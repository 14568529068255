import React, { useContext, useEffect, useState } from "react";
import { FiPhoneCall } from "react-icons/fi";
import Dropzone from "react-dropzone";
import { MdDoubleArrow } from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";
import { DxfParser } from "dxf-parser";
import { toast } from "react-toastify";
import {
  analyzeDXFFile,
  drawCanvasBasedOnDXF,
} from "../../util/CanvasOperations";
import { Auth } from "aws-amplify";
import { useLocation, useNavigate } from "react-router-dom";
import { context } from "../../context/ContextProvider";
import BoxLetterFileDetail from "../../components/box_letter/BoxLetterFileDetail";
import { queryPrintingTypeList } from "../../requests/printingTypeRequests";
import BoxLetterCartDetail from "../../components/box_letter/BoxLetterCartDetail";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { GoTriangleLeft } from "react-icons/go";
import ShippingBillingInformation from "../../components/transportation/ShippingBillingInformation";
import { queryAddressList } from "../../requests/addressRequests";
import { retrieveS3Uploader, uploadStreamToS3 } from "../../util/AWSOperations";
import { createOrder } from "../../requests/orderRequests";
import PaymentDetail from "../../components/payment/PaymentDetail";
import {
  responsePayment,
  initializeCheckoutFormPayment,
  initializeThreeDsPayment,
  queryPaymentListByConversationId,
} from "../../requests/paymentRequests";
import ModalView from "../../components/modal/ModalView";
import InitializeThreeDsModalHeader from "../../components/transaction/InitializeThreeDsModalHeader";
import InitializeThreeDsModalBody from "../../components/transaction/InitializeThreeDsModalBody";
import InitializeThreeDsModalFooter from "../../components/transaction/InitializeThreeDsModalFooter";
import config from "../../config/config.json";
import {
  updateDefaultBillingId,
  updateDefaultShippingId,
} from "../../requests/userRequests";
import {
  sendOrderCreatedEmail,
  sendOrderReceivedEmail,
} from "../../requests/emailRequests";
import CncCuttingFileDetail from "../../components/cnc_cutting/CncCuttingFileDetail";
import CncCuttingCartDetail from "../../components/cnc_cutting/CncCuttingCartDetail";
import LaserCuttingFileDetail from "../../components/laser_cutting/LaserCuttingFileDetail";
import LaserCuttingCartDetail from "../../components/laser_cutting/LaserCuttingCartDetail";
import LoadingAnimation from "../../components/loading/LoadingAnimation";

const OfferScreen = () => {
  const navigate = useNavigate();
  const appContext = useContext(context);
  const { state } = useLocation();
  const { productionTypeList, userInformation, settings } = appContext;
  const [pageState, setPageState] = useState(0);
  const [selectedProductionType, setSelectedProductionType] = useState({
    id: null,
  });
  const [printingTypeList, setPrintingTypeList] = useState([]);
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [currentState, setCurrentState] = useState("COMPLETED");
  const [metricUpdated, setMetricUpdated] = useState(false);
  const [parsedFileMap, setParsedFileMap] = useState(null);
  const [constructedFileMap, setConstructedFileMap] = useState({});
  const [billingList, setBillingList] = useState([]);
  const [selectedBillingId, setSelectedBillingId] = useState(
    userInformation ? userInformation.defaultBillingId : null
  );
  const [shippingList, setShippingList] = useState([]);
  const [selectedShippingId, setSelectedShippingId] = useState(
    userInformation ? userInformation.defaultShippingId : null
  );
  const [addressUpdated, setAddressUpdated] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState({
    number: "4766620000000001",
    name: "ISMINIZI GIRIN",
    expiration: "07/27",
    cvc: "543",
  });
  const [paymentFocused, setPaymentFocused] = useState(null);
  const [showThreeDsModal, setShowThreeDsModal] = useState(false);
  const [threeDsHtmlContent, setThreeDsHtmlContent] = useState(null);
  const [conversationId, setConversationId] = useState(null);
  const [orderNote, setOrderNote] = useState(null);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const serviceTypeList = [
    {
      key: 0,
      name: "Hizmeti Seç",
    },
    {
      key: 1,
      name: "Dosya Yükle",
    },
    {
      key: 2,
      name: "Malzeme Seç",
    },
    {
      key: 3,
      name: "Sipariş Oluştur",
    },
    {
      key: 4,
      name: "Teslimatı Seç",
    },
    {
      key: 5,
      name: "Ödeme Yap",
    },
  ];

  useEffect(() => {
    const checkUserHasLoggedIn = async () => {
      const currentUser = await Auth.currentUserInfo();
      if (currentUser === null) {
        navigate("/login");
      } else {
        const addressList = await queryAddressList(currentUser.username);
        const filteredShippingList = addressList.filter(
          (address) => address.type === "SHIPPING"
        );
        setShippingList(filteredShippingList);
        const filteredBillingList = addressList.filter(
          (address) => address.type === "BILLING"
        );
        setBillingList(filteredBillingList);
      }
    };
    checkUserHasLoggedIn();
  }, []);

  useEffect(() => {
    if (state) {
      let foundProductionType;
      switch (state) {
        case "BOX_LETTER":
          foundProductionType = productionTypeList.find(
            (productionType) => productionType.id === 0
          );
          if (foundProductionType) {
            setSelectedProductionType(foundProductionType);
          }

          break;
        case "3D_PRINTING":
          foundProductionType = productionTypeList.find(
            (productionType) => productionType.id === 4
          );
          if (foundProductionType) {
            setSelectedProductionType(foundProductionType);
          }
          break;
        case "LASER_CUTTING":
          foundProductionType = productionTypeList.find(
            (productionType) => productionType.id === 2
          );
          if (foundProductionType) {
            setSelectedProductionType(foundProductionType);
          }

          break;
        case "CNC_CUTTING":
          foundProductionType = productionTypeList.find(
            (productionType) => productionType.id === 1
          );
          if (foundProductionType) {
            setSelectedProductionType(foundProductionType);
          }
          break;
        default:
          break;
      }
    }
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageState]);

  useEffect(() => {
    const retrieveAndSetPrintingTypeList = async () => {
      const foundPrintingTypeList = await queryPrintingTypeList(
        selectedProductionType.id
      );
      setPrintingTypeList(foundPrintingTypeList);
    };
    if (selectedProductionType.id !== null) {
      retrieveAndSetPrintingTypeList();
    }
  }, [selectedProductionType]);

  useEffect(() => {
    if (uploadedFileList.length > 0) {
      setCurrentState("READING");
      const currentParsedFileMap = {};
      for (const [
        uploadedFileIndex,
        uploadedFile,
      ] of uploadedFileList.entries()) {
        var reader = new FileReader();
        const parser = new DxfParser();
        reader.readAsText(uploadedFile);
        reader.onload = (e) => {
          try {
            const dxf = parser.parseSync(e.target.result);
            currentParsedFileMap[uploadedFile.name] = dxf;
            const { highest_x, highest_y, distance, perimeter, area } =
              analyzeDXFFile(dxf, 500);
            currentParsedFileMap[uploadedFile.name].highest_x = highest_x;
            currentParsedFileMap[uploadedFile.name].highest_y = highest_y;
            currentParsedFileMap[uploadedFile.name].metric = "MM";
            currentParsedFileMap[uploadedFile.name].distance = distance;
            currentParsedFileMap[uploadedFile.name].perimeter = perimeter;
            currentParsedFileMap[uploadedFile.name].area = area;
            if (uploadedFileIndex === uploadedFileList.length - 1) {
              setParsedFileMap(currentParsedFileMap);
              setCurrentState("COMPLETED");
            }
          } catch (err) {
            console.error(err.stack);
          }
        };
      }
    }
  }, [uploadedFileList]);

  useEffect(() => {
    if ((pageState === 2 || pageState === 3) && parsedFileMap) {
      Object.keys(parsedFileMap).forEach((parsedFileKey, parsedFileIndex) => {
        const foundDivElement = document.getElementById(
          `canvas_${parsedFileKey}`
        );
        drawCanvasBasedOnDXF(
          `canvas_${parsedFileKey}`,
          foundDivElement.offsetWidth,
          parsedFileMap[parsedFileKey]
        );
      });
    }
  }, [pageState, parsedFileMap]);

  useEffect(() => {
    if (metricUpdated) {
      setMetricUpdated(false);
    }
  }, [metricUpdated]);

  useEffect(() => {
    const retrieveAndSetShippingBillingList = async () => {
      const currentUser = await Auth.currentUserInfo();
      const addressList = await queryAddressList(currentUser.username);
      const filteredShippingList = addressList.filter(
        (address) => address.type === "SHIPPING"
      );
      setShippingList(filteredShippingList);
      const filteredBillingList = addressList.filter(
        (address) => address.type === "BILLING"
      );
      setBillingList(filteredBillingList);
    };
    if (addressUpdated) {
      retrieveAndSetShippingBillingList();
      setAddressUpdated(false);
    }
  }, [addressUpdated]);

  useEffect(() => {
    if (userInformation && selectedBillingId !== null) {
      updateDefaultBillingId(userInformation.id, selectedBillingId);
    }
  }, [userInformation, selectedBillingId]);

  useEffect(() => {
    if (userInformation && selectedShippingId !== null) {
      updateDefaultShippingId(userInformation.id, selectedShippingId);
    }
  }, [userInformation, selectedShippingId]);

  const uploadFileListToS3 = async () => {
    const currentUser = await Auth.currentUserInfo();
    const username = currentUser.username;
    const s3Uploader = retrieveS3Uploader(process.env.REACT_APP_S3_BUCKET_NAME);
    let uploadSuccessful = true;
    for (const uploadedFile of uploadedFileList) {
      try {
        await uploadStreamToS3(
          s3Uploader,
          uploadedFile,
          `${username.replace("@", "-")}/${uploadedFile.name}`,
          "public-read"
        );
      } catch (err) {
        uploadSuccessful = false;
        toast(
          `${uploadedFile.name} dosyasını yüklerken bir hata oluştu, lütfen tekrar deneyin`
        );
        break;
      }
    }
    if (uploadSuccessful) {
      toast(`Bütün dosyalar başarı ile yüklendi`);
    }
  };

  const createOrderFromParameters = async (paymentId, conversationId) => {
    const currentUser = await Auth.currentUserInfo();
    let shipping;
    if (selectedShippingId === 0) {
      shipping = {
        fullName: "ELDEN TESLIM",
        city: "ELDEN TESLIM",
        country: "ELDEN TESLIM",
        address: "ELDEN TESLIM",
        postalCode: "06378",
      };
    } else {
      shipping = shippingList.find(
        (shipping) => shipping.id === selectedShippingId
      );
    }
    const billing = billingList.find(
      (billing) => billing.id === selectedBillingId
    );
    const orderItemList = [];
    for (const constructedFileKey in constructedFileMap) {
      const constructedFile = constructedFileMap[constructedFileKey];
      const price = parseFloat(
        calculateSinglePriceInformation(constructedFileKey)
      );
      const taxPrice = parseFloat(calculateTaxPriceInformation(price));
      const orderItem = {
        x: parsedFileMap[constructedFileKey].highest_x,
        y: parsedFileMap[constructedFileKey].highest_y,
        metric: parsedFileMap[constructedFileKey].metric,
        productionType: selectedProductionType,
        name: constructedFileKey,
        price: price.toFixed(2),
        taxPrice: taxPrice.toFixed(2),
      };
      for (const propertyKey in constructedFile) {
        if (constructedFile[propertyKey] !== null) {
          orderItem[propertyKey] = JSON.parse(constructedFile[propertyKey]);
        }
      }
      orderItemList.push(orderItem);
    }
    let totalPrice = 0;
    orderItemList.forEach((orderItem) => {
      totalPrice +=
        parseFloat(orderItem.price) + parseFloat(orderItem.taxPrice);
    });
    const order = {
      id: new Date().getTime(),
      status: "WAITING",
      username: currentUser.username,
      itemList: orderItemList,
      shipping,
      billing,
      paymentId,
      conversationId,
      note: orderNote,
      price: totalPrice.toFixed(2),
      paidPrice: calculateDiscountedTotalPriceInformation(),
      discountAmount: calculateDiscountAmount(),
    };
    const response = await createOrder(order);
    if (response) {
      sendOrderCreatedEmail(order);
      sendOrderReceivedEmail(order);
    }
  };

  const handleNextPageState = (paymentId, conversationId) => {
    if (pageState === 1) {
      uploadFileListToS3();
    }
    if (pageState === 5) {
      createOrderFromParameters(paymentId, conversationId);
    }
    setPageState(pageState + 1);
  };

  const handleCloseShowThreeDsModal = async () => {
    setShowThreeDsModal(false);
    const response = await queryPaymentListByConversationId(conversationId);
    setPaymentLoading(false);
    if (response) {
      const result = response[0];
      if (result.status === "success") {
        handleNextPageState(result.paymentId, result.conversationId);
      } else {
        if (result.mdStatus === 0) {
          toast("3-D Secure imzası geçersiz veya doğrulama");
        } else if (result.mdStatus === 2) {
          toast("Kart sahibi veya bankası sisteme kayıtlı değil");
        } else if (result.mdStatus === 3) {
          toast("Kartın bankası sisteme kayıtlı değil");
        } else if (result.mdStatus === 4) {
          toast(
            "Doğrulama denemesi, kart sahibi sisteme daha sonra kayıt olmayı seçmiş"
          );
        } else if (result.mdStatus === 5) {
          toast("Doğrulama yapılamıyor");
        } else if (result.mdStatus === 6) {
          toast("3-D Secure hatası");
        } else if (result.mdStatus === 7) {
          toast("Sistem hatası");
        } else if (result.mdStatus === 8) {
          toast("Bilinmeyen kart no");
        }
      }
    }
  };

  const handleUploadPage = () => {
    setPageState(1);
  };

  const retrieveSingleSelecetedServiceOffsetHeight = (productionType) => {
    const productionBaseElement = document.getElementById(`production_type_0`);
    const headerElement = document.getElementById("header");
    if (productionBaseElement && headerElement) {
      const headerHeight = headerElement.offsetHeight;
      const baseHeight = productionBaseElement.offsetHeight;
      return headerHeight + baseHeight * productionType.id;
    }
    return 0;
  };

  const RenderSingleSelectedService = (productionType) => {
    return (
      <div
        id={`production_type_${productionType.id}`}
        className="col-12"
        key={productionType.id}
      >
        <div
          className={`${
            selectedProductionType.id === productionType.id
              ? "offer-base-information-selection-base-selected-item"
              : null
          } ${
            productionType.id === 0 ||
            productionType.id === 1 ||
            productionType.id === 2
              ? "offer-base-information-selection-base-item"
              : "offer-base-information-selection-base-not-selectable-item"
          }`}
          onClick={() => {
            setSelectedProductionType(productionType);
          }}
        >
          {productionType.name}
        </div>
      </div>
    );
  };

  const retrieveSingleSelectedServiceText = (productionType) => {
    if (productionType.id === 0) {
      return "Kutu harf dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 1) {
      return "CNC kesim dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 2) {
      return "Lazer kesim dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 3) {
      return "Folyo kesim dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 4) {
      return "3D baskı dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 5) {
      return "Digital baskı dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    } else if (productionType.id === 6) {
      return "UV baskı dosyanızı .dxf formatına çevirerek yükleyin. Seçimlerinizi tamamlayın siparişlerinizi oluşturun.";
    }
  };

  const RenderSingleSelectedServiceInfo = (productionType) => {
    if (productionType.id !== null) {
      return (
        <div className="col-md-12">
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                left: -20,
                top: 10,
              }}
            >
              <GoTriangleLeft color="gray" size={30} />
            </div>
            <div
              style={{
                backgroundColor: "gray",
                color: "white",
                padding: 20,
                borderRadius: 5,
                marginTop:
                  20 +
                  retrieveSingleSelecetedServiceOffsetHeight(productionType),
              }}
            >
              {retrieveSingleSelectedServiceText(productionType)}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const RenderSelectService = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div
              id="header"
              className="offer-base-information-selection-base-header"
            >
              Almak istediğiniz servisi seçin
            </div>
            <div className="d-flex flex-column justify-content-start align-items-start offer-base-information-selection-base-items">
              {productionTypeList.map((productionType) =>
                RenderSingleSelectedService(productionType)
              )}
            </div>
            <div className="col-md-6 w-100">
              <button
                className="primary-button w-100"
                style={{ marginTop: 20 }}
                onClick={() => {
                  if (selectedProductionType.id !== null) {
                    if (
                      selectedProductionType.id === 0 ||
                      selectedProductionType.id === 1 ||
                      selectedProductionType.id === 2
                    ) {
                      handleNextPageState();
                    } else {
                      toast(
                        "Bu hizmetimiz yapım aşamasındadır. Yakın bir zamanda görüşmek üzere"
                      );
                    }
                  } else {
                    toast("Lütfen baskı tipi seçin");
                  }
                }}
              >
                Devam et
              </button>
            </div>
          </div>
          <div className="col-md-4">
            {RenderSingleSelectedServiceInfo(selectedProductionType)}
          </div>
          {/* <div className="d-flex flex-column justify-content-center align-items-center col-md-6">
            <FiPhoneCall size={50} style={{ color: "#6257f7" }} />
            <div style={{ marginTop: 30 }}>İlgili başlığı bulamadınız mı?</div>
            <div style={{ marginTop: 5 }}>
              Daha fazla bilgiyi mi almak istiyorsunuz?
            </div>

            <a href="tel:+905544986046" style={{ textDecoration: "none" }}>
              <div className="offer-base-information-selection-call-button">
                Hemen bizi arayın
              </div>
            </a>
          </div> */}
        </div>
      </div>
    );
  };

  const handleOnDrop = (acceptedFiles) => {
    setUploadedFileList(acceptedFiles);
  };

  const RenderUploadFile = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div
          className="col-md-10"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <div className="offer-base-information-selection-base-header">
            {selectedProductionType.name} |
            <span style={{ fontStyle: "italic", marginLeft: 5 }}>
              Dosyalarınızı yükleyin
            </span>
          </div>

          <Dropzone onDrop={handleOnDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="d-flex flex-column justify-content-center align-items-center offer-base-information-upload-file">
                  <input {...getInputProps()} />
                  <div className="offer-base-information-upload-file-text">
                    Bir dosya seçin / buraya sürükleyin
                  </div>
                  <div className="">
                    Hemen teklif alabileceğiniz dosya formatı:{" "}
                    <span className="offer-base-information-upload-file-text-special-format">
                      .dxf
                    </span>
                  </div>
                  <div className="offer-base-information-upload-file-sub-text">
                    Dosya yüklemeden önce lütfen dosya yönergelerini inceleyiniz
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
          {uploadedFileList.length > 0
            ? uploadedFileList.map((uploadedFile, uploadFileIndex) => (
                <div
                  key={uploadFileIndex}
                  className="d-flex flex-row justify-content-start align-items-center offer-base-single-uploaded-file-item"
                >
                  <MdDoubleArrow size={20} />
                  <div style={{ marginRight: 10 }}>
                    Dosya ismi:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {uploadedFile.name}
                    </span>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    Dosya boyutu:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {uploadedFile.size} Bytes
                    </span>
                  </div>
                </div>
              ))
            : null}
          {uploadedFileList.length > 0 ? (
            currentState === "COMPLETED" ? (
              <button
                className="col-md-12 primary-button"
                onClick={handleNextPageState}
              >
                Devam et
              </button>
            ) : (
              <RenderLoadingAnimation />
            )
          ) : null}
        </div>
      </div>
    );
  };

  const RenderLoadingAnimation = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img
          src="loading/loading_animation.gif"
          alt="loading_animation"
          style={{ width: 50, height: 50 }}
        />
      </div>
    );
  };

  const retrieveParsedFileMetricInformation = (dxfJSON) => {
    // const { highest_x, highest_y } = analyzeDXFFile(dxfJSON, 500);
    return (
      <div>
        X: {dxfJSON.highest_x.toFixed(2)} Y: {dxfJSON.highest_y.toFixed(2)}
        <select
          defaultValue={dxfJSON.metric}
          onChange={(e) => {
            dxfJSON.metric = e.target.value;
            setMetricUpdated(true);
          }}
          style={{ marginLeft: 10 }}
        >
          <option value={"MM"}>mm</option>
          <option value={"CM"}>cm</option>
          <option value={"INCH"}>inch</option>
        </select>
      </div>
    );
  };

  const calculatePleksiglasPriceInformation = (
    parsedFileKey,
    area,
    perimeter
  ) => {
    const pleksiThicknessValue =
      constructedFileMap[parsedFileKey].pleksiThickness;
    if (pleksiThicknessValue) {
      const pleksiThickness = JSON.parse(pleksiThicknessValue);
      const foundCurrency = settings.currency[pleksiThickness.currency];
      return (
        pleksiThickness.unitPrice * foundCurrency * area +
        pleksiThickness.cncPMPrice * foundCurrency * perimeter +
        pleksiThickness.labourPrice * foundCurrency * area
      );
    }
    return 0;
  };

  const calculateSideBandPriceInformation = (parsedFileKey, perimeter) => {
    const sideBandDepthValue = constructedFileMap[parsedFileKey].sideBandDepth;
    const sideBandThicknessValue =
      constructedFileMap[parsedFileKey].sideBandThickness;
    if (sideBandDepthValue && sideBandThicknessValue) {
      const sideBandThickness = JSON.parse(sideBandThicknessValue);
      const baseMaterialValue = constructedFileMap[parsedFileKey].baseMaterial;
      if (parseInt(baseMaterialValue) === 2) {
        const sideBandDepth = JSON.parse(
          constructedFileMap[parsedFileKey].sideBandDepth
        );
        const kromPattern = JSON.parse(
          constructedFileMap[parsedFileKey].kromPattern
        );
        const kromColor = JSON.parse(
          constructedFileMap[parsedFileKey].kromColor
        );
        if (kromPattern && kromColor && sideBandDepth) {
          const foundSideBandThickness = sideBandDepth.thicknessList.find(
            (thickness) =>
              thickness.value === sideBandThickness.value &&
              thickness.patternId === kromPattern.id &&
              thickness.colorId === kromColor.id
          );
          const foundCurrency =
            settings.currency[foundSideBandThickness.currency];
          return (
            foundSideBandThickness.unitPrice * foundCurrency * perimeter +
            foundSideBandThickness.labourPrice * foundCurrency * perimeter +
            foundSideBandThickness.twistPrice * foundCurrency * perimeter
          );
        }
        return 0;
      } else {
        return (
          sideBandThickness.unitPrice * perimeter +
          sideBandThickness.labourPrice * perimeter +
          sideBandThickness.twistPrice * perimeter
        );
      }
    }
    return 0;
  };

  const calculateMaterialPriceInformation = (
    parsedFileKey,
    area,
    perimeter
  ) => {
    const materialThicknessValue =
      constructedFileMap[parsedFileKey].materialThickness;
    if (materialThicknessValue) {
      const materialThickness = JSON.parse(materialThicknessValue);
      const foundCurrency = settings.currency[materialThickness.currency];
      return (
        materialThickness.unitPrice * foundCurrency * area +
        materialThickness.cncPMPrice * foundCurrency * perimeter +
        materialThickness.labourPrice * foundCurrency * area
      );
    }
    return 0;
  };

  const calculateLEDPriceInformation = (parsedFileKey, area) => {
    const includeLedValue = constructedFileMap[parsedFileKey].includeLed;
    if (includeLedValue) {
      const includeLED = includeLedValue === "true";
      if (includeLED) {
        const ledColorValue = constructedFileMap[parsedFileKey].ledColor;
        if (ledColorValue) {
          const ledColor = JSON.parse(ledColorValue);
          const ledCount = area / 0.01;
          const foundCurrency = settings.currency[ledColor.currency];
          return (
            ledColor.unitPrice * foundCurrency * ledCount +
            ledColor.labourPrice * foundCurrency * ledCount
          );
        }
        return 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const calculateFootPriceInformation = (parsedFileKey) => {
    const includeFootValue = constructedFileMap[parsedFileKey].includeFoot;
    if (includeFootValue) {
      const includeFoot = includeFootValue === "true";
      if (includeFoot) {
        const entityList = parsedFileMap[parsedFileKey].entities;
        const footValue = constructedFileMap[parsedFileKey].foot;
        if (entityList && footValue) {
          const foot = JSON.parse(footValue);
          const foundCurrency = settings.currency[foot.currency];
          return entityList.length * foot.unitPrice * foundCurrency;
        }
      }
    }
    return 0;
  };

  const calculateLetterColorPriceInformation = (
    parsedFileKey,
    area,
    perimeter
  ) => {
    const letterColorPriceValue =
      constructedFileMap[parsedFileKey].letterColorPrice;
    const sideBandDepthValue = constructedFileMap[parsedFileKey].sideBandDepth;
    if (letterColorPriceValue && sideBandDepthValue) {
      const sideBandDepth = JSON.parse(sideBandDepthValue);
      const letterColorPrice = JSON.parse(letterColorPriceValue);
      const foundCurrency = settings.currency[letterColorPrice.currency];
      return (
        letterColorPrice.unitPrice * foundCurrency * area +
        letterColorPrice.unitPrice *
          foundCurrency *
          perimeter *
          (sideBandDepth.depth / 1000)
      );
    }
    return 0;
  };

  const calculateDekotaPriceInformation = (parsedFileKey, area, perimeter) => {
    const includeDekotaValue = constructedFileMap[parsedFileKey].includeDekota;
    if (includeDekotaValue) {
      const includeDekota = includeDekotaValue === "true";
      if (includeDekota) {
        const dekotaThicknessValue =
          constructedFileMap[parsedFileKey].dekotaThickness;
        if (dekotaThicknessValue) {
          const dekotaThickness = JSON.parse(dekotaThicknessValue);
          const foundCurrency = settings.currency[dekotaThickness.currency];
          return (
            dekotaThickness.unitPrice * foundCurrency * area +
            dekotaThickness.cncPMPrice * foundCurrency * perimeter +
            dekotaThickness.labourPrice * foundCurrency * area
          );
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
    return 0;
  };

  const calculateTransformerPriceInformation = (parsedFileKey) => {
    const transformerTypeValue =
      constructedFileMap[parsedFileKey].transformerType;
    const transformerCount = constructedFileMap[parsedFileKey].transformerCount;
    if (transformerTypeValue && transformerCount) {
      const transformerType = JSON.parse(transformerTypeValue);
      const foundCurrency = settings.currency[transformerType.currency];
      return transformerType.unitPrice * foundCurrency * transformerCount;
    }
    return 0;
  };

  // const calculateCuttingPriceInformation = (parsedFileMap, perimeter) => {
  //   console.log(constructedFileMap[parsedFileMap]);
  //   const material = constructedFileMap[parsedFileMap].materialThickness;
  //   if (cncPMPriceValue) {
  //     const cncPMPrice = JSON.parse(cncPMPriceValue);
  //     console.log(cncPMPrice);
  //   }

  //   return 0;
  // };

  const calculateWoodSidebandPriceInformation = (parsedFileKey, perimeter) => {
    const sideBandDepthValue = constructedFileMap[parsedFileKey].sideBandDepth;
    if (sideBandDepthValue) {
      const sideBandDepth = JSON.parse(sideBandDepthValue);
      const foundCurrency = settings.currency[sideBandDepth.currency];
      return (
        sideBandDepth.unitPrice * foundCurrency * perimeter +
        sideBandDepth.labourPrice * foundCurrency * perimeter
      );
    }
    return 0;
  };

  const calculateBoxLetterPriceInformation = (
    parsedFileKey,
    area,
    distance
  ) => {
    const pleksiPrice = calculatePleksiglasPriceInformation(
      parsedFileKey,
      area,
      distance
    );
    const sideBandPrice = calculateSideBandPriceInformation(
      parsedFileKey,
      distance
    );
    const materialPrice = calculateMaterialPriceInformation(
      parsedFileKey,
      area,
      distance
    );
    const ledPrice = calculateLEDPriceInformation(parsedFileKey, area);
    const footPrice = calculateFootPriceInformation(parsedFileKey);
    const dekotaPrice = calculateDekotaPriceInformation(
      parsedFileKey,
      area,
      distance
    );
    const letterColorPrice = calculateLetterColorPriceInformation(
      parsedFileKey,
      area,
      distance
    );
    const transformerPrice =
      calculateTransformerPriceInformation(parsedFileKey);
    // console.log(
    //   `${pleksiPrice}, ${sideBandPrice}, ${materialPrice}, ${ledPrice}, ${dekotaPrice}`
    // );
    const baseMaterialValue = constructedFileMap[parsedFileKey].baseMaterial;

    if (baseMaterialValue !== null) {
      const baseMaterialId = parseInt(baseMaterialValue);
      const profitPercentage =
        1 + constructedFileMap[parsedFileKey].profitPercentage;
      if (baseMaterialId === 0) {
        if (ledPrice > 0) {
          return (
            (pleksiPrice +
              sideBandPrice +
              dekotaPrice +
              ledPrice +
              transformerPrice) *
            profitPercentage
          );
        } else {
          const includeDekotaValue =
            constructedFileMap[parsedFileKey].includeDekota;
          if (includeDekotaValue) {
            const includeDekota = includeDekotaValue === "true";
            if (includeDekota) {
              return (
                (pleksiPrice + sideBandPrice + dekotaPrice) * profitPercentage
              );
            } else {
              return (pleksiPrice + sideBandPrice) * profitPercentage;
            }
          }
        }
      } else if (baseMaterialId === 1) {
        if (ledPrice > 0) {
          return (
            (pleksiPrice +
              sideBandPrice +
              materialPrice +
              dekotaPrice +
              ledPrice +
              transformerPrice +
              letterColorPrice +
              footPrice) *
            profitPercentage
          );
        } else {
          const includeDekotaValue =
            constructedFileMap[parsedFileKey].includeDekota;
          if (includeDekotaValue) {
            const includeDekota = includeDekotaValue === "true";
            if (includeDekota) {
              return (
                (pleksiPrice +
                  sideBandPrice +
                  materialPrice +
                  dekotaPrice +
                  letterColorPrice +
                  footPrice) *
                profitPercentage
              );
            } else {
              return (
                (pleksiPrice +
                  sideBandPrice +
                  materialPrice +
                  footPrice +
                  letterColorPrice) *
                profitPercentage
              );
            }
          }
        }
      } else if (baseMaterialId === 2) {
        if (ledPrice > 0) {
          return (
            (pleksiPrice +
              sideBandPrice +
              materialPrice +
              dekotaPrice +
              ledPrice +
              transformerPrice +
              footPrice) *
            profitPercentage
          );
        } else {
          const includeDekotaValue =
            constructedFileMap[parsedFileKey].includeDekota;
          if (includeDekotaValue) {
            const includeDekota = includeDekotaValue === "true";
            if (includeDekota) {
              return (
                (pleksiPrice +
                  sideBandPrice +
                  materialPrice +
                  dekotaPrice +
                  footPrice) *
                profitPercentage
              );
            } else {
              return (
                (pleksiPrice + sideBandPrice + materialPrice + footPrice) *
                profitPercentage
              );
            }
          }
        }
      }
    }

    return 0;
  };

  const calculateCncCuttingPriceInformation = (
    parsedFileKey,
    area,
    perimeter
  ) => {
    const materialPrice = calculateMaterialPriceInformation(
      parsedFileKey,
      area,
      perimeter
    );
    const sideBandPrice = calculateWoodSidebandPriceInformation(
      parsedFileKey,
      area,
      perimeter
    );
    const count = constructedFileMap[parsedFileKey].count;
    const profitPercentage =
      1 + constructedFileMap[parsedFileKey].profitPercentage;
    return (materialPrice + sideBandPrice) * count * profitPercentage;
  };

  const calculateLaserCuttingPriceInformation = (
    parsedFileKey,
    area,
    perimeter
  ) => {
    const materialPrice = calculateMaterialPriceInformation(
      parsedFileKey,
      area,
      perimeter
    );
    const sideBandPrice = calculateWoodSidebandPriceInformation(
      parsedFileKey,
      area,
      perimeter
    );
    const count = constructedFileMap[parsedFileKey].count;
    const profitPercentage =
      1 + constructedFileMap[parsedFileKey].profitPercentage;
    return (materialPrice + sideBandPrice) * count * profitPercentage;
  };

  const calculateSinglePriceInformation = (parsedFileKey) => {
    // let highest_x;
    // let highest_y;
    let area = parsedFileMap[parsedFileKey].area;
    let perimeter = parsedFileMap[parsedFileKey].perimeter;
    let distance = parsedFileMap[parsedFileKey].distance;
    if (parsedFileMap[parsedFileKey].metric === "MM") {
      // highest_x = parsedFileMap[parsedFileKey].highest_x / 1000;
      // highest_y = parsedFileMap[parsedFileKey].highest_y / 1000;
      area = area / (1000 * 1000);
      perimeter = perimeter / 1000;
      distance = distance / 1000;
    } else if (parsedFileMap[parsedFileKey].metric === "CM") {
      // highest_x = parsedFileMap[parsedFileKey].highest_x / 100;
      // highest_y = parsedFileMap[parsedFileKey].highest_y / 100;
      area = area / (100 * 100);
      perimeter = perimeter / 100;
      distance = distance / 100;
    } else if (parsedFileMap[parsedFileKey].metric === "INCH") {
      // highest_x = (parsedFileMap[parsedFileKey].highest_x / 100) * 2.54;
      // highest_y = (parsedFileMap[parsedFileKey].highest_y / 100) * 2.54;
      area = area / (((100 / 2.54) * 100) / 2.54);
      perimeter = perimeter / (100 / 2.54);
      distance = distance / (100 / 2.54);
    }
    // console.log(`area: ${area} metersquare`);
    // console.log(`perimeter: ${perimeter} meter`);
    // console.log(`distance: ${distance} meter`);

    if (!constructedFileMap[parsedFileKey]) {
      return 0;
    }
    if (selectedProductionType.id === 0) {
      return calculateBoxLetterPriceInformation(parsedFileKey, area, distance);
    } else if (selectedProductionType.id === 1) {
      return calculateCncCuttingPriceInformation(parsedFileKey, area, distance);
    } else if (selectedProductionType.id === 2) {
      return calculateLaserCuttingPriceInformation(
        parsedFileKey,
        area,
        distance
      );
    }
    return 0;
  };

  const calculateTaxPriceInformation = (price) => {
    return price * 0.18;
  };

  const calculateShippingPrice = () => {
    const shippingPrice = 0.0;
    return shippingPrice;
  };

  const calculateTotalPriceInformation = () => {
    let totalPrice = 0.0;
    Object.keys(parsedFileMap).forEach((parsedFileKey) => {
      const price = parseFloat(calculateSinglePriceInformation(parsedFileKey));
      const taxPrice = parseFloat(calculateTaxPriceInformation(price));
      totalPrice += price + taxPrice;
    });
    const shippingPrice = parseFloat(calculateShippingPrice());
    const shippingTaxPrice = parseFloat(
      calculateTaxPriceInformation(shippingPrice)
    );
    return (totalPrice + shippingPrice + shippingTaxPrice).toFixed(2);
  };

  const retrieveCalculateSinglePriceInformation = (dxfKey) => {
    const price = calculateSinglePriceInformation(dxfKey);
    const taxPrice = calculateTaxPriceInformation(price);
    return (
      <div className="col-md-12" style={{ marginTop: 30 }}>
        <div style={{ fontWeight: "bold" }}>{dxfKey}</div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div>Toplam</div>
          <div>{price.toFixed(2)} TL + KDV</div>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div>KDV (18%)</div>
          <div>{taxPrice.toFixed(2)} TL</div>
        </div>
      </div>
    );
  };

  const reDrawCanvasWithColor = (parsedFileKey, color) => {
    const foundDivElement = document.getElementById(`canvas_${parsedFileKey}`);
    while (foundDivElement.firstChild) {
      foundDivElement.removeChild(foundDivElement.firstChild);
    }
    drawCanvasBasedOnDXF(
      `canvas_${parsedFileKey}`,
      foundDivElement.offsetWidth,
      parsedFileMap[parsedFileKey],
      color
    );
  };

  const calculateDiscountAmount = () => {
    const totalPrice = parseFloat(calculateTotalPriceInformation());
    if (userInformation) {
      return (totalPrice * userInformation.discountPercentage).toFixed(2);
    }
    return 0.0;
  };

  const calculateDiscountedTotalPriceInformation = () => {
    const totalPrice = parseFloat(calculateTotalPriceInformation());
    const discountAmount = parseFloat(calculateDiscountAmount());

    return (totalPrice - discountAmount).toFixed(2);
  };

  const RenderFileDetailBasedOnProductionType = (parsedFileKey) => {
    if (selectedProductionType.id === 0) {
      return (
        <BoxLetterFileDetail
          parsedFileKey={parsedFileKey}
          printingTypeList={printingTypeList}
          metricUpdated={metricUpdated}
          setMetricUpdated={setMetricUpdated}
          setConstructedFileMap={setConstructedFileMap}
        />
      );
    } else if (selectedProductionType.id === 1) {
      return (
        <CncCuttingFileDetail
          parsedFileKey={parsedFileKey}
          printingTypeList={printingTypeList}
          metricUpdated={metricUpdated}
          setMetricUpdated={setMetricUpdated}
          setConstructedFileMap={setConstructedFileMap}
        />
      );
    } else if (selectedProductionType.id === 2) {
      return (
        <LaserCuttingFileDetail
          parsedFileKey={parsedFileKey}
          printingTypeList={printingTypeList}
          metricUpdated={metricUpdated}
          setMetricUpdated={setMetricUpdated}
          setConstructedFileMap={setConstructedFileMap}
        />
      );
    }
    return null;
  };

  const RenderUploadedFileListDetails = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div className="row">
          <div className="col-md-12">
            <div className="offer-base-information-selection-base-header">
              {selectedProductionType.name} |
              <span style={{ fontStyle: "italic", marginLeft: 5 }}>
                Dosyanızın özelliklerini belirleyin
              </span>
            </div>
            {Object.keys(parsedFileMap).map(
              (parsedFileKey, parsedFileIndex) => (
                <div
                  className="row mt-4"
                  style={{ borderBottom: "1px solid", paddingBottom: 10 }}
                >
                  <div className="col-md-6">
                    <div
                      style={{
                        border: "1px solid lightgray",
                        borderRadius: 5,
                        padding: 10,
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <div
                        className="d-flex flex-column justify-content-center align-items-center h-100"
                        id={`canvas_${parsedFileKey}`}
                      ></div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    {RenderFileDetailBasedOnProductionType(parsedFileKey)}
                    <div className="row mt-5">
                      <div className="col-md-6">
                        <div>
                          <div style={{ fontWeight: "bold" }}>Ölçüler</div>
                          {retrieveParsedFileMetricInformation(
                            parsedFileMap[parsedFileKey]
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div style={{ fontWeight: "bold" }}>Gönderim</div>
                        <div className="offer-base-information-selection-base-shipping-box">
                          <div>Standart</div>
                          <div>İş gününde kargolanır</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}

            <div className="row">
              <div className="col-md-6">
                {/* <div style={{ marginTop: 30 }}>
                  <div style={{ fontWeight: "bold" }}> Sipariş Notu</div>
                  <textarea
                    style={{ width: "100%", height: 300, marginTop: 10 }}
                  ></textarea>
                </div> */}
              </div>
              <div className="col-md-6">
                {Object.keys(parsedFileMap).map((parsedFileKey) =>
                  retrieveCalculateSinglePriceInformation(parsedFileKey)
                )}
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>Kargo</div>
                  <div>{calculateShippingPrice()} TL + KDV</div>
                </div>
                <div
                  style={{
                    height: 2,
                    backgroundColor: "lightgray",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>Toplam Tutar</div>
                  <div>{calculateTotalPriceInformation()} TL</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>İskonto Tutarı</div>
                  <div>{calculateDiscountAmount()} TL</div>
                </div>
                <div
                  className="d-flex flex-row justify-content-between align-items-center mt-2"
                  style={{ fontWeight: "bold" }}
                >
                  <div>Ödenecek (İndirimli) Tutar</div>
                  <div>{calculateDiscountedTotalPriceInformation()} TL</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <button
                className="secondary-button mt-3"
                onClick={handleUploadPage}
              >
                Yeni Model Ekle
              </button>
              <button
                className="primary-button mt-3"
                onClick={handleNextPageState}
              >
                Siparişe Ekle
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  const RenderCartDetailBasedOnProductionType = () => {
    if (selectedProductionType.id === 0) {
      return Object.keys(parsedFileMap).map((parsedFileKey) => (
        <BoxLetterCartDetail
          parsedFileKey={parsedFileKey}
          parsedFile={constructedFileMap[parsedFileKey]}
        />
      ));
    } else if (selectedProductionType.id === 1) {
      return Object.keys(parsedFileMap).map((parsedFileKey) => (
        <CncCuttingCartDetail
          parsedFileKey={parsedFileKey}
          parsedFile={constructedFileMap[parsedFileKey]}
        />
      ));
    } else if (selectedProductionType.id === 2) {
      return Object.keys(parsedFileMap).map((parsedFileKey) => (
        <LaserCuttingCartDetail
          parsedFileKey={parsedFileKey}
          parsedFile={constructedFileMap[parsedFileKey]}
        />
      ));
    }
    return null;
  };

  const RenderCartInformation = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div className="row">
          <div className="col-md-12">
            <div className="offer-base-information-selection-base-header">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  {selectedProductionType.name} |
                  <span style={{ fontStyle: "italic", marginLeft: 5 }}>
                    Seçtiğiniz özellikleri görüntüleyin
                  </span>
                </div>

                <IoArrowBackCircleOutline
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setPageState(pageState - 1);
                  }}
                  size={40}
                  color="red"
                />
              </div>
            </div>
            {RenderCartDetailBasedOnProductionType()}
            <div
              style={{
                height: 2,
                backgroundColor: "lightgray",
                marginTop: 20,
                marginBottom: 20,
              }}
            />
            <div className="row">
              <div className="col-md-6">
                <div style={{ marginTop: 30 }}>
                  <div style={{ fontWeight: "bold" }}> Sipariş Notu</div>
                  <textarea
                    id="order_note"
                    style={{
                      width: "100%",
                      height: 200,
                      marginTop: 10,
                      padding: 20,
                      borderRadius: 5,
                    }}
                    onChange={(e) => {
                      setOrderNote(e.target.value);
                    }}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                {Object.keys(parsedFileMap).map((parsedFileKey) =>
                  retrieveCalculateSinglePriceInformation(parsedFileKey)
                )}
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>Kargo</div>
                  <div>{calculateShippingPrice()} TL + KDV</div>
                </div>
                <div
                  style={{
                    height: 2,
                    backgroundColor: "rgba(0,0,0,0.3)",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                />
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>Toplam Tutar</div>
                  <div>{calculateTotalPriceInformation()} TL</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div>İskonto Tutarı</div>
                  <div>{calculateDiscountAmount()} TL</div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                  <div>Ödenecek (İndirimli) Tutar</div>
                  <div>{calculateDiscountedTotalPriceInformation()} TL</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between align-items-center">
              <button
                className="secondary-button mt-3"
                onClick={handleUploadPage}
              >
                Yeni Model Ekle
              </button>
              <button
                className="primary-button mt-3"
                onClick={handleNextPageState}
              >
                Siparişi Tamamla
              </button>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  const RenderShippingBillingInformation = () => {
    return (
      <React.Fragment>
        <ShippingBillingInformation
          selectedBillingId={selectedBillingId}
          setSelectedBillingId={setSelectedBillingId}
          selectedBilling={billingList.find(
            (billing) => billing.id === selectedBillingId
          )}
          selectedShippingId={selectedShippingId}
          setSelectedShippingId={setSelectedShippingId}
          selectedShipping={shippingList.find(
            (shipping) => shipping.id === selectedShippingId
          )}
          shippingList={shippingList}
          billingList={billingList}
          setAddressUpdated={setAddressUpdated}
        />
        <div className="d-flex flex-column justify-content-center align-items-center mt-4">
          <div className="col-md-4">
            {selectedBillingId !== null && selectedShippingId !== null ? (
              <button
                className="col-md-12 primary-button"
                onClick={handleNextPageState}
              >
                Devam et
              </button>
            ) : (
              RenderLoadingAnimation()
            )}
          </div>
        </div>
      </React.Fragment>
    );
  };

  const handlePaymentInformation = async () => {
    setPaymentLoading(true);
    const currentUser = await Auth.currentUserInfo();
    let selectedShipping;
    if (selectedShippingId === 0) {
      selectedShipping = {
        fullName: "ELDEN TESLIM",
        city: "ELDEN TESLIM",
        country: "ELDEN TESLIM",
        address: "ELDEN TESLIM",
        postalCode: "06378",
      };
    } else {
      selectedShipping = shippingList.find(
        (shipping) => shipping.id === selectedShippingId
      );
    }
    const selectedBilling = billingList.find(
      (billing) => billing.id === selectedBillingId
    );
    const specificId = new Date().getTime();
    setConversationId(specificId);
    const payment = {
      locale: "TR",
      conversationId: specificId,

      currency: "TRY",
      installment: "1",
      basketId: specificId,
      callbackUrl: `${config.baseUrl}${config.payment.context}${config.payment.prod.responseThreeDs.context}`,
      paymentChannel: "WEB",
      paymentGroup: "PRODUCT",
      paymentCard: {
        cardHolderName: paymentInformation.name,
        cardNumber: paymentInformation.number,
        expireMonth: paymentInformation.expiration.split("/")[0],
        expireYear: paymentInformation.expiration.split("/")[1],
        cvc: paymentInformation.cvc,
        registerCard: "0",
      },
      buyer: {
        id: userInformation.email,
        name: userInformation.name,
        surname: userInformation.lastName,
        gsmNumber: "+905350000000",
        email: userInformation.email,
        identityNumber: "74300864791",
        lastLoginDate: "2015-10-05 12:43:35",
        registrationDate: "2013-04-21 15:12:09",
        registrationAddress:
          "Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1",
        ip: "85.34.78.112",
        city: "Istanbul",
        country: "Turkey",
        zipCode: "34732",
      },
      shippingAddress: {
        contactName: selectedShipping.fullName,
        city: selectedShipping.city,
        country: selectedShipping.country,
        address: selectedShipping.address,
        zipCode: selectedShipping.postalCode,
      },
      billingAddress: {
        contactName: selectedBilling.fullName,
        city: selectedBilling.city,
        country: selectedBilling.country,
        address: selectedBilling.address,
        zipCode: selectedBilling.postalCode,
      },
      // basketItems: [
      //   {
      //     id: "0",
      //     name: "Game code",
      //     category1: "Game",
      //     category2: "Online Game Items",
      //     itemType: "VIRTUAL",
      //     price: "0.5",
      //   },
      // ],
    };
    const basketItemList = [];
    for (const constructedFileKey in constructedFileMap) {
      const index = Object.keys(constructedFileMap).indexOf(constructedFileKey);
      const price = parseFloat(
        calculateSinglePriceInformation(constructedFileKey)
      );
      const taxPrice = parseFloat(calculateTaxPriceInformation(price));
      basketItemList.push({
        id: index.toString(),
        name: constructedFileKey,
        category1: selectedProductionType.name,
        category2: "Fiziksel Eşya",
        itemType: "PHYSICAL",
        price: (price + taxPrice).toFixed(2),
      });
    }
    // const shippingPrice = parseFloat(calculateShippingPrice());
    // const shippingTaxPrice = parseFloat(
    //   calculateTaxPriceInformation(shippingPrice)
    // );
    // basketItemList.push({
    //   id: basketItemList.length,
    //   name: "kargo",
    //   category1: "Kargo",
    //   category2: "Fiziksel Eşya",
    //   itemType: "PHYSICAL",
    //   price: (shippingPrice + shippingTaxPrice).toFixed(2),
    // });
    payment.basketItems = basketItemList;
    let totalPrice = 0;
    basketItemList.forEach((basketItem) => {
      totalPrice += parseFloat(basketItem.price);
    });
    payment.price = totalPrice.toFixed(2);
    payment.paidPrice = calculateDiscountedTotalPriceInformation();
    try {
      const response = await initializeThreeDsPayment(payment);
      if (response.status === "failure") {
        toast(response.errorMessage);
        setPaymentLoading(false);
      } else {
        setThreeDsHtmlContent(response.threeDSHtmlContent);
        setShowThreeDsModal(true);
        // handleNextPageState(response.paymentId, response.conversationId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const RenderPaymentInformation = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div className="col-md-12">
          <div className="offer-base-information-selection-base-header">
            <span style={{ fontStyle: "italic", marginLeft: 5 }}>
              Ödeme bilgilerinizi girin
            </span>
          </div>
          <PaymentDetail
            paymentInformation={paymentInformation}
            setPaymentInformation={setPaymentInformation}
            paymentFocused={paymentFocused}
            setPaymentFocused={setPaymentFocused}
          />
          <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <div className="col-md-4">
              <button
                className="col-md-12 primary-button"
                onClick={handlePaymentInformation}
              >
                Ödeme yap
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderSuccessfulPayment = () => {
    return (
      <div className="offer-base-information-selection-base">
        <div className="col-md-12">
          <div className="offer-base-information-selection-base-header">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <BsCheck2Circle size={150} color="green" />
              <div style={{ marginTop: 20 }}>Ödemeniz Tamamlandı</div>
              <div
                className="col-md-6"
                style={{
                  fontSize: 14,
                  fontWeight: "normal",
                  marginTop: 10,
                  textAlign: "center",
                }}
              >
                Ödemeniz başarı ile alındı. Dilerseniz siparişlerim sayfasından
                siparişinizin durumunu kontrol edebilirsiniz
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const RenderServiceInformation = () => {
    switch (pageState) {
      case 0:
        return RenderSelectService();
      case 1:
        return RenderUploadFile();
      case 2:
        return RenderUploadedFileListDetails();
      case 3:
        return RenderCartInformation();
      case 4:
        return RenderShippingBillingInformation();
      case 5:
        return RenderPaymentInformation();
      case 6:
        return RenderSuccessfulPayment();
      default:
        return null;
    }
  };

  // const retrieveServiceInformationText = () => {
  //   switch (pageState) {
  //     case 0:
  //       return "İstediğiniz hizmeti seçip adımları takip ederek teklif talebimizi değerlendirebilirsiniz";
  //     case 1:
  //       return "Bir dosya yükleyin";
  //     case 2:
  //       return "Teknik özelliklerini, adet sayısını, malzemesini belirterek teklif sürecini hızlandırabilirsiniz";
  //     case 3:
  //       return "Sepetinizdeki ürünlerin adedi ve gönderim seçeneğini belirleyerek hemen siparişinizi tamamlayabilirsiniz";
  //     case 4:
  //       return "Sipariş ve faturanız için adres seçmelisiniz";
  //     case 5:
  //       return "Ödeme bilgilerinizi girerek hızlıca alışverişinizi sonlandırabilirsiniz";
  //     default:
  //       return null;
  //   }
  // };

  const retrieveCurrentColor = (index) => {
    if (pageState === index) {
      return "#ff6700";
    } else if (pageState > index) {
      return "#ff6700";
    } else {
      return "lightgray";
    }
  };

  const RenderServiceInformationText = () => {
    return (
      <div
        className="d-flex flex-row flex-wrap justify-content-center align-items-center w-100"
        style={{ marginBottom: 20 }}
      >
        {serviceTypeList.map((serviceType, serviceTypeIndex) => (
          <div className="d-flex justify-content-center mt-3">
            <div
              style={{
                border: `1px solid ${retrieveCurrentColor(serviceTypeIndex)}`,
                color: retrieveCurrentColor(serviceTypeIndex),
                fontWeight: "bold",
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 5,
                cursor: "pointer",
              }}
              onClick={() => {
                if (pageState > serviceTypeIndex) {
                  setPageState(serviceTypeIndex);
                }
              }}
            >
              {serviceType.name}
            </div>
            {serviceTypeIndex !== serviceTypeList.length - 1 ? (
              <div className="d-flex align-items-center">
                <div
                  style={{
                    height: 1,
                    width: 30,
                    backgroundColor: "black",
                    border: "1px dashed lightgray",
                  }}
                ></div>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    );

    // if (pageState < 6) {
    //   return (
    //     <div className="d-flex flex-column">
    //       <div className="offer-base-information-text-state align-self-end">
    //         0{pageState + 1}/06
    //       </div>
    //       <div className="d-flex flex-column align-items-end">
    //         <div className="offer-base-information-text  col-md-6">
    //           {retrieveServiceInformationText()}
    //         </div>
    //       </div>
    //     </div>
    //   );
    // } else {
    //   return null;
    // }
  };

  return (
    <React.Fragment>
      <ModalView
        show={showThreeDsModal}
        onHide={handleCloseShowThreeDsModal}
        header={InitializeThreeDsModalHeader}
        body={
          <InitializeThreeDsModalBody
            src={`data:text/html;base64,${threeDsHtmlContent}`}
            onHide={handleCloseShowThreeDsModal}
          />
        }
        footer={InitializeThreeDsModalFooter}
      />
      <div className="offer-base-template">
        <div className="container">
          <div className="col-md-12" style={{ margin: "auto" }}>
            {RenderServiceInformationText()}
          </div>
          {paymentLoading ? (
            <LoadingAnimation height={400} />
          ) : (
            RenderServiceInformation()
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OfferScreen;
