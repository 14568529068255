import axios from "axios";
import config from "../config/config.json";

export const queryPrintingTypeDetailList = async (printingTypeId) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.printing_type_details.context}${config.printing_type_details.query.context}`,
      {
        printingTypeId,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
