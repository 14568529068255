import InputGroup from "../../components/InputGroup";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import InputSwitch from "../../components/InputSwitch";
import { createUser } from "../../requests/userRequests";

const RegisterScreen = () => {
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(0);
  const [registerInformation, setRegisterInformation] = useState(null);
  const [isIndividual, setIsIndividual] = useState(true);

  const handleOnClick = () => {
    const name = document.getElementById("name").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    const email = document.getElementById("email").value.trim();
    const password = document.getElementById("password").value.trim();
    let corporateName;
    let taxOffice;
    let taxNumber;
    let phoneNumber;
    if (!isIndividual) {
      corporateName = document.getElementById("corporateName").value.trim();
      taxOffice = document.getElementById("taxOffice").value.trim();
      taxNumber = document.getElementById("taxNumber").value.trim();
      phoneNumber = document.getElementById("phoneNumber").value.trim();
      if (!(corporateName && taxOffice && taxNumber && phoneNumber)) {
        toast("Lütfen bütün alanları doldurun");
        return;
      }
    }
    if (name && lastName && email && password) {
      Auth.signUp({
        username: email,
        password,
        attributes: { email, "custom:name": name, "custom:lastName": lastName },
      })
        .then(async () => {
          const dynamoDbUser = {
            id: new Date().getTime(),
            name,
            lastName,
            email,
            corporateName,
            taxOffice,
            taxNumber,
            phoneNumber,
            type: isIndividual ? "INDIVIDUAL" : "CORPORATE",
            discountPercentage: 0.0,
            defaultBillingId: null,
            defaultShippingId: null,
          };
          await createUser(dynamoDbUser);

          toast(
            "Aktivasyon kodunuz başarıyla gönderildi. Lütfen e-mailinizi kontrol edin."
          );
          setRegisterInformation({
            username: email,
          });
          setPageState(1);
        })
        .catch((err) => {
          if (err.message === "User already exists") {
            toast("Bu email adresinde kullanıcı bulunmakta");
          } else if (
            err.message ===
            "Password did not conform with policy: Password not long enough"
          ) {
            toast("Şifreniz yeterli uzunluğa sahip değil");
          } else if (
            err.message ===
            "Password did not conform with policy: Password must have lowercase characters"
          ) {
            toast("Şifrenizde küçük harf bulundurmalısınız");
          } else if (
            err.message ===
            "Password did not conform with policy: Password must have uppercase characters"
          ) {
            toast("Şifrenizde büyük harf bulundurmalısınız");
          } else if (
            err.message ===
            "Password did not conform with policy: Password must have symbol characters"
          ) {
            toast("Şifrenizde sembol bulundurmalısınız");
          }
        });
    } else {
      toast("Lütfen bütün alanları doldurun");
    }
  };

  const handleVerifyAccount = () => {
    const verificationCode = document
      .getElementById("verification_code")
      .value.trim();
    const username = registerInformation.username;
    if (username && verificationCode) {
      Auth.confirmSignUp(username, verificationCode)
        .then((data) => {
          if (data === "SUCCESS") {
            toast(
              "Hesabınız başarı ile aktive oldu. Giriş yaparak devam edebilirsiniz."
            );
            navigate("/login");
          }
          console.log(data);
        })
        .catch((err) => {
          if (
            (err.message =
              "Invalid verification code provided, please try again.")
          ) {
            toast(
              "Geçersiz aktivasyon kodu, lütfen e-mailinize en son gönderilen kodu girin ve devam edin."
            );
          }
        });
    } else {
      toast("Please provide a valid verification code");
    }
  };

  const handleResendCode = () => {
    const username = registerInformation.username;
    if (username) {
      Auth.resendSignUp(username)
        .then(() => {
          toast(
            "Aktivasyon kodunuz başarıyla gönderildi. Lütfen e-mailinizi kontrol edin."
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast("Please provide a valid email");
    }
  };

  const RenderRegisterForm = () => {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div
          style={{
            fontWeight: "bold",
            fontSize: 32,
            textAlign: "center",
          }}
        >
          Kayıt Olun
        </div>

        <div className="d-flex flex-row align-items-center mt-2">
          <div
            style={{
              fontSize: 20,
              fontWeight: isIndividual ? "bold" : "normal",
              marginRight: 10,
              color: isIndividual ? "#ff6700" : "gray",
            }}
          >
            Bireysel
          </div>
          <InputSwitch
            onChange={(e) => {
              setIsIndividual(!e.target.checked);
            }}
          />
          <div
            style={{
              fontSize: 20,
              fontWeight: !isIndividual ? "bold" : "normal",
              marginLeft: 10,
              color: !isIndividual ? "#3c3c3c" : "gray",
            }}
          >
            Kurumsal
          </div>
        </div>

        <div className="col-md-9" style={{ marginTop: 20 }}>
          <div className="row">
            <div className="col-md-6">
              <InputGroup
                id="name"
                label="İsim"
                type="text"
                placeholder="İsminiz"
              />
            </div>
            <div className="col-md-6">
              <InputGroup
                id="lastName"
                label="Soyisim"
                type="text"
                placeholder="Soyisminiz"
              />
            </div>
          </div>
          <div className="mt-2">
            <InputGroup
              id="email"
              label="E-Mail"
              type="text"
              placeholder="E-mail adresinizi girin"
            />
          </div>
          <div className="mt-2">
            <InputGroup
              id="password"
              label="Şifre"
              type="password"
              placeholder="Şifrenizi girin"
            />
          </div>
          <div className="mt-2" style={{ fontSize: 12 }}>
            <div style={{ fontWeight: "bold" }}>
              Şifreniz şunları içermelidir:{" "}
            </div>
            <div className="d-flex flex-row align-items-center">
              <MdKeyboardArrowRight
                size={20}
                style={{ marginRight: 10 }}
                color="green"
              />
              Büyük harf, küçük harf
            </div>
            <div className="d-flex flex-row align-items-center">
              <MdKeyboardArrowRight
                size={20}
                style={{ marginRight: 10 }}
                color="green"
              />
              En az 8 karakter
            </div>
            <div className="d-flex flex-row align-items-center">
              <MdKeyboardArrowRight
                size={20}
                style={{ marginRight: 10 }}
                color="green"
              />
              Sayı
            </div>
            <div className="d-flex flex-row align-items-center">
              <MdKeyboardArrowRight
                size={20}
                style={{ marginRight: 10 }}
                color="green"
              />
              ".!?" gibi özel karakterler
            </div>
          </div>

          {!isIndividual ? (
            <div style={{ borderTop: "1px solid lightgray", marginTop: 10 }}>
              <div className="mt-2">
                <InputGroup
                  id="corporateName"
                  label="Kurum Adı"
                  type="text"
                  placeholder="Kurum Adı"
                />
              </div>
              <div className="mt-2">
                <InputGroup
                  id="taxOffice"
                  label="Vergi Dairesi"
                  type="text"
                  placeholder="Vergi Dairesi"
                />
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <InputGroup
                    id="taxNumber"
                    label="Vergi Numarası"
                    type="number"
                    placeholder="Vergi Numarası"
                  />
                </div>
                <div className="col-md-6">
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 7,
                        left: 15,
                      }}
                    >
                      +90
                    </div>
                    <InputGroup
                      id="phoneNumber"
                      label="İrtibat Numarası"
                      type="number"
                      placeholder="İrtibat Numarası"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-md-6 mt-4 text-center w-100">
            <button
              className="primary-button"
              onClick={handleOnClick}
              style={{ width: "100%" }}
            >
              Kayıt Ol
            </button>
          </div>
          <div style={{ textAlign: "right", marginTop: 10, fontSize: 14 }}>
            Zaten bir hesabınız var mı? Dilerseniz{" "}
            <span
              style={{
                fontWeight: "bold",
                color: "blue",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/login");
              }}
            >
              buradan
            </span>{" "}
            giriş yapabilirsiniz
          </div>
        </div>
      </div>
    );
  };

  const RenderActivationEmail = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ fontWeight: "bold", fontSize: 32, textAlign: "center" }}>
          Hesabınızı aktive edin
        </div>
        <div className="col-md-9">
          <div style={{ fontSize: 14, textAlign: "center", marginTop: 20 }}>
            Lütfen vermiş olduğunuz emailinize gönderilen şifreyi aşağıdaki
            alana giriniz
          </div>
        </div>
        <div className="col-md-9" style={{ marginTop: 40 }}>
          <InputGroup
            id="verification_code"
            label="Aktivasyon Kodu"
            type="number"
            placeholder="Aktivasyon kodunu girin"
          />

          <div className="col-md-6 mt-5 text-center w-100">
            <button
              className="primary-button"
              onClick={handleVerifyAccount}
              style={{ width: "100%" }}
            >
              Hesabımı aktive et
            </button>
          </div>
          <div className="col-md-6 mt-2 text-center w-100">
            <button
              className="secondary-button"
              onClick={handleResendCode}
              style={{ width: "100%" }}
            >
              Kodumu yeniden gönder
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderCurrentPageState = () => {
    switch (pageState) {
      case 0:
        return RenderRegisterForm();
      case 1:
        return RenderActivationEmail();
      default:
        return null;
    }
  };
  return (
    <div className="login-register-screen-template">
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-md-6">
            <img
              src="/images/register_image.png"
              style={{ width: "100%", marginTop: -50 }}
            ></img>
          </div>
          <div className="col-md-6">{RenderCurrentPageState()}</div>
        </div>
      </div>
    </div>
  );
};

export default RegisterScreen;
