import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryOrderList } from "../../requests/orderRequests";
import { GiHourglass } from "react-icons/gi";
import { BsCheck2Circle } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import fileDownload from "js-file-download";
import axios from "axios";

const MyOrderScreen = () => {
  const [orderList, setOrderList] = useState([]);
  const [pageReady, setPageReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserHasLoggedIn = async () => {
      const currentUser = await Auth.currentUserInfo();
      if (currentUser === null) {
        navigate("/login");
      } else {
        const foundOrderList = await queryOrderList(currentUser.username);
        setOrderList(foundOrderList);
        setPageReady(true);
      }
    };
    checkUserHasLoggedIn();
  }, []);

  const handleDownload = async (url, filename) => {
    const axiosInstance = axios.create();
    await axiosInstance
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const RenderSingleOrderItemKeyValue = (key, value) => {
    return (
      <React.Fragment>
        <div style={{ fontWeight: "bold" }}>{key}</div>
        <div>{value}</div>
      </React.Fragment>
    );
  };

  const RenderBoxLetterOrder_PleksiglasMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Kutu Harf
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Materyal", "Pleksiglass")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Pleksi Rengi",
            orderItem.pleksiColor.name
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Pleksi Kalınlığı",
            `${orderItem.pleksiThickness.thickness}(${orderItem.pleksiThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Harf Derinliği",
            `${orderItem.sideBandDepth.depth}(${orderItem.sideBandDepth.depthUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Kalınlığı",
            `${orderItem.sideBandThickness.thickness}(${orderItem.sideBandThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Rengi",
            orderItem.sideBandColor.name
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "LED",
            orderItem.includeLed ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.includeLed ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "LED Rengi",
                orderItem.ledColor.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo",
                orderItem.transformerType.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo Adedi",
                orderItem.transformerCount
              )}
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Dekota",
            orderItem.includeDekota ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.dekotaThickness ? (
          <div className="col-md-3">
            {RenderSingleOrderItemKeyValue(
              "Dekota Kalınlığı",
              `${orderItem.dekotaThickness.thickness}(${orderItem.dekotaThickness.thicknessUnit})`
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderBoxLetterOrder_AliminiumMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Kutu Harf
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Materyal", "Alüminyum")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Aydınlatma",
            orderItem.lightingType === 0 ? "Önden" : "Arkadan"
          )}
        </div>
        {orderItem.lightingType === 0 ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Pleksi Rengi",
                orderItem.pleksiColor.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Pleksi Kalınlığı",
                `${orderItem.pleksiThickness.thickness}(${orderItem.pleksiThickness.thicknessUnit})`
              )}
            </div>
          </React.Fragment>
        ) : null}

        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Harf Derinliği",
            `${orderItem.sideBandDepth.depth}(${orderItem.sideBandDepth.depthUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Kalınlığı",
            `${orderItem.sideBandThickness.thickness}(${orderItem.sideBandThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Harf Rengi",
            orderItem.letterColor.name
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Ayak",
            orderItem.includeFoot ? "Evet" : "Hayır"
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "LED",
            orderItem.includeLed ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.includeLed ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "LED Rengi",
                orderItem.ledColor.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo",
                orderItem.transformerType.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo Adedi",
                orderItem.transformerCount
              )}
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Dekota",
            orderItem.includeDekota ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.dekotaThickness ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Dekota Kalınlığı",
                `${orderItem.dekotaThickness.thickness}(${orderItem.dekotaThickness.thicknessUnit})`
              )}
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderBoxLetterOrder_KromMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Kutu Harf
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Materyal", "Krom")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Aydınlatma",
            orderItem.lightingType === 0 ? "Önden" : "Arkadan"
          )}
        </div>
        {orderItem.lightingType === 0 ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Pleksi Rengi",
                orderItem.pleksiColor.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Pleksi Kalınlığı",
                `${orderItem.pleksiThickness.thickness}(${orderItem.pleksiThickness.thicknessUnit})`
              )}
            </div>
          </React.Fragment>
        ) : null}

        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Krom Rengi",
            `${orderItem.kromColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Deseni",
            `${orderItem.kromPattern.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Harf Derinliği",
            `${orderItem.sideBandDepth.depth}(${orderItem.sideBandDepth.depthUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Kalınlığı",
            `${orderItem.sideBandThickness.thickness}(${orderItem.sideBandThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Yanak Rengi",
            orderItem.sideBandColor.name
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Ayak",
            orderItem.includeFoot ? "Evet" : "Hayır"
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "LED",
            orderItem.includeLed ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.includeLed ? (
          <React.Fragment>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "LED Rengi",
                orderItem.ledColor.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo",
                orderItem.transformerType.name
              )}
            </div>
            <div className="col-md-3">
              {RenderSingleOrderItemKeyValue(
                "Trafo Adedi",
                orderItem.transformerCount
              )}
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Dekota",
            orderItem.includeDekota ? "Evet" : "Hayır"
          )}
        </div>
        {orderItem.dekotaThickness ? (
          <div className="col-md-3">
            {RenderSingleOrderItemKeyValue(
              "Dekota Kalınlığı",
              `${orderItem.dekotaThickness.thickness}(${orderItem.dekotaThickness.thicknessUnit})`
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderCncCutting_WoodMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            CNC Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Ahşap")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
        {orderItem.sideBandDepth ? (
          <div className="col-md-3">
            {RenderSingleOrderItemKeyValue(
              "Yan Bant",
              orderItem.sideBandDepth.depthName
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderCncCutting_PlasticMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            CNC Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Plastik")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
      </React.Fragment>
    );
  };

  const RenderCncCutting_MetalMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            CNC Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Metal")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
      </React.Fragment>
    );
  };

  const RenderLaserCutting_WoodMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Lazer Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Ahşap")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
        {orderItem.sideBandDepth ? (
          <div className="col-md-3">
            {RenderSingleOrderItemKeyValue(
              "Yan Bant",
              orderItem.sideBandDepth.depthName
            )}
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderLaserCutting_PlasticMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Lazer Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Plastik")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
      </React.Fragment>
    );
  };

  const RenderLaserCutting_MetalMaterial = (orderItem) => {
    return (
      <React.Fragment>
        <div className="d-flex">
          <div
            style={{
              marginBottom: 10,
              fontWeight: "bold",
              fontStyle: "italic",
              border: "1px solid",
              borderRadius: 10,
              padding: 5,
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            Lazer Kesim
          </div>
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme Grubu", "Metal")}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Malzeme", orderItem.cuttingType.name)}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Malzeme Kalınlığı",
            `${orderItem.materialThickness.thickness}(${orderItem.materialThickness.thicknessUnit})`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue(
            "Renk",
            `${orderItem.materialColor.name}`
          )}
        </div>
        <div className="col-md-3">
          {RenderSingleOrderItemKeyValue("Adet", orderItem.count)}
        </div>
      </React.Fragment>
    );
  };

  const RenderBoxLetterOrder = (orderItem) => {
    if (orderItem.baseMaterial !== null) {
      if (orderItem.baseMaterial === 0) {
        return RenderBoxLetterOrder_PleksiglasMaterial(orderItem);
      } else if (orderItem.baseMaterial === 1) {
        return RenderBoxLetterOrder_AliminiumMaterial(orderItem);
      } else if (orderItem.baseMaterial === 2) {
        return RenderBoxLetterOrder_KromMaterial(orderItem);
      }
    }
    return null;
  };

  const RenderCncCuttingOrder = (orderItem) => {
    if (orderItem.baseMaterial !== null) {
      if (orderItem.baseMaterial === 3) {
        return RenderCncCutting_WoodMaterial(orderItem);
      } else if (orderItem.baseMaterial === 4) {
        return RenderCncCutting_PlasticMaterial(orderItem);
      } else if (orderItem.baseMaterial === 5) {
        return RenderCncCutting_MetalMaterial(orderItem);
      }
    }
    return null;
  };

  const RenderLaserCuttingOrder = (orderItem) => {
    if (orderItem.baseMaterial !== null) {
      if (orderItem.baseMaterial === 6) {
        return RenderLaserCutting_WoodMaterial(orderItem);
      } else if (orderItem.baseMaterial === 7) {
        return RenderLaserCutting_PlasticMaterial(orderItem);
      } else if (orderItem.baseMaterial === 8) {
        return RenderLaserCutting_MetalMaterial(orderItem);
      }
    }
    return null;
  };

  const RenderSingleOrderItemDescriptionBase = (orderItem) => {
    if (orderItem.productionType) {
      if (orderItem.productionType.id === 0) {
        return RenderBoxLetterOrder(orderItem);
      } else if (orderItem.productionType.id === 1) {
        return RenderCncCuttingOrder(orderItem);
      } else if (orderItem.productionType.id === 2) {
        return RenderLaserCuttingOrder(orderItem);
      }
      return null;
    }
    return null;
  };

  const RenderSingleOrderItemDescription = (orderItem) => {
    return (
      <div className="order-item-description-template row">
        {RenderSingleOrderItemDescriptionBase(orderItem)}
      </div>
    );
  };

  const RenderSingleOrderItem = (orderItem, orderItemIndex) => {
    return (
      <div className="d-flex flex-column">
        <div className="order-item-template row">
          <div className="col-md-4">
            <span style={{ fontWeight: "bold" }}>
              İçerik {orderItemIndex + 1}:{" "}
            </span>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={async () => {
                const currentUser = await Auth.currentUserInfo();
                const filename = encodeURIComponent(orderItem.name);
                handleDownload(
                  `https://net-uretim-files.s3.eu-west-1.amazonaws.com/${currentUser.username.replace(
                    "@",
                    "-"
                  )}/${filename}`,
                  orderItem.name
                );
              }}
            >
              {orderItem.name}
            </span>
          </div>
          <div className="col-md-4">
            <span style={{ fontWeight: "bold", marginLeft: 10 }}>
              Ölçüler:{" "}
            </span>
            X: {orderItem.x.toFixed(2)}({orderItem.metric.toLowerCase()}), Y:{" "}
            {orderItem.y.toFixed(2)}({orderItem.metric.toLowerCase()})
          </div>
          <div className="col-md-4">
            <span style={{ fontWeight: "bold", marginLeft: 10 }}>Fiyat: </span>
            {(
              parseFloat(orderItem.price) + parseFloat(orderItem.taxPrice)
            ).toFixed(2)}{" "}
            TL(
            {parseFloat(orderItem.price).toFixed(2)} TL + KDV)
          </div>
        </div>
        <div className="col">
          {RenderSingleOrderItemDescription(orderItem, orderItemIndex)}
        </div>
      </div>
    );
  };

  const RenderOrderStatus = (order) => {
    if (order.status === "WAITING") {
      return (
        <GiHourglass size={40} style={{ marginRight: 10 }} color="#ff6700" />
      );
    } else if (order.status === "APPROVED") {
      return (
        <BsCheck2Circle size={40} style={{ marginRight: 10 }} color="green" />
      );
    } else if (order.status === "REJECTED") {
      return <ImCross size={40} style={{ marginRight: 10 }} color="red" />;
    }
  };

  const RenderOrder = (order) => {
    return (
      <div className="order-template col">
        <div className="order-template-header">
          <span style={{ fontWeight: "bold" }}>Sipariş Numarası: </span>
          {order.paymentId}
        </div>
        <div className="order-template-header">
          <span style={{ fontWeight: "bold" }}>Sipariş Tarihi: </span>
          {new Date(order.id).toLocaleString("tr")}
        </div>
        <div className="d-flex flex-row mt-3">
          <div className="col-md-6">
            <div
              style={{
                border: "1px solid lightgray",
                padding: 10,
                margin: 10,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Kargo Adresi
              </div>
              <div style={{ marginLeft: 20 }}>
                <div>{order.shipping.heading}</div>
                <div>{order.shipping.address}</div>
                <div>+90{order.shipping.phoneNumber}</div>
                {order.shipping.addressType === "CORPORATE" ? (
                  <div>
                    {order.shipping.corporateName}, {order.shipping.taxOffice},{" "}
                    {order.shipping.taxNumber}
                  </div>
                ) : null}
              </div>
              <div
                className="mt-2"
                style={{
                  fontWeight: "bold",
                }}
              >
                Fatura Bilgisi
              </div>
              <div style={{ marginLeft: 20 }}>
                <div>{order.billing.heading}</div>
                <div>{order.billing.address}</div>
                <div>+90{order.billing.phoneNumber}</div>
                {order.billing.addressType === "CORPORATE" ? (
                  <div>
                    {order.billing.corporateName}, {order.billing.taxOffice},{" "}
                    {order.billing.taxNumber}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div
              style={{
                border: "1px solid lightgray",
                padding: 10,
                margin: 10,
                borderRadius: 5,
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                Sipariş Notu
              </div>
              <div style={{ marginLeft: 20 }}>{order.note}</div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-start align-items-center mt-3">
          <div className="d-flex justify-content-center align-items-center col-md-1">
            {RenderOrderStatus(order)}
          </div>
          <div className="col-md-11">
            {order.itemList.map((orderItem, orderItemIndex) =>
              RenderSingleOrderItem(orderItem, orderItemIndex)
            )}
          </div>
        </div>
      </div>
    );
  };

  const RenderOrderList = () => {
    return (
      <div className="d-flex flex-column justify-content-start align-items-start my-orders-screen-template">
        {orderList.map((order) => RenderOrder(order))}
      </div>
    );
  };

  const RenderEmptyOrderList = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center my-orders-screen-template">
        <h1>Henüz siparişiniz bulunmamaktadır</h1>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (orderList.length === 0) {
      return RenderEmptyOrderList();
    } else {
      return RenderOrderList();
    }
  };

  return (
    <div className="container">
      {pageReady ? (
        RenderMainContent()
      ) : (
        <React.Fragment>
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <img
              src="loading/loading_animation.gif"
              alt="loading_animation"
              style={{ width: 100, height: 100 }}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default MyOrderScreen;
