const equalIfIOperation = (input, value, state) => {
  if (state === "BIGGER_THAN") {
    if (input > value) {
      return value;
    }
  } else if (state === "SMALLER_THAN") {
    if (input < value) {
      return value;
    }
  }
  return input;
};

const calculateDistanceBetweenPoints = (startPoint, endPoint) => {
  const distanceY = endPoint.y - startPoint.y;
  const distanceX = endPoint.x - startPoint.x;
  return Math.sqrt(distanceY * distanceY + distanceX * distanceX);
};

export const analyzeDXFFile = (json, width) => {
  let lowest_x = Number.MAX_SAFE_INTEGER;
  let lowest_y = Number.MAX_SAFE_INTEGER;
  let highest_x = Number.MIN_SAFE_INTEGER;
  let highest_y = Number.MIN_SAFE_INTEGER;
  json.entities.forEach((entity) => {
    let locale_lowest_x = Number.MAX_SAFE_INTEGER;
    let locale_lowest_y = Number.MAX_SAFE_INTEGER;
    let locale_highest_x = Number.MIN_SAFE_INTEGER;
    let locale_highest_y = Number.MIN_SAFE_INTEGER;
    entity.distance = 0;
    if (entity.type === "LINE") {
      entity.vertices.forEach((vertice, verticeIndex) => {
        lowest_x = equalIfIOperation(lowest_x, vertice.x, "BIGGER_THAN");
        lowest_y = equalIfIOperation(lowest_y, vertice.y, "BIGGER_THAN");
        highest_x = equalIfIOperation(highest_x, vertice.x, "SMALLER_THAN");
        highest_y = equalIfIOperation(highest_y, vertice.y, "SMALLER_THAN");
        locale_lowest_x = equalIfIOperation(
          locale_lowest_x,
          vertice.x,
          "BIGGER_THAN"
        );
        locale_lowest_y = equalIfIOperation(
          locale_lowest_y,
          vertice.y,
          "BIGGER_THAN"
        );
        locale_highest_x = equalIfIOperation(
          locale_highest_x,
          vertice.x,
          "SMALLER_THAN"
        );
        locale_highest_y = equalIfIOperation(
          locale_highest_y,
          vertice.y,
          "SMALLER_THAN"
        );
        if (verticeIndex < entity.vertices.length - 1) {
          entity.distance += calculateDistanceBetweenPoints(
            entity.vertices[verticeIndex + 1],
            entity.vertices[verticeIndex]
          );
        }
      });
    } else if (entity.type === "SPLINE") {
      entity.controlPoints.forEach((controlPoint, controlPointIndex) => {
        lowest_x = equalIfIOperation(lowest_x, controlPoint.x, "BIGGER_THAN");
        lowest_y = equalIfIOperation(lowest_y, controlPoint.y, "BIGGER_THAN");
        highest_x = equalIfIOperation(
          highest_x,
          controlPoint.x,
          "SMALLER_THAN"
        );
        highest_y = equalIfIOperation(
          highest_y,
          controlPoint.y,
          "SMALLER_THAN"
        );
        locale_lowest_x = equalIfIOperation(
          locale_lowest_x,
          controlPoint.x,
          "BIGGER_THAN"
        );
        locale_lowest_y = equalIfIOperation(
          locale_lowest_y,
          controlPoint.y,
          "BIGGER_THAN"
        );
        locale_highest_x = equalIfIOperation(
          locale_highest_x,
          controlPoint.x,
          "SMALLER_THAN"
        );
        locale_highest_y = equalIfIOperation(
          locale_highest_y,
          controlPoint.y,
          "SMALLER_THAN"
        );
        if (controlPointIndex < entity.controlPoints.length - 1) {
          entity.distance += calculateDistanceBetweenPoints(
            entity.controlPoints[controlPointIndex + 1],
            entity.controlPoints[controlPointIndex]
          );
        }
      });
    } else if (entity.type === "LWPOLYLINE") {
      entity.vertices.forEach((vertice, verticeIndex) => {
        lowest_x = equalIfIOperation(lowest_x, vertice.x, "BIGGER_THAN");
        lowest_y = equalIfIOperation(lowest_y, vertice.y, "BIGGER_THAN");
        highest_x = equalIfIOperation(highest_x, vertice.x, "SMALLER_THAN");
        highest_y = equalIfIOperation(highest_y, vertice.y, "SMALLER_THAN");
        locale_lowest_x = equalIfIOperation(
          locale_lowest_x,
          vertice.x,
          "BIGGER_THAN"
        );
        locale_lowest_y = equalIfIOperation(
          locale_lowest_y,
          vertice.y,
          "BIGGER_THAN"
        );
        locale_highest_x = equalIfIOperation(
          locale_highest_x,
          vertice.x,
          "SMALLER_THAN"
        );
        locale_highest_y = equalIfIOperation(
          locale_highest_y,
          vertice.y,
          "SMALLER_THAN"
        );
        if (verticeIndex < entity.vertices.length - 1) {
          entity.distance += calculateDistanceBetweenPoints(
            entity.vertices[verticeIndex + 1],
            entity.vertices[verticeIndex]
          );
        }
      });
    } else {
      console.log(entity);
      console.log(entity.type);
    }
    entity.locale_highest_x = locale_highest_x;
    entity.locale_highest_y = locale_highest_y;
    entity.locale_lowest_x = locale_lowest_x;
    entity.locale_lowest_y = locale_lowest_y;
  });

  // console.log(
  //   `Highest X: ${highest_x}, Y: ${highest_y} | Lowest X: ${lowest_x}, Y: ${lowest_y}`
  // );
  let totalDistance = 0;
  let totalPerimeter = 0;
  let totalArea = 0;
  json.entities.forEach((entity) => {
    entity.locale_highest_x = entity.locale_highest_x - entity.locale_lowest_x;
    entity.locale_lowest_x = 0;
    entity.locale_highest_y = entity.locale_highest_y - entity.locale_lowest_y;
    entity.locale_lowest_y = 0;
    entity.perimeter = (entity.locale_highest_x + entity.locale_highest_y) * 2;
    entity.area = entity.locale_highest_x * entity.locale_highest_y;
    totalDistance += entity.distance;
    totalPerimeter += entity.perimeter;
    totalArea += entity.area;
  });
  const offset_x = lowest_x < 0 ? -lowest_x : lowest_x;
  const offset_y = -lowest_y;
  highest_x = highest_x + offset_x;
  lowest_x = 0;
  highest_y = highest_y + offset_y;
  lowest_y = 0;
  // console.log(
  //   `Highest X: ${highest_x}, Y: ${highest_y} | Lowest X: ${lowest_x}, Y: ${lowest_y}`
  // );
  const multiplier_x = width / highest_x;
  const ratio = highest_x / highest_y;
  const height = Math.ceil(width / ratio);
  const multiplier_y = height / highest_y;

  return {
    offset_x,
    offset_y,
    multiplier_x,
    multiplier_y,
    ratio,
    highest_x,
    highest_y,
    distance: totalDistance,
    perimeter: totalPerimeter,
    area: totalArea,
  };
};

export const drawCanvasBasedOnDXF = (div_id, width, dxf, color) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const { offset_x, offset_y, multiplier_x, multiplier_y, ratio } =
    analyzeDXFFile(dxf, width);
  const height = Math.ceil(width / ratio);
  canvas.width = width;
  canvas.height = height;
  context.strokeStyle = color;
  for (const entity of dxf.entities) {
    if (entity.type === "LINE") {
      const beginPosition = entity.vertices[0];
      const begin_x = (beginPosition.x + offset_x) * multiplier_x;
      const begin_y = (beginPosition.y + offset_y) * multiplier_y;
      const endPosition = entity.vertices[1];
      const end_x = (endPosition.x + offset_x) * multiplier_x;
      const end_y = (endPosition.y + offset_y) * multiplier_y;
      context.beginPath();
      context.moveTo(begin_x, height - begin_y);
      context.lineTo(end_x, height - end_y);
      context.stroke();
    } else if (entity.type === "SPLINE") {
      const controlPoints = entity.controlPoints;
      const beginPosition = controlPoints[0];
      const begin_x = (beginPosition.x + offset_x) * multiplier_x;
      const begin_y = (beginPosition.y + offset_y) * multiplier_y;
      context.beginPath();
      context.moveTo(begin_x, height - begin_y);
      for (let i = 1; i < controlPoints.length - 2; i++) {
        const other_begin_x = (controlPoints[i].x + offset_x) * multiplier_x;
        const other_begin_y = (controlPoints[i].y + offset_y) * multiplier_y;
        const other_next_begin_x =
          (controlPoints[i + 1].x + offset_x) * multiplier_x;
        const other_next_begin_y =
          (controlPoints[i + 1].y + offset_y) * multiplier_y;
        context.quadraticCurveTo(
          other_begin_x,
          height - other_begin_y,
          other_next_begin_x,
          height - other_next_begin_y
        );
      }
      const final_begin_x =
        (controlPoints[controlPoints.length - 2].x + offset_x) * multiplier_x;
      const final_begin_y =
        (controlPoints[controlPoints.length - 2].y + offset_y) * multiplier_y;
      const final_next_begin_x =
        (controlPoints[controlPoints.length - 1].x + offset_x) * multiplier_x;
      const final_next_begin_y =
        (controlPoints[controlPoints.length - 1].y + offset_y) * multiplier_y;
      context.quadraticCurveTo(
        final_begin_x,
        height - final_begin_y,
        final_next_begin_x,
        height - final_next_begin_y
      );
      context.stroke();
    } else if (entity.type === "LWPOLYLINE") {
      const vertices = entity.vertices;
      const beginPosition = vertices[0];
      const begin_x = (beginPosition.x + offset_x) * multiplier_x;
      const begin_y = (beginPosition.y + offset_y) * multiplier_y;
      context.beginPath();
      context.moveTo(begin_x, height - begin_y);
      for (let i = 1; i < vertices.length - 1; i++) {
        const currentPosition = entity.vertices[i];
        const current_x = (currentPosition.x + offset_x) * multiplier_x;
        const current_y = (currentPosition.y + offset_y) * multiplier_y;
        context.lineTo(current_x, height - current_y);
      }
      const lastPosition = vertices[vertices.length - 1];
      const last_x = (lastPosition.x + offset_x) * multiplier_x;
      const last_y = (lastPosition.y + offset_y) * multiplier_y;
      context.lineTo(last_x, height - last_y);
      context.stroke();
    }
  }

  document.getElementById(div_id).appendChild(canvas);
};
