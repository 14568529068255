// import { useEffect, useState } from "react";

const InitializeThreeDsModalBody = (props) => {
  // const [loadCompleted, setLoadCompleted] = useState(false);
  let count = 0;

  // useEffect(() => {
  //   const checkElementWhenCompleted = () => {
  //     const iframeElement = document.getElementById("initialize_3ds_iframe");
  //     console.log(iframeElement);
  //     if (iframeElement) {
  //       const iframeDocument = iframeElement.document;
  //       console.log(iframeDocument);
  //       if (iframeDocument) {
  //         const iframeBody = iframeDocument.body;
  //         console.log(iframeBody);
  //         if (iframeBody) {
  //           const iframeInnerHTML = iframeBody.innerHTML;
  //           console.log(iframeInnerHTML);
  //         }
  //       }
  //     }
  //   };
  //   if (loadCompleted) {
  //     checkElementWhenCompleted();
  //     setLoadCompleted(false);
  //   }
  // }, [loadCompleted]);

  return (
    <div>
      <iframe
        id="initialize_3ds_iframe"
        src={props.src}
        style={{ height: 400, width: "100%" }}
        onChange={(e) => {
          console.log(e);
        }}
        onLoad={(e) => {
          count += 1;
          console.log(count);
          // setLoadCompleted(true);
          // console.log("Loaded");
        }}
      />
    </div>
  );
};

export default InitializeThreeDsModalBody;
