import ReactCreditCard from "@repay/react-credit-card";
import InputGroup from "../InputGroup";

const PaymentDetail = (props) => {
  const {
    paymentInformation,
    setPaymentInformation,
    paymentFocused,
    setPaymentFocused,
  } = props;

  const retrieveYears = () => {
    const years = [];
    for (let i = 2022; i <= 2050; i++) {
      years.push({
        text: i,
        value: i.toString().substring(2),
      });
    }
    return years;
  };

  const retrieveMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push({
        text: i,
        value: i < 10 ? `0${i}` : i,
      });
    }
    return months;
  };
  return (
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <ReactCreditCard
            {...paymentInformation}
            focused={paymentFocused}
            hasShadow={true}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="col-md-9">
          <div>
            <InputGroup
              id="payment_card_number"
              label="Kart Numarası"
              type="number"
              placeholder="Kart numarası belirtin"
              onFocus={() => {
                setPaymentFocused("number");
              }}
              onChange={(e) => {
                const paymentCardNumber = e.target.value.trim();
                setPaymentInformation({
                  ...paymentInformation,
                  number: paymentCardNumber,
                });
              }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <InputGroup
              id="payment_card_name"
              label="İsmi Soyisim"
              type="text"
              placeholder="İsim soyisim belirtin"
              onFocus={() => {
                setPaymentFocused("name");
              }}
              onChange={(e) => {
                const paymentCardName = e.target.value.trim();
                setPaymentInformation({
                  ...paymentInformation,
                  name: paymentCardName,
                });
              }}
            />
          </div>
          <div
            className="d-flex flex-row justify-content-center align-items-start"
            style={{ marginTop: 10 }}
          >
            <div className="col-md-6">
              <div style={{ fontWeight: "bold" }}>Son Kullanım Tarihi</div>
              <div className="d-flex flex-row justify-content-start align-items-center">
                <div style={{ marginRight: 10 }}>
                  <InputGroup
                    id="payment_card_expiry_month"
                    type="select"
                    optionList={retrieveMonths()}
                    onFocus={() => {
                      setPaymentFocused("expiration");
                    }}
                    onChange={(e) => {
                      const paymentCardExpiryMonth = e.target.value.trim();
                      const paymentCardExpiryYear = document
                        .getElementById("payment_card_expiry_year")
                        .value.trim();
                      setPaymentInformation({
                        ...paymentInformation,
                        expiration: `${paymentCardExpiryMonth}/${paymentCardExpiryYear}`,
                      });
                    }}
                  />
                </div>
                <div style={{ fontSize: 24 }}>/ </div>
                <div style={{ marginLeft: 10 }}>
                  <InputGroup
                    id="payment_card_expiry_year"
                    optionList={retrieveYears()}
                    type="select"
                    onFocus={() => {
                      setPaymentFocused("expiration");
                    }}
                    onChange={(e) => {
                      const paymentCardExpiryMonth = document
                        .getElementById("payment_card_expiry_month")
                        .value.trim();
                      const paymentCardExpiryYear = e.target.value.trim();
                      setPaymentInformation({
                        ...paymentInformation,
                        expiration: `${paymentCardExpiryMonth}/${paymentCardExpiryYear}`,
                      });
                    }}
                  />
                </div>
                {/* <input
                type="number"
                id="payment_card_expiry_year"
                onFocus={() => {
                  setPaymentFocused("expiration");
                }}
                style={{ marginLeft: 10 }}
                onChange={(e) => {
                  const paymentCardExpiryMonth = document
                    .getElementById("payment_card_expiry_month")
                    .value.trim();
                  const paymentCardExpiryYear = e.target.value.trim();
                  setPaymentInformation({
                    ...paymentInformation,
                    expiration: `${paymentCardExpiryMonth}/${paymentCardExpiryYear}`,
                  });
                }}
              /> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column align-items-end">
                <div className="col-md-6">
                  <InputGroup
                    id="payment_card_cvc"
                    label="CVC"
                    type="number"
                    onFocus={() => {
                      setPaymentFocused("cvc");
                    }}
                    onChange={(e) => {
                      const paymentCardCvc = e.target.value.trim();
                      setPaymentInformation({
                        ...paymentInformation,
                        cvc: paymentCardCvc,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetail;
