const ContactUsScreen = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          İletişim
        </div>
        <div className="d-flex flex-row">
          <div className="col-md-3">
            <div
              className="d-flex flex-column justify-content-center"
              style={{ height: "100%" }}
            >
              <div>Tel: 0554 498 60 46</div>
              <div>Tel: 0539 510 87 66</div>
              <div style={{ marginTop: 20 }}>
                İvedik OSB. 1122 Cad. Prestij İş Merkezi No:40/16 Yenimahalle /
                ANKARA
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="d-flex flex-row">
              <div className="col-md-6">
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                  <div className="d-flex flex-column mt-2">
                    <div>Ad Soyad</div>
                    <input
                      id="fullname"
                      type="text"
                      style={{
                        padding: 10,
                        border: "1px solid lightgray",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column mt-2">
                    <div>Email</div>
                    <input
                      id="email"
                      type="text"
                      style={{
                        padding: 10,
                        border: "1px solid lightgray",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="d-flex flex-column mt-2">
                    <div>Konu</div>
                    <input
                      id="topic"
                      type="text"
                      style={{
                        padding: 10,
                        border: "1px solid lightgray",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex flex-column"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    height: "100%",
                  }}
                >
                  <div>İletmek istediğiniz mesaj</div>
                  <textarea
                    className="d-flex flex-fill mt-2"
                    style={{
                      padding: 20,
                      border: "1px solid lightgray",
                      borderRadius: 5,
                    }}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-end">
          <div style={{ marginRight: 20, marginLeft: 20, marginTop: 20 }}>
            <button className="primary-button">Gönder</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
