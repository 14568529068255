import React from "react";

const LoadingAnimation = (props) => {
  const { height } = props;
  return (
    <React.Fragment>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height }}
      >
        <img
          src="loading/loading_animation.gif"
          alt="loading_animation"
          style={{ width: 100, height: 100 }}
        />
      </div>
    </React.Fragment>
  );
};

export default LoadingAnimation;
