import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { context } from "../../context/ContextProvider";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";

const LandingScreen = () => {
  const { settings, userInformation } = useContext(context);
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const RenderCarouselItemList = () => {
    if (settings) {
      return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {settings.bannerImageList.map((bannerImage) => (
            <Carousel.Item>
              <img
                onClick={() => {
                  navigate("/offer");
                }}
                className="d-block w-100"
                src={bannerImage.url}
                alt="First slide"
                style={{
                  borderBottomLeftRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              />
              {/* <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
            </Carousel.Item>
          ))}
        </Carousel>
      );
    }
    return null;
  };

  const RenderOrderProcess = () => {
    return (
      <div>
        <img
          className="d-block w-100"
          src="https://static.wixstatic.com/media/d1a664_77ae5563c2714612a5659333b36a2fa8~mv2.jpg/v1/fill/w_2628,h_658,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/banner.jpg"
          alt="Second slide"
        />
      </div>
    );
  };

  const RenderDescriptionPanel = () => {
    return (
      <div className="d-flex flex-row flex-wrap" style={{ minHeight: 400 }}>
        <div className="col-md-6">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div style={{ padding: 30 }}>
              <div
                style={{ fontWeight: "bold", fontSize: 30, color: "#ff6700" }}
              >
                Maaliyet hesaplarken
              </div>
              <div style={{ fontWeight: "bold", fontSize: 30 }}>
                Zaman kazanın
              </div>
              <div style={{ marginTop: 30 }}>
                Tasarım dosyalarınızı dxf formatına dönüştürüp yükleyerek hemen
                fiyat alabilirsiniz. Kutu Harf İmalatı, Cnc ve Lazer kesim, 3d
                baskı hizmetleri veriyoruz.
              </div>
              <div style={{ marginTop: 30 }}>
                <button
                  className="primary-button d-flex align-items-center"
                  style={{ width: "auto" }}
                  onClick={() => {
                    navigate("/offer");
                  }}
                >
                  Dosya Yükle
                  <AiOutlineCloudUpload size={20} style={{ marginLeft: 10 }} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <video
            src="https://net-uretim-images.s3.eu-west-1.amazonaws.com/admin/neturetim.mp4"
            // controls
            autoPlay={true}
            muted={true}
            loop={true}
            style={{ width: "100%", height: 400 }}
          />
        </div>
      </div>
    );
  };

  const RenderFAQAccordionItemList = () => {
    return (
      <div className="">
        <div
          style={{
            fontWeight: "bold",
            // color: "#d75d34",
            color: "#ff6700",
            fontSize: 18,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          Sıkça Sorulan Sorular
        </div>

        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Hangi dosya formatında yükleme yapmalıyım?
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Otomatik fiyatlandırma için .dxf dosya formatı kullanmaktayız.
                Tasarımlarınızı .dxf dosya formatına çevirerek
                yükleyebilirsiniz. Yakında vektörel ve pdf dosya formatlarında
                da yükleme kabul edebileceğiz.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Ölçeklendirme nasıl olmalı?</Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Dosyanızı kaydederken mm (milimetre) cinsinden kaydetmeniz daha
                sağlıklı fiyatlandırma almanızı sağlar.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Ön izlemede oval köşelerde kırılmalar var, ürünüm bu şekilde mi
              üretilecek?
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Dosyanızı dxf formatına çevirmeden önce daha düzgün bir ön
                izleme için node (nokta) sayılarını artırınız. Ön izlemedeki
                bozukluklar üretimi etkilememektedir. Yüklediğiniz dosyadan
                üretim yapılmaktadır.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  const RenderFooterBanner = () => {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center text-center"
        style={{ minHeight: 400 }}
      >
        <div className="col-md-8">
          <div style={{ fontWeight: "bold", fontSize: 30 }}>
            Tüm hesaplama araçlarınız basit bir abonelikle tek bir yerde
          </div>

          <div
            className="col-md-8"
            style={{ marginTop: 30, marginLeft: "auto", marginRight: "auto" }}
          >
            Zamandan tasarruf etmek, makine, üretim ve personel maliyetlerini en
            aza indirmek için online üretim ve tedarik platformu hizmetinizde
          </div>
          {userInformation ? null : (
            <div style={{ marginTop: 30 }}>
              <button
                className="primary-button d-flex align-items-center"
                style={{ marginLeft: "auto", marginRight: "auto" }}
                onClick={() => {
                  navigate("/login");
                }}
              >
                Şimdi Üye Ol
                <FaUserCircle size={20} style={{ marginLeft: 10 }} />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const RenderCalculationTipsAccordionItemList = () => {
    return (
      <div className="">
        <div
          style={{
            fontWeight: "bold",
            // color: "#d75d34",
            color: "#ff6700",
            fontSize: 18,
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          Hesaplama İpuçları
        </div>
        <Accordion alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              Dosyada üst üste nesneler olmamalı
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Tasarımınızı kaydederken üst üste nesne olmamasına dikkat edin.
                Ürününüzün yüklediğiniz dosya kullanılarak üretileceğini göz
                önünde bulundurun.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Fileli kutu harf hesaplama ayrıntıları
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Fileli kutu harf imalatı yapılacaksa içe veya dışa kontör
                vermeyiniz. Böylece hesaplamada fiyat daha ekonomik çıkacaktır.
                Kontör kalınlığını sipariş notunuza ekleyebilirsiniz.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Maliyet hesabının minimum olması için neler yapılmalı
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Dosyanızı kaydetmeden önce fire vermeyecek şekilde iç içe
                yerleştirmeniz fiyatlandırma yapılırken daha ekonomik olmasını
                sağlar.
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Dosya hazırlarken sayfaya dikkat edin
            </Accordion.Header>
            <Accordion.Body>
              <div style={{ paddingLeft: 20 }}>
                Sayfa boyutunu seçili nesnelerin tamamını kapsayacak büyüklükte
                ayarlayıp seçili nesneleri sayfanın ortasına yerleştirin.
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  };

  return (
    <div className="landing-screen-template">
      <div className="container">
        {RenderCarouselItemList()}
        <div style={{ marginTop: 50 }}>{RenderOrderProcess()}</div>
        <div style={{ marginTop: 50 }}>{RenderDescriptionPanel()}</div>
        <div style={{ marginTop: 50 }}>{RenderFooterBanner()}</div>
        <div style={{ marginTop: 50 }}>{RenderFAQAccordionItemList()}</div>
        <div style={{ marginTop: 50 }}>
          {RenderCalculationTipsAccordionItemList()}
        </div>
      </div>
    </div>
  );
};

export default LandingScreen;
