import React, { useContext, useEffect, useRef, useState } from "react";
import { context } from "../../context/ContextProvider";
import { queryPrintingTypeDetailList } from "../../requests/printingTypeDetailRequests";
import InputGroup from "../InputGroup";

const BoxLetterFileDetail = (props) => {
  const appContext = useContext(context);
  const { colorList, dekotaList, ledList, patternList, transformerList } =
    appContext;
  const {
    parsedFileKey,
    printingTypeList,
    metricUpdated,
    setConstructedFileMap,
  } = props;
  const [printingTypeIndex, setPrintingTypeIndex] = useState(0);
  const [ligthingTypeIndex, setLigthingTypeIndex] = useState(0);
  const lightingTypeList = [
    { id: 0, name: "Önden Aydınlatma" },
    {
      id: 1,
      name: "Arkadan Aydınlatma",
    },
  ];
  const [includeLED, setIncludeLED] = useState(true);
  const [includeFoot, setIncludeFoot] = useState(true);
  const [includeDekota, setIncludeDekota] = useState(true);
  const [printingTypeDetail, setPrintingTypeDetail] = useState(null);
  const pleksiColorRef = useRef();
  const sideBandColorRef = useRef();
  const ledColorRef = useRef();
  const kromColorRef = useRef();
  const letterColorRef = useRef();

  useEffect(() => {
    const retrieveAndSetPrintingTypeDetail = async (printingTypeIndex) => {
      const foundPrintingTypeDetailList = await queryPrintingTypeDetailList(
        printingTypeIndex
      );
      if (foundPrintingTypeDetailList.length > 0) {
        setPrintingTypeDetail(foundPrintingTypeDetailList[0]);
        props.setMetricUpdated(true);
      }
    };
    retrieveAndSetPrintingTypeDetail(printingTypeIndex);
    setLigthingTypeIndex(0);
    setIncludeLED(true);
    setIncludeFoot(true);
    setIncludeDekota(true);
  }, [printingTypeIndex]);

  // useEffect(() => {
  //   if (includeLED) {
  //     setIncludeDekota(true);
  //   }
  // }, [includeLED]);

  useEffect(() => {
    props.setMetricUpdated(true);
  }, [includeDekota]);

  const retrieveSingleElementValue = (id) => {
    const element = document.getElementById(`${id}${parsedFileKey}`);
    if (element && element.value) {
      return element.value;
    }
    return null;
  };

  const retrieveLetterColorPrice = () => {
    const letterColorElement = document.getElementById(
      `letter_color_${parsedFileKey}`
    );
    if (letterColorElement && letterColorElement.value) {
      const letterColor = JSON.parse(letterColorElement.value);
      const foundLetterPrice =
        printingTypeDetail.associatedLetterPriceList.find(
          (letterColorPrice) => letterColorPrice.colorId === letterColor.id
        );
      return JSON.stringify(foundLetterPrice);
    }
    return null;
  };

  useEffect(() => {
    setIncludeLED(retrieveSingleElementValue("include_led_") === "true");
    setIncludeDekota(retrieveSingleElementValue("include_dekota_") === "true");
    setIncludeFoot(retrieveSingleElementValue("include_foot_") === "true");
    props.setMetricUpdated(true);
  }, [ligthingTypeIndex, printingTypeIndex]);

  useEffect(() => {
    setIncludeDekota(retrieveSingleElementValue("include_dekota_") === "true");
    setIncludeFoot(retrieveSingleElementValue("include_foot_") === "true");
  }, [includeLED]);

  useEffect(() => {
    setIncludeDekota(retrieveSingleElementValue("include_dekota_") === "true");
  }, [includeFoot]);

  useEffect(() => {
    const retrieveAndSetContructedFileMap = () => {
      setConstructedFileMap((prevConstructedFileMap) => ({
        ...prevConstructedFileMap,
        [parsedFileKey]: {
          baseMaterial: retrieveSingleElementValue("base_material_"),
          pleksiColor: retrieveSingleElementValue("pleksi_color_"),
          pleksiThickness: retrieveSingleElementValue("pleksi_thickness_"),
          sideBandDepth: retrieveSingleElementValue("sideband_depth_"),
          sideBandThickness: retrieveSingleElementValue("sideband_thickness_"),
          sideBandColor: retrieveSingleElementValue("sideband_color_"),
          includeLed: retrieveSingleElementValue("include_led_"),
          includeFoot: retrieveSingleElementValue("include_foot_"),
          foot: retrieveSingleFootValue(),
          ledColor: retrieveSingleElementValue("led_color_"),
          includeDekota: retrieveSingleElementValue("include_dekota_"),
          dekotaThickness: retrieveSingleElementValue("dekota_thickness_"),
          lightingType: retrieveSingleElementValue("lighting_type_"),
          materialThickness: retrieveSingleElementValue("material_thickness_"),
          letterColor: retrieveSingleElementValue("letter_color_"),
          letterColorPrice: retrieveLetterColorPrice(),
          kromColor: retrieveSingleElementValue("krom_color_"),
          kromPattern: retrieveSingleElementValue("krom_pattern_"),
          profitPercentage: printingTypeDetail.profitPercentage,
          transformerType: retrieveSingleElementValue("transformer_type_"),
          transformerCount: parseInt(
            retrieveSingleElementValue("transformer_count_")
          ),
        },
      }));
    };
    const setColorToDiv = (ref, id, key) => {
      const colorElement = document.getElementById(id);
      if (colorElement && colorElement.value) {
        const colorInformation = JSON.parse(colorElement.value);
        const foundColor = colorList.find(
          (color) => color.id === colorInformation[key]
        );
        ref.current.style.backgroundColor = foundColor.code;
      }
    };
    if (metricUpdated && printingTypeDetail) {
      retrieveAndSetContructedFileMap();
      if (pleksiColorRef && pleksiColorRef.current) {
        setColorToDiv(pleksiColorRef, `pleksi_color_${parsedFileKey}`, "id");
      }
      if (sideBandColorRef && sideBandColorRef.current) {
        setColorToDiv(
          sideBandColorRef,
          `sideband_color_${parsedFileKey}`,
          "id"
        );
      }
      if (ledColorRef && ledColorRef.current) {
        setColorToDiv(
          ledColorRef,
          `led_color_${parsedFileKey}`,
          "associatedColorId"
        );
      }
      if (kromColorRef && kromColorRef.current) {
        setColorToDiv(kromColorRef, `krom_color_${parsedFileKey}`, "id");
      }
      if (letterColorRef && letterColorRef.current) {
        setColorToDiv(letterColorRef, `letter_color_${parsedFileKey}`, "id");
      }
    }
  }, [metricUpdated]);

  // const retrievePrintingTypeKromColors = () => {
  //   if (printingTypeDetail && printingTypeDetail.associatedKromColorIdList) {
  //     const associatedColorList = [];
  //     printingTypeDetail.associatedKromColorIdList.forEach(
  //       (associatedColorId) => {
  //         const foundColor = colorList.find(
  //           (color) => color.id === associatedColorId
  //         );
  //         associatedColorList.push(foundColor);
  //       }
  //     );
  //     return associatedColorList.map((associatedColor) => ({
  //       value: JSON.stringify(associatedColor),
  //       text: associatedColor.name,
  //     }));
  //   }
  //   return [];
  // };

  const retrievePrintingTypeKromColors = () => {
    if (printingTypeDetail) {
      const sideBandDepthElement = document.getElementById(
        `sideband_depth_${parsedFileKey}`
      );
      if (sideBandDepthElement && sideBandDepthElement.value) {
        const sideBandDepth = JSON.parse(sideBandDepthElement.value);
        const sideBandThicknessColorList = [];
        sideBandDepth.thicknessList.forEach((sideBandThickness) => {
          const foundIndex = sideBandThicknessColorList.findIndex(
            (sideBandThicknessColor) =>
              sideBandThicknessColor.id === sideBandThickness.colorId
          );
          if (foundIndex === -1) {
            const foundColor = colorList.find(
              (color) => color.id === sideBandThickness.colorId
            );
            if (foundColor) {
              sideBandThicknessColorList.push(foundColor);
            }
          }
        });
        return sideBandThicknessColorList.map((sideBandThicknessColor) => ({
          value: JSON.stringify(sideBandThicknessColor),
          text: sideBandThicknessColor.name,
        }));
      }
    }
    return [];
  };

  const retrievePrintingTypeKromPatterns = () => {
    if (printingTypeDetail) {
      const sideBandDepthElement = document.getElementById(
        `sideband_depth_${parsedFileKey}`
      );
      if (sideBandDepthElement && sideBandDepthElement.value) {
        const sideBandDepth = JSON.parse(sideBandDepthElement.value);
        const sideBandThicknessPatternList = [];
        sideBandDepth.thicknessList.forEach((sideBandThickness) => {
          const foundIndex = sideBandThicknessPatternList.findIndex(
            (sideBandThicknessPattern) =>
              sideBandThicknessPattern.id === sideBandThickness.patternId
          );
          if (foundIndex === -1) {
            const foundPattern = patternList.find(
              (pattern) => pattern.id === sideBandThickness.patternId
            );
            if (foundPattern) {
              sideBandThicknessPatternList.push(foundPattern);
            }
          }
        });
        return sideBandThicknessPatternList.map((sideBandThicknessPattern) => ({
          value: JSON.stringify(sideBandThicknessPattern),
          text: sideBandThicknessPattern.name,
        }));
      }
    }
    return [];
  };

  const retrievePrintingTypePleksiColors = () => {
    if (printingTypeDetail && printingTypeDetail.associatedPleksiColorIdList) {
      const associatedColorList = [];
      printingTypeDetail.associatedPleksiColorIdList.forEach(
        (associatedColorId) => {
          const foundColor = colorList.find(
            (color) => color.id === associatedColorId
          );
          if (foundColor) {
            associatedColorList.push(foundColor);
          }
        }
      );
      return associatedColorList.map((associatedColor) => ({
        value: JSON.stringify(associatedColor),
        text: associatedColor.name,
      }));
    }
    return [];
  };

  const retrievePrintingTypeLetterColors = () => {
    if (printingTypeDetail && printingTypeDetail.associatedLetterColorIdList) {
      const associatedColorList = [];
      printingTypeDetail.associatedLetterColorIdList.forEach(
        (associatedColorId) => {
          const foundColor = colorList.find(
            (color) => color.id === associatedColorId
          );
          associatedColorList.push(foundColor);
        }
      );
      return associatedColorList.map((associatedColor) => ({
        value: JSON.stringify(associatedColor),
        text: associatedColor.name,
      }));
    }
    return [];
  };

  const retrievePrintingTypeSideBandColors = () => {
    if (
      printingTypeDetail &&
      printingTypeDetail.associatedSideBandColorIdList
    ) {
      const associatedColorList = [];
      printingTypeDetail.associatedSideBandColorIdList.forEach(
        (associatedSideBandColorId) => {
          const foundColor = colorList.find(
            (color) => color.id === associatedSideBandColorId
          );
          associatedColorList.push(foundColor);
        }
      );
      return associatedColorList.map((associatedColor) => ({
        value: JSON.stringify(associatedColor),
        text: associatedColor.name,
      }));
    }
    return [];
  };

  const retrievePrintingTypeLEDColors = () => {
    if (printingTypeDetail && printingTypeDetail.associatedLedIdList) {
      const associatedLedList = [];
      printingTypeDetail.associatedLedIdList.forEach((associatedLedId) => {
        const foundLed = ledList.find((led) => led.id === associatedLedId);
        if (foundLed && foundLed.associatedColorIdList) {
          foundLed.associatedColorIdList.forEach((associatedColorId) => {
            const foundColor = colorList.find(
              (color) => color.id === associatedColorId
            );
            if (foundColor) {
              const name = `${foundLed.name} - ${foundColor.name}`;
              associatedLedList.push({ ...foundLed, name, associatedColorId });
            }
          });
        }
      });
      return associatedLedList.map((associatedLed) => ({
        value: JSON.stringify(associatedLed),
        text: associatedLed.name,
      }));
    }
    return [];
  };

  const retrieveTransformerType = () => {
    return transformerList.map((transformer) => ({
      value: JSON.stringify(transformer),
      text: `${transformer.name} (${transformer.brand}) - ${transformer.volt}${transformer.voltUnit}`,
    }));
  };

  const retrieveTransformerCount = () => {
    return [
      {
        value: 0,
        text: 0,
      },
      {
        value: 1,
        text: 1,
      },
      {
        value: 2,
        text: 2,
      },
      {
        value: 3,
        text: 3,
      },
      {
        value: 4,
        text: 4,
      },
      {
        value: 5,
        text: 5,
      },
    ];
  };

  const retrievePrintingTypePleksiThicknessValues = () => {
    if (printingTypeDetail && printingTypeDetail.pleksiThicknessList) {
      return printingTypeDetail.pleksiThicknessList.map((pleksiThickness) => ({
        value: JSON.stringify(pleksiThickness),
        text: `${pleksiThickness.thickness} ${pleksiThickness.thicknessUnit}`,
      }));
    }
    return [];
  };

  const retrievePrintingTypeMaterialThicknessValues = () => {
    if (printingTypeDetail && printingTypeDetail.materialThicknessList) {
      if (printingTypeIndex === 2) {
        let kromColor;
        const kromColorValue = retrieveSingleElementValue("krom_color_");
        if (kromColorValue) {
          kromColor = JSON.parse(kromColorValue);
        }
        let kromPattern;
        const kromPatternValue = retrieveSingleElementValue("krom_pattern_");
        if (kromPatternValue) {
          kromPattern = JSON.parse(kromPatternValue);
        }
        if (kromColor && kromPattern) {
          const filteredMaterialThicknessList =
            printingTypeDetail.materialThicknessList.filter(
              (thickness) =>
                thickness.patternId === kromPattern.id &&
                thickness.colorId === kromColor.id
            );
          return filteredMaterialThicknessList.map((materialThickness) => ({
            value: JSON.stringify(materialThickness),
            text: `${materialThickness.thickness} ${materialThickness.thicknessUnit}`,
          }));
        } else {
          return [];
        }
      } else {
        return printingTypeDetail.materialThicknessList.map(
          (materialThickness) => ({
            value: JSON.stringify(materialThickness),
            text: `${materialThickness.thickness} ${materialThickness.thicknessUnit}`,
          })
        );
      }
    }
    return [];
  };

  const retrievePrintingTypeSideBandDepthValues = () => {
    if (printingTypeDetail && printingTypeDetail.sideBandDepthList) {
      return printingTypeDetail.sideBandDepthList.map((sideBandDepth) => ({
        value: JSON.stringify(sideBandDepth),
        text: `${sideBandDepth.depth} ${sideBandDepth.depthUnit}`,
      }));
    }
    return [];
  };

  const retrievePrintingTypeSideBandThicknessValues = () => {
    if (printingTypeDetail) {
      const sideBandDepthElement = document.getElementById(
        `sideband_depth_${parsedFileKey}`
      );
      if (sideBandDepthElement && sideBandDepthElement.value) {
        const sideBandDepth = JSON.parse(sideBandDepthElement.value);
        if (printingTypeIndex === 2) {
          const thicknessSet = [];
          sideBandDepth.thicknessList.forEach((thickness) => {
            const foundThicknessIndex = thicknessSet.findIndex(
              (foundThickness) => foundThickness.value === thickness.value
            );
            if (foundThicknessIndex === -1) {
              thicknessSet.push(thickness);
            }
          });
          return thicknessSet.map((sideBandThickness) => ({
            value: JSON.stringify(sideBandThickness),
            text: `${sideBandThickness.thickness} ${sideBandThickness.thicknessUnit}`,
          }));
        } else {
          return sideBandDepth.thicknessList.map((sideBandThickness) => ({
            value: JSON.stringify(sideBandThickness),
            text: `${sideBandThickness.thickness} ${sideBandThickness.thicknessUnit}`,
          }));
        }
      }
    }
    return [];
  };

  const retrieveLedOptionList = () => {
    return [
      {
        value: true,
        text: "Evet",
      },
      {
        value: false,
        text: "Hayır",
      },
    ];
  };

  const retrieveFootOptionList = () => {
    return [
      {
        value: true,
        text: "Evet",
      },
      {
        value: false,
        text: "Hayır",
      },
    ];
  };

  const retrieveSingleFootValue = () => {
    const footList = printingTypeDetail.footList;
    if (footList && footList.length > 0) {
      return JSON.stringify(footList[0]);
    }
    return null;
  };

  const retrieveDekotaOptionList = () => {
    if (includeLED) {
      return [
        {
          value: true,
          text: "Evet",
        },
      ];
    } else {
      return [
        {
          value: true,
          text: "Evet",
        },
        {
          value: false,
          text: "Hayır",
        },
      ];
    }
  };

  const retrieveDekotaOptionList_RearLighting = () => {
    if (includeLED && includeFoot) {
      return [
        {
          value: false,
          text: "Hayır",
        },
      ];
    } else {
      return [
        {
          value: true,
          text: "Evet",
        },
        {
          value: false,
          text: "Hayır",
        },
      ];
    }
  };

  const retrieveDekotaColors = () => {
    if (printingTypeDetail && printingTypeDetail.associatedDekotaIdList) {
      const associatedDekotaColorList = [];
      printingTypeDetail.associatedDekotaIdList.forEach(
        (associatedDekotaId) => {
          const foundDekota = dekotaList.find(
            (dekota) => dekota.id === associatedDekotaId
          );
          if (foundDekota) {
            foundDekota.associatedColorIdList.forEach((associatedColorId) => {
              const foundColor = colorList.find(
                (color) => color.id === associatedColorId
              );
              if (foundColor) {
                associatedDekotaColorList.push(foundColor);
              }
            });
          }
        }
      );

      return associatedDekotaColorList.map((associatedDekotaColor) => ({
        value: JSON.stringify(associatedDekotaColor),
        text: associatedDekotaColor.name,
      }));
    }
    return [];
  };

  const retrieveDekotaValues = () => {
    if (printingTypeDetail && printingTypeDetail.associatedDekotaIdList) {
      const associatedDekotaIdList = [];
      printingTypeDetail.associatedDekotaIdList.forEach(
        (associatedDekotaId) => {
          const foundDekota = dekotaList.find(
            (dekota) => dekota.id === associatedDekotaId
          );
          if (foundDekota) {
            associatedDekotaIdList.push(foundDekota);
          }
        }
      );
      return associatedDekotaIdList.map((associatedDekota) => ({
        value: JSON.stringify(associatedDekota),
        text: `${associatedDekota.thickness} (${associatedDekota.thicknessUnit})`,
      }));
    }
    return [];
    // };
    // if (dekotaList.length > 0) {
    //   const dekota = dekotaList[0];
    //   return dekota.materialThicknessList.map((materialThickness) => ({
    //     value: JSON.stringify(materialThickness),
    //     text: `${materialThickness.thickness} ${materialThickness.thicknessUnit}`,
    //   }));
    // }
    // return [];
  };

  const RenderPleksiglasPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`pleksi_color_${parsedFileKey}`}
                label="Pleksiglass Rengi"
                type="select"
                optionList={retrievePrintingTypePleksiColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={pleksiColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`pleksi_thickness_${parsedFileKey}`}
            label="Pleksiglass Kalınlığı"
            type="select"
            optionList={retrievePrintingTypePleksiThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_depth_${parsedFileKey}`}
            label="Harf Derinliği"
            type="select"
            optionList={retrievePrintingTypeSideBandDepthValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_thickness_${parsedFileKey}`}
            label="Yanak Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeSideBandThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`sideband_color_${parsedFileKey}`}
                label="Yanak Rengi"
                type="select"
                optionList={retrievePrintingTypeSideBandColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={sideBandColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_led_${parsedFileKey}`}
            label="LED"
            type="select"
            optionList={retrieveLedOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeLED(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeLED ? (
          <React.Fragment>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div style={{ position: "relative" }}>
                  <InputGroup
                    id={`led_color_${parsedFileKey}`}
                    label="LED Rengi"
                    type="select"
                    optionList={retrievePrintingTypeLEDColors()}
                    onChange={() => {
                      props.setMetricUpdated(true);
                    }}
                  />
                  <div
                    ref={ledColorRef}
                    className="single-file-detail-color-ball-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_type_${parsedFileKey}`}
                label="Trafo"
                type="select"
                optionList={retrieveTransformerType()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_count_${parsedFileKey}`}
                label="Trafo Adedi"
                type="select"
                optionList={retrieveTransformerCount()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_dekota_${parsedFileKey}`}
            label="Dekota"
            type="select"
            optionList={retrieveDekotaOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeDekota(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeDekota ? (
          <React.Fragment>
            {/* <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_colors_${parsedFileKey}`}
                label="Dekota Rengi"
                type="select"
                optionList={retrieveDekotaColors()}
              />
            </div> */}
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_thickness_${parsedFileKey}`}
                label="Dekota Kalınlığı"
                type="select"
                optionList={retrieveDekotaValues()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderAliminiumPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`lighting_type_${parsedFileKey}`}
            key={`aliminium_lighting_type_${parsedFileKey}`}
            value={ligthingTypeIndex}
            label="Aydınlatma Tipi"
            type="select"
            optionList={lightingTypeList.map((ligthingType) => ({
              value: ligthingType.id,
              text: ligthingType.name,
            }))}
            onChange={(e) => {
              setLigthingTypeIndex(parseInt(e.target.value));
            }}
          />
        </div>

        {RenderAliminiumPrintingTypeDetails_LightingType()}
      </React.Fragment>
    );
  };

  const RenderAliminiumPrintingTypeDetails_LightingType = () => {
    if (ligthingTypeIndex === 0) {
      return RenderAliminiumPrintingTypeDetails_FrontalLightining();
    } else if (ligthingTypeIndex === 1) {
      return RenderAliminiumPrintingTypeDetails_RearLightining();
    } else {
      return null;
    }
  };

  const RenderAliminiumPrintingTypeDetails_FrontalLightining = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`pleksi_color_${parsedFileKey}`}
                label="Pleksiglass Rengi"
                type="select"
                optionList={retrievePrintingTypePleksiColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={pleksiColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`pleksi_thickness_${parsedFileKey}`}
            label="Pleksiglass Kalınlığı"
            type="select"
            optionList={retrievePrintingTypePleksiThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_depth_${parsedFileKey}`}
            label="Harf Derinliği"
            type="select"
            optionList={retrievePrintingTypeSideBandDepthValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_thickness_${parsedFileKey}`}
            label="Yanak Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeSideBandThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`letter_color_${parsedFileKey}`}
                label="Harf Rengi"
                type="select"
                optionList={retrievePrintingTypeLetterColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={letterColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_led_${parsedFileKey}`}
            label="LED"
            type="select"
            optionList={retrieveLedOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeLED(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {/* <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_foot_${parsedFileKey}`}
            label="Ayak"
            type="select"
            optionList={retrieveFootOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeFoot(value);
              props.setMetricUpdated(true);
            }}
          />
        </div> */}
        {includeLED ? (
          <React.Fragment>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div style={{ position: "relative" }}>
                  <InputGroup
                    id={`led_color_${parsedFileKey}`}
                    label="LED Rengi"
                    type="select"
                    optionList={retrievePrintingTypeLEDColors()}
                    onChange={() => {
                      props.setMetricUpdated(true);
                    }}
                  />
                  <div
                    ref={ledColorRef}
                    className="single-file-detail-color-ball-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_type_${parsedFileKey}`}
                label="Trafo"
                type="select"
                optionList={retrieveTransformerType()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_count_${parsedFileKey}`}
                label="Trafo Adedi"
                type="select"
                optionList={retrieveTransformerCount()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_dekota_${parsedFileKey}`}
            label="Dekota"
            type="select"
            optionList={retrieveDekotaOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeDekota(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeDekota ? (
          <React.Fragment>
            {/* <div className="mt-3">
                  <InputGroup
                    id={`dekota_colors_${parsedFileKey}`}
                    label="Dekota Rengi"
                    type="select"
                    optionList={retrieveDekotaColors()}
                  />
                </div> */}
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_thickness_${parsedFileKey}`}
                label="Dekota Kalınlığı"
                type="select"
                optionList={retrieveDekotaValues()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderAliminiumPrintingTypeDetails_RearLightining = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_depth_${parsedFileKey}`}
            label="Harf Derinliği"
            type="select"
            optionList={retrievePrintingTypeSideBandDepthValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_thickness_${parsedFileKey}`}
            label="Yanak Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeSideBandThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div style={{ position: "relative" }}>
            <InputGroup
              id={`letter_color_${parsedFileKey}`}
              label="Harf Rengi"
              type="select"
              optionList={retrievePrintingTypeLetterColors()}
              onChange={() => {
                props.setMetricUpdated(true);
              }}
            />
            <div
              ref={letterColorRef}
              className="single-file-detail-color-ball-div"
            />
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_led_${parsedFileKey}`}
            label="LED"
            type="select"
            optionList={retrieveLedOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeLED(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_foot_${parsedFileKey}`}
            label="Ayak"
            type="select"
            optionList={retrieveFootOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeFoot(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeLED ? (
          <React.Fragment>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div style={{ position: "relative" }}>
                  <InputGroup
                    id={`led_color_${parsedFileKey}`}
                    label="LED Rengi"
                    type="select"
                    optionList={retrievePrintingTypeLEDColors()}
                    onChange={() => {
                      props.setMetricUpdated(true);
                    }}
                  />
                  <div
                    ref={ledColorRef}
                    className="single-file-detail-color-ball-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_type_${parsedFileKey}`}
                label="Trafo"
                type="select"
                optionList={retrieveTransformerType()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_count_${parsedFileKey}`}
                label="Trafo Adedi"
                type="select"
                optionList={retrieveTransformerCount()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_dekota_${parsedFileKey}`}
            label="Dekota"
            type="select"
            optionList={retrieveDekotaOptionList_RearLighting()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeDekota(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeDekota ? (
          <React.Fragment>
            {/* <div className="mt-3">
                  <InputGroup
                    id={`dekota_colors_${parsedFileKey}`}
                    label="Dekota Rengi"
                    type="select"
                    optionList={retrieveDekotaColors()}
                  />
                </div> */}
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_thickness_${parsedFileKey}`}
                label="Dekota Kalınlığı"
                type="select"
                optionList={retrieveDekotaValues()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderKromPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`lighting_type_${parsedFileKey}`}
            key={`krom_lighting_type_${parsedFileKey}`}
            label="Aydınlatma Tipi"
            type="select"
            optionList={lightingTypeList.map((ligthingType) => ({
              value: ligthingType.id,
              text: ligthingType.name,
            }))}
            onChange={(e) => {
              setLigthingTypeIndex(parseInt(e.target.value));
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`krom_color_${parsedFileKey}`}
                label="Krom Rengi"
                type="select"
                optionList={retrievePrintingTypeKromColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={kromColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`krom_pattern_${parsedFileKey}`}
            label="Yanak Deseni"
            type="select"
            optionList={retrievePrintingTypeKromPatterns()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>

        {RenderKromPrintingTypeDetails_LightingType()}
      </React.Fragment>
    );
  };

  const RenderKromPrintingTypeDetails_LightingType = () => {
    if (ligthingTypeIndex === 0) {
      return RenderKromPrintingTypeDetails_FrontalLightining();
    } else if (ligthingTypeIndex === 1) {
      return RenderKromPrintingTypeDetails_RearLightining();
    } else {
      return null;
    }
  };

  const RenderKromPrintingTypeDetails_FrontalLightining = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`pleksi_color_${parsedFileKey}`}
                label="Pleksiglass Rengi"
                type="select"
                optionList={retrievePrintingTypePleksiColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={pleksiColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`pleksi_thickness_${parsedFileKey}`}
            label="Pleksiglass Kalınlığı"
            type="select"
            optionList={retrievePrintingTypePleksiThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_depth_${parsedFileKey}`}
            label="Harf Derinliği"
            type="select"
            optionList={retrievePrintingTypeSideBandDepthValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_thickness_${parsedFileKey}`}
            label="Yanak Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeSideBandThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`sideband_color_${parsedFileKey}`}
                label="Yanak Rengi"
                type="select"
                optionList={retrievePrintingTypeSideBandColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={sideBandColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_led_${parsedFileKey}`}
            label="LED"
            type="select"
            optionList={retrieveLedOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeLED(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {/* <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_foot_${parsedFileKey}`}
            label="Ayak"
            type="select"
            optionList={retrieveFootOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeFoot(value);
              props.setMetricUpdated(true);
            }}
          />
        </div> */}
        {includeLED ? (
          <React.Fragment>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div style={{ position: "relative" }}>
                  <InputGroup
                    id={`led_color_${parsedFileKey}`}
                    label="LED Rengi"
                    type="select"
                    optionList={retrievePrintingTypeLEDColors()}
                    onChange={() => {
                      props.setMetricUpdated(true);
                    }}
                  />
                  <div
                    ref={ledColorRef}
                    className="single-file-detail-color-ball-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_type_${parsedFileKey}`}
                label="Trafo"
                type="select"
                optionList={retrieveTransformerType()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_count_${parsedFileKey}`}
                label="Trafo Adedi"
                type="select"
                optionList={retrieveTransformerCount()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_dekota_${parsedFileKey}`}
            label="Dekota"
            type="select"
            optionList={retrieveDekotaOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeDekota(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeDekota ? (
          <React.Fragment>
            {/* <div className="mt-3">
                  <InputGroup
                    id={`dekota_colors_${parsedFileKey}`}
                    label="Dekota Rengi"
                    type="select"
                    optionList={retrieveDekotaColors()}
                  />
                </div> */}
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_thickness_${parsedFileKey}`}
                label="Dekota Kalınlığı"
                type="select"
                optionList={retrieveDekotaValues()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  const RenderKromPrintingTypeDetails_RearLightining = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_depth_${parsedFileKey}`}
            label="Harf Derinliği"
            type="select"
            optionList={retrievePrintingTypeSideBandDepthValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`sideband_thickness_${parsedFileKey}`}
            label="Yanak Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeSideBandThicknessValues()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`sideband_color_${parsedFileKey}`}
                label="Yanak Rengi"
                type="select"
                optionList={retrievePrintingTypeSideBandColors()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={sideBandColorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_led_${parsedFileKey}`}
            label="LED"
            type="select"
            optionList={retrieveLedOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeLED(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_foot_${parsedFileKey}`}
            label="Ayak"
            type="select"
            optionList={retrieveFootOptionList()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeFoot(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeLED ? (
          <React.Fragment>
            <div className="col-md-6 mt-3">
              <div className="row">
                <div style={{ position: "relative" }}>
                  <InputGroup
                    id={`led_color_${parsedFileKey}`}
                    label="LED Rengi"
                    type="select"
                    optionList={retrievePrintingTypeLEDColors()}
                    onChange={() => {
                      props.setMetricUpdated(true);
                    }}
                  />
                  <div
                    ref={ledColorRef}
                    className="single-file-detail-color-ball-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_type_${parsedFileKey}`}
                label="Trafo"
                type="select"
                optionList={retrieveTransformerType()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`transformer_count_${parsedFileKey}`}
                label="Trafo Adedi"
                type="select"
                optionList={retrieveTransformerCount()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`include_dekota_${parsedFileKey}`}
            label="Dekota"
            type="select"
            optionList={retrieveDekotaOptionList_RearLighting()}
            onChange={(e) => {
              const value = e.target.value === "true";
              setIncludeDekota(value);
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {includeDekota ? (
          <React.Fragment>
            {/* <div className="mt-3">
                  <InputGroup
                    id={`dekota_colors_${parsedFileKey}`}
                    label="Dekota Rengi"
                    type="select"
                    optionList={retrieveDekotaColors()}
                  />
                </div> */}
            <div className="col-md-6 mt-3">
              <InputGroup
                id={`dekota_thickness_${parsedFileKey}`}
                label="Dekota Kalınlığı"
                type="select"
                optionList={retrieveDekotaValues()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-start align-items-center flex-wrap">
        <div className="col-md-12">
          <div>
            <span style={{ fontWeight: "bold" }}>Dosya İsmi: </span>
            {parsedFileKey}
          </div>
        </div>
        <div className="col-md-9 mt-2">
          <InputGroup
            id={`base_material_${parsedFileKey}`}
            type="select"
            optionList={printingTypeList.map((printingType) => ({
              text: printingType.name,
              value: printingType.id,
            }))}
            onChange={(e) => {
              const value = e.target.value;
              const selectedPrintingType = printingTypeList.find(
                (printingType) => printingType.id === parseInt(value)
              );
              setPrintingTypeIndex(selectedPrintingType.id);
            }}
            label="Harf Tipi"
          />
        </div>
      </div>

      <div className="row">
        {printingTypeIndex === 0
          ? RenderPleksiglasPrintingTypeDetails()
          : printingTypeIndex === 1
          ? RenderAliminiumPrintingTypeDetails()
          : printingTypeIndex === 2
          ? RenderKromPrintingTypeDetails()
          : null}
      </div>
    </React.Fragment>
  );
};

export default BoxLetterFileDetail;
