import React from "react";
import { Modal } from "react-bootstrap";

const ModalView = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="xl"
      fullscreen={props.fullscreen}
    >
      <Modal.Header closeButton>{props.header}</Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>{props.footer}</Modal.Footer>
    </Modal>
  );
};

export default ModalView;
