import axios from "axios";
import config from "../config/config.json";

export const retrieveLedList = async () => {
  try {
    const response = await axios.get(`${config.baseUrl}${config.leds.context}`);
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
