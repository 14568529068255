const WarrantAndReturnConditionsScreen = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Garanti ve İade Koşulları
        </div>
        <div style={{ marginTop: 30 }}>
          Sipariş ettiğiniz ürünü elinize geçmeden iptal etmek istiyorsanız
          bizimle iletişime geçiniz.
        </div>
        <div>
          Sevkiyat sırasında zarar gördüğünü düşündüğünüz paketleri teslim
          aldığınız kargo yetkilisi önünde açıp kontrol ediniz.
        </div>
        <div>
          Eğer üründe herhangi bir zarar varsa kargo firmasına tutanak
          tutturarak ürünü teslim almayınız.
        </div>
        <div>
          Ürün teslim alındıktan sonra kargo firmasının görevini tam olarak
          yerine getirdiğini kabul etmiş olduğunuzu unutmayınız.
        </div>
        <div style={{ marginTop: 30 }}>
          Siparişiniz elinize ulaşmış ve iptal etmek istiyorsanız:
        </div>
        <div>Bizimle iletişime geçiniz. 0(312) 228 01 86</div>
        <div style={{ marginTop: 30 }}>
          Not: Neturetim.com, üründe ve ambalajında herhangi bir açılma,
          bozulma, kırılma, tahrip, yırtılma, kullanılma ve benzer durumları
          tespit ettiği hallerde ve ürünün müşteriye teslim edildiği andaki hali
          ile iade edilememesi halinde ürünü iade almayacak ve bedelini iade
          etmeyecektir.
        </div>
        <div style={{ marginTop: 30 }}>
          Bu tür hallerde ürünün Neturetim.com'a gelişi ve geri dönüşü
          sırasındaki tüm kargo ücretleri müşteriye aittir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          İptal ettiğim siparişin ücretini nasıl alırım?
        </div>
        <div style={{ marginTop: 30 }}>
          İade işlemi tamamlandıktan sonra eğer kredi kartı ile ödeme
          yapmışsanız iadeniz 2 (iki) iş günü içerisinde bankanıza bildirilir.
          Kredi kartı iadelerinde iadeler bankanız kurallarına göre iade edilir.
          Eğer taksitli alışveriş yaptıysanız bankanız bu işlemi her ay
          taksitler oranında yapabilir. Diğer ödeme kanallarını kullanmış iseniz
          İptal işleminden 2 (iki) iş günü sonrasında hesabınıza iade edilir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Ürün Arızalı Çıktı Ne Yapmalıyım?
        </div>
        <div style={{ marginTop: 30 }}>
          Bizimle iletişime geçiniz. 0(312) 228 01 86
        </div>
      </div>
    </div>
  );
};

export default WarrantAndReturnConditionsScreen;
