import config from "../config/config.json";
import axios from "axios";

export const retrieveSettings = async () => {
  try {
    const response = await axios.get(
      `${config.baseUrl}${config.settings.context}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};
