import { Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { FaAddressCard, FaUserAlt } from "react-icons/fa";
import ShippingBillingInformation from "../../components/transportation/ShippingBillingInformation";
import { context } from "../../context/ContextProvider";
import { queryAddressList } from "../../requests/addressRequests";
import UserInformation from "../../components/user/UserInformation";

const SettingsScreen = () => {
  const appContext = useContext(context);
  const { userInformation } = appContext;
  const [pageState, setPageState] = useState("USER");
  const [billingList, setBillingList] = useState([]);
  const [selectedBillingId, setSelectedBillingId] = useState(
    userInformation ? userInformation.defaultBillingId : null
  );
  const [shippingList, setShippingList] = useState([]);
  const [selectedShippingId, setSelectedShippingId] = useState(
    userInformation ? userInformation.defaultShippingId : null
  );
  const [addressUpdated, setAddressUpdated] = useState(true);

  useEffect(() => {
    const retrieveAndSetShippingBillingList = async () => {
      const currentUser = await Auth.currentUserInfo();
      const addressList = await queryAddressList(currentUser.username);
      const filteredShippingList = addressList.filter(
        (address) => address.type === "SHIPPING"
      );
      setShippingList(filteredShippingList);
      const filteredBillingList = addressList.filter(
        (address) => address.type === "BILLING"
      );
      setBillingList(filteredBillingList);
    };
    if (addressUpdated) {
      retrieveAndSetShippingBillingList();
      setAddressUpdated(false);
    }
  }, [addressUpdated]);

  const RenderAddressContent = () => {
    return (
      <ShippingBillingInformation
        selectedBillingId={selectedBillingId}
        setSelectedBillingId={setSelectedBillingId}
        selectedBilling={billingList.find(
          (billing) => billing.id === selectedBillingId
        )}
        selectedShippingId={selectedShippingId}
        setSelectedShippingId={setSelectedShippingId}
        selectedShipping={shippingList.find(
          (shipping) => shipping.id === selectedShippingId
        )}
        shippingList={shippingList}
        billingList={billingList}
        setAddressUpdated={setAddressUpdated}
      />
    );
  };

  const RenderUserContent = () => {
    return <UserInformation />;
  };

  const RenderMainContent = () => {
    switch (pageState) {
      case "USER":
        return RenderUserContent();
      case "ADDRESS":
        return RenderAddressContent();
      default:
        return null;
    }
  };

  const RenderHeaderItem = (Icon, name, state) => {
    return (
      <div
        className={`box-tab-header-item ${
          state === pageState ? "box-tab-header-selected-item" : null
        }`}
        onClick={() => {
          setPageState(state);
        }}
      >
        <Icon size={40} />
        <div style={{ marginTop: 20 }}>{name}</div>
      </div>
    );
  };

  const RenderMainHeader = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-row">
          {RenderHeaderItem(FaUserAlt, "Bilgilerim", "USER")}
          {RenderHeaderItem(FaAddressCard, "Adreslerim", "ADDRESS")}
        </div>
      </div>
    );
  };
  return (
    <div style={{ marginTop: 50 }} className="container">
      <RenderMainHeader />
      <div style={{ marginTop: 30 }}>
        <RenderMainContent />
      </div>
    </div>
  );
};

export default SettingsScreen;
