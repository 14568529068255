import AWS from "aws-sdk";

export const retrieveS3Uploader = (bucketName) => {
  return new AWS.S3({ params: { Bucket: bucketName } });
};

export const uploadStreamToS3 = async (s3Uploader, file, filePath, ACL) => {
  await s3Uploader
    .upload({ Body: file, Key: filePath, ACL })
    .on("httpUploadProgress", function (event) {
      console.log(
        `[stream upload process]: on httpUploadProgress ${event.loaded} bytes`
      );
    })
    .promise();
  // managedUpload.on("httpUploadProgress", function (event) {
  //     console.log(
  //       `[stream upload process]: on httpUploadProgress ${event.loaded} bytes`
  //     );
  //   });

  // (err, data) => {
  //   if (err) {
  //     console.log(
  //       "[stream upload process] - failure - error handling on failure",
  //       err
  //     );
  //   } else {
  //     console.log(
  //       `[stream upload process] - success - uploaded the file to: ${data.Location}`
  //     );
  //   }
  // }
  //   );
};
