import React from "react";

const BoxLetterCartDetail = (props) => {
  const { parsedFileKey, parsedFile } = props;

  const RenderCartInformation = () => {
    const baseMaterialValue = parsedFile.baseMaterial;
    if (baseMaterialValue) {
      const baseMaterialId = parseInt(baseMaterialValue);
      if (baseMaterialId === 0) {
        return RenderPleksiglasCartInformation();
      } else if (baseMaterialId === 1) {
        return RenderAliminiumCartInformation();
      } else if (baseMaterialId === 2) {
        return RenderKromCartInformation();
      } else {
        return null;
      }
    }
  };

  const RenderPleksiglasCartInformation = () => {
    return (
      <div className="col">
        <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Harf Tipi</div>
            <div>Pleksiglass</div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center col">
          <div>
            <div style={{ fontWeight: "bold" }}>Pleksiglass Ön Yüzey</div>
            <div>
              {JSON.parse(parsedFile.pleksiThickness).thickness}{" "}
              {JSON.parse(parsedFile.pleksiThickness).thicknessUnit} /{" "}
              {JSON.parse(parsedFile.pleksiColor).name}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Yan Bant</div>
            <div>
              {JSON.parse(parsedFile.sideBandDepth).depth}{" "}
              {JSON.parse(parsedFile.sideBandDepth).depthUnit} /{" "}
              {JSON.parse(parsedFile.sideBandThickness).thickness}{" "}
              {JSON.parse(parsedFile.sideBandThickness).thicknessUnit}
            </div>
          </div>
          {parsedFile.includeLed === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Işıklandırma</div>
                <div>{JSON.parse(parsedFile.ledColor).name}</div>
              </div>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Trafo Malzeme</div>
                <div>Standart</div>
              </div>
            </React.Fragment>
          ) : parsedFile.includeDekota === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Dekota Kalınlığı</div>
                <div>
                  {JSON.parse(parsedFile.dekotaThickness).thickness}{" "}
                  {JSON.parse(parsedFile.dekotaThickness).thicknessUnit}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led / Dekota</div>
                <div>Yok / Yok</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const RenderAliminiumCartInformation = () => {
    return (
      <div className="col">
        <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Harf Tipi</div>
            <div>Alüminyum</div>
          </div>
          {parseInt(parsedFile.lightingType) === 0 ? (
            <React.Fragment>
              <div className="col-md-6">
                <div style={{ fontWeight: "bold" }}>Işıklandırma</div>
                <div>Önden</div>
              </div>
            </React.Fragment>
          ) : (
            <div className="col-md-6">
              <div style={{ fontWeight: "bold" }}>Işıklandırma</div>
              <div>Arkadan</div>
            </div>
          )}
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center col">
          {parseInt(parsedFile.lightingType) === 0 ? (
            <div>
              <div style={{ fontWeight: "bold" }}>Pleksiglass Ön Yüzey</div>
              <div>
                {JSON.parse(parsedFile.pleksiThickness).thickness}{" "}
                {JSON.parse(parsedFile.pleksiThickness).thicknessUnit} /{" "}
                {JSON.parse(parsedFile.pleksiColor).name}
              </div>
            </div>
          ) : null}
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme</div>
            <div>
              {JSON.parse(parsedFile.materialThickness).thickness}{" "}
              {JSON.parse(parsedFile.materialThickness).thicknessUnit}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Yan Bant</div>
            <div>
              {JSON.parse(parsedFile.sideBandDepth).depth}{" "}
              {JSON.parse(parsedFile.sideBandDepth).depthUnit} /{" "}
              {JSON.parse(parsedFile.sideBandThickness).thickness}{" "}
              {JSON.parse(parsedFile.sideBandThickness).thicknessUnit}
            </div>
          </div>
          {parsedFile.includeLed === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Işıklandırma</div>
                <div>{JSON.parse(parsedFile.ledColor).name}</div>
              </div>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Trafo Malzeme</div>
                <div>Standart</div>
              </div>
            </React.Fragment>
          ) : parsedFile.includeDekota === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Dekota Kalınlığı</div>
                <div>
                  {JSON.parse(parsedFile.dekotaThickness).thickness}{" "}
                  {JSON.parse(parsedFile.dekotaThickness).thicknessUnit}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led / Dekota</div>
                <div>Yok / Yok</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  const RenderKromCartInformation = () => {
    return (
      <div className="col">
        <div
          className="d-flex flex-row justify-content-around align-items-center"
          style={{ marginBottom: 20 }}
        >
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Harf Tipi</div>
            <div>Krom</div>
          </div>
          <div className="col-md-3">
            <div style={{ fontWeight: "bold" }}>Krom Rengi</div>
            <div>{JSON.parse(parsedFile.kromColor).name}</div>
          </div>
          {parseInt(parsedFile.lightingType) === 0 ? (
            <React.Fragment>
              <div className="col-md-3">
                <div style={{ fontWeight: "bold" }}>Işıklandırma</div>
                <div>Önden</div>
              </div>
            </React.Fragment>
          ) : (
            <div className="col-md-3">
              <div style={{ fontWeight: "bold" }}>Işıklandırma</div>
              <div>Arkadan</div>
            </div>
          )}
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center">
          {parseInt(parsedFile.lightingType) === 0 ? (
            <div>
              <div style={{ fontWeight: "bold" }}>Pleksiglass Ön Yüzey</div>
              <div>
                {JSON.parse(parsedFile.pleksiThickness).thickness}{" "}
                {JSON.parse(parsedFile.pleksiThickness).thicknessUnit} /{" "}
                {JSON.parse(parsedFile.pleksiColor).name}
              </div>
            </div>
          ) : null}
          <div>
            <div style={{ fontWeight: "bold" }}>Yan Bant</div>
            <div>
              {JSON.parse(parsedFile.sideBandDepth).depth}{" "}
              {JSON.parse(parsedFile.sideBandDepth).depthUnit} /{" "}
              {JSON.parse(parsedFile.sideBandThickness).thickness}{" "}
              {JSON.parse(parsedFile.sideBandThickness).thicknessUnit}
            </div>
          </div>
          {parsedFile.includeLed === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Işıklandırma</div>
                <div>{JSON.parse(parsedFile.ledColor).name}</div>
              </div>
              <div>
                <div style={{ fontWeight: "bold" }}>Led Trafo Malzeme</div>
                <div>Standart</div>
              </div>
            </React.Fragment>
          ) : parsedFile.includeDekota === "true" ? (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Dekota Kalınlığı</div>
                <div>
                  {JSON.parse(parsedFile.dekotaThickness).thickness}{" "}
                  {JSON.parse(parsedFile.dekotaThickness).thicknessUnit}
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <div style={{ fontWeight: "bold" }}>Led / Dekota</div>
                <div>Yok / Yok</div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-row justify-content-start align-items-center text-center mt-4">
      <div id={`canvas_${parsedFileKey}`} className="col-md-2" />
      {RenderCartInformation()}
    </div>
  );
};

export default BoxLetterCartDetail;
