const InputGroup = (props) => {
  const {
    id,
    label,
    type,
    defaultValue,
    placeholder,
    onChange,
    onFocus,
    optionList,
    disabled,
  } = props;

  const retrieveOptionList = () => {
    return optionList.map((option) => (
      <option value={option.value}>{option.text}</option>
    ));
  };
  return type === "select" ? (
    <div className="d-flex flex-column justify-content-center align-items-start">
      <div className="input-group-label">{label}</div>
      <select
        className="input-group-value"
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        defaultValue={defaultValue}
      >
        {retrieveOptionList()}
      </select>
    </div>
  ) : (
    <div className="d-flex flex-column justify-content-center align-items-start">
      <div className="input-group-label">{label}</div>
      <input
        id={id}
        className="input-group-value"
        defaultValue={defaultValue}
        type={type}
        placeholder={placeholder}
        onFocus={onFocus}
        onChange={onChange}
        disabled={disabled}
        style={{
          paddingLeft:
            id === "phoneNumber" ||
            id === "billing_phone_number" ||
            id === "shipping_phone_number"
              ? 50
              : 10,
        }}
      />
    </div>
  );
};

export default InputGroup;
