import axios from "axios";
import UserInformation from "../components/user/UserInformation";
import config from "../config/config.json";

export const createUser = async (user) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.users.context}`,
      user
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const queryUserListByEmail = async (email) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.users.context}${config.users.query.byEmail.context}`,
      {
        email,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const updateDefaultShippingId = async (id, defaultShippingId) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.users.context}${config.users.id.default_shipping_id.context}`.replace(
        "{id}",
        id
      ),
      {
        defaultShippingId,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const updateDefaultBillingId = async (id, defaultBillingId) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.users.context}${config.users.id.default_billing_id.context}`.replace(
        "{id}",
        id
      ),
      {
        defaultBillingId,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const patchUserInformation = async (id, patchUser) => {
  try {
    const response = await axios.patch(
      `${config.baseUrl}${config.users.context}${config.users.id.context}`.replace(
        "{id}",
        id
      ),
      patchUser
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
