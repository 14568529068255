import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div style={{ minHeight: 200, marginTop: 50, backgroundColor: "#e8e6e6" }}>
      <div className="container">
        <div style={{ paddingTop: 50, fontSize: 12 }}>
          <div className="d-flex flex-row flex-wrap">
            <div className="col-md-6" style={{ position: "relative" }}>
              <div className="d-flex flex-row align-items-center">
                <div
                  className="col-md-4"
                  style={{ borderRight: "1px solid", minHeight: 100 }}
                >
                  <div className="d-flex flex-column justify-content-center p-2">
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/membership-agreement");
                      }}
                    >
                      Üyelik Sözleşmesi
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/distant-sales-agreement");
                      }}
                    >
                      Satış Sözleşmesi
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/privacy-and-security");
                      }}
                    >
                      Gizlilik ve Güvenlik
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-4"
                  style={{ borderRight: "1px solid", minHeight: 100 }}
                >
                  <div className="d-flex flex-column justify-content-center p-2">
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/warrant-and-return-conditions");
                      }}
                    >
                      Garanti ve İade Koşulları
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/delivery-conditions");
                      }}
                    >
                      Teslimat Koşulları
                    </div>
                  </div>
                </div>
                <div className="col-md-4 m-auto" style={{ minHeight: 100 }}>
                  <div className="d-flex flex-column justify-content-center p-2">
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/about-us");
                      }}
                    >
                      Hakkımızda
                    </div>
                    <div
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        margin: 5,
                      }}
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      İletişim
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-row flex-wrap">
                <div className="col-md-3">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div style={{ marginTop: 10 }}>
                      <a
                        href="https://www.facebook.com/neturetim/"
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        <FaFacebookF size={30} />
                      </a>
                    </div>
                    <div style={{ marginTop: 10 }}>
                      <a
                        href="https://www.instagram.com/neturetim/"
                        target="_blank"
                        style={{ color: "black" }}
                      >
                        <FaInstagram size={30} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div style={{ fontWeight: "bold" }}>Telefon</div>
                    <div style={{ marginTop: 10 }}>
                      <a href="tel:+903122280186">0(312) 228 01 86</a>
                    </div>
                    <img
                      src="https://static.wixstatic.com/media/d1a664_e5b44de32277497f8e37edcdda2419a4~mv2.png/v1/fill/w_974,h_71,al_c,lg_1,enc_auto/bankalar_webp.png"
                      style={{ width: "100%", marginTop: 10 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
