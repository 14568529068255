const MembershipAgreement = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Üyelik Sözleşmesi
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>Üyelik Hakkında</div>
        <div style={{ marginTop: 30 }}>
          Mağazamıza üye olmak için Üye İşlemleri sayfamıza giderek ilgili
          bölümleri doldurmanız yeterlidir.Üye bilgilerinizi doğru ve eksiksiz
          doldurmanız iletişim ve ulaşım problemleri olasılığı açısından çok
          önemlidir. Ürün ve hizmetlerin çabuk ve sıhhatli ulaşması için lütfen
          dikkatle ve eksiksiz doldurunuz.
        </div>
        <div style={{ marginTop: 30 }}>
          Üye olmak oldukça basit ve hızlı bir işlemdir. Üye olmanız hiç bir
          yükümlülük altına girdiğinizi ifade etmez. Ancak alışveriş yapmadan
          önce Satış sözleşmesini dikkatle okuyunuz. Dilediğiniz zaman
          üyeliğinizi sonlandırma hakkına sahipsiniz.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>Üyelik İptali</div>
        <div style={{ marginTop: 30 }}>
          Üye dilediği zaman üyeliğini sonlandırma hakkına sahiptir. Üyeliğinizi
          sonlandırdıktan sonra sitemiz ile tüm ilişkiniz kesilmiş olacaktır.
          Üyeliğinizi sonlandırmak için üye girişi yaptıktan sonra iletişim
          bilgileri sayfamızdan talebinizi iletmelisiniz.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Aşağıdaki Durumlarda Üyelik İptal Edilemez
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ marginLeft: 20 }}>
            <ol>
              <li>Üye son verdiği sipariş üzerinden 60 gün geçmemişse.</li>
              <li>
                Farklı bir e-posta adresi ile üyelik iptal talebinde
                bulunulduysa
              </li>
              <li>Üyeye Kayıtlı bilgilerinden ulaşılamıyorsa</li>
            </ol>
          </div>
        </div>

        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Aşağıdaki Durumlarda Üyelik Tarafımızdan İptal Edilir
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ marginLeft: 20 }}>
            <ol>
              <li>Üye Ahlak dışı, mesajlar gönderiyor ya da yorum yazıyorsa</li>
              <li>Sahtekarlık girişiminde bulunduysa</li>
              <li>
                Sisteme veya Neturetim.com ismine zarar vermeye yönelik
                girişimde bulunduysa.
              </li>
            </ol>
          </div>
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>Üye Güvenliği</div>
        <div style={{ marginTop: 30 }}>
          Üyenin güvenliği için mağazamızda her türlü önlem alınmıştır. Bu
          alınan önlemlerin yanında sizlerde üye bilgilerinizin güvenliğinden
          sorumlusunuz. Mağazamıza giriş için kullandığınız bilgilerinizi hiç
          kimse ile paylaşmayın, güvenli olduğundan emin olmadığınız
          bilgisayarlardan sisteme giriş yapmayın.
        </div>

        <div style={{ marginTop: 30, fontWeight: "bold" }}>Farklı Adres</div>
        <div style={{ marginTop: 30 }}>
          Her üye İçin Sipariş Sonlandırırken kendi kayıtlı adresi haricinde
          farklı adres girebileceği bir adres bölümü bulunmaktadır. Adres
          bölümünü arkadaşlarınıza hediye gönderirken kullanabileceğiniz gibi,
          farklı adreslerde olduğunuz dönemlerde kullanabilmeniz için
          düşünülmüştür.
        </div>
        <div style={{ marginTop: 30 }}>
          Örneğin: Çalıştığınız şirketin farklı şubelerinde bulunacağınız
          dönemlerde veya yazlıkta kaldığınız dönemlerde. Yâda faturanızın size
          siparişinizin bir arkadaşınıza ulaşmasını istiyorsanız
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>Ürün Yorumları</div>
        <div style={{ marginTop: 30 }}>
          Her üye ürünlere yorum yazabilir. Bilgilerinizi ve tecrübelerinizi
          diğer kullanıcılarla paylaştıkça, alışveriş daha keyifli ve bilinçli
          olacaktır. Müşteri yorumları tarafsız ve tecrübeye dayalı olacağı için
          daha bilinçli alışveriş ortamı doğacaktır.
        </div>
        <div style={{ marginTop: 30 }}>
          Ürün yorumu yazarken dikkat etmeniz gerekenler: Genel ahlak kuralları
          çerçevesinde, diğer kullanıcılara ve ürünün üreticisine saygılı
          yorumlar yazmaya özen gösterin. Yorumlar incelenerek uygun görülmeyen
          yorumlar sistemden silinmektedir.
        </div>
      </div>
    </div>
  );
};

export default MembershipAgreement;
