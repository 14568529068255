import React, { useContext, useEffect, useRef, useState } from "react";
import { context } from "../../context/ContextProvider";
import { queryPrintingTypeDetailList } from "../../requests/printingTypeDetailRequests";
import InputGroup from "../InputGroup";

const CncCuttingFileDetail = (props) => {
  const appContext = useContext(context);
  const { cuttingList, colorList } = appContext;
  const {
    parsedFileKey,
    printingTypeList,
    metricUpdated,
    setConstructedFileMap,
  } = props;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [printingTypeIndex, setPrintingTypeIndex] = useState(3);
  const [printingTypeDetail, setPrintingTypeDetail] = useState(null);
  const colorRef = useRef();
  let intervalId;

  useEffect(() => {
    const retrieveAndSetPrintingTypeDetail = async (printingTypeIndex) => {
      const foundPrintingTypeDetailList = await queryPrintingTypeDetailList(
        printingTypeIndex
      );
      if (foundPrintingTypeDetailList.length > 0) {
        setPrintingTypeDetail(foundPrintingTypeDetailList[0]);
        props.setMetricUpdated(true);
      }
    };
    retrieveAndSetPrintingTypeDetail(printingTypeIndex);
  }, [printingTypeIndex]);

  useEffect(() => {
    intervalId = setInterval(() => {
      props.setMetricUpdated(true);
    }, [1000]);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  useEffect(() => {
    const retrieveSingleElementValue = (id) => {
      const element = document.getElementById(`${id}${parsedFileKey}`);
      if (element && element.value) {
        return element.value;
      }
      return null;
    };
    const retrieveAndSetConstructedFileMap = () => {
      setConstructedFileMap((prevConstructedFileMap) => ({
        ...prevConstructedFileMap,
        [parsedFileKey]: {
          baseMaterial: retrieveSingleElementValue("base_material_"),
          cuttingType: retrieveSingleElementValue("cutting_type_"),
          materialThickness: retrieveSingleElementValue("material_thickness_"),
          materialColor: retrieveSingleElementValue("material_color_"),
          count: retrieveSingleElementValue("count_"),
          sideBandDepth: retrieveSingleElementValue("sideband_depth_"),
          profitPercentage: printingTypeDetail.profitPercentage,
        },
      }));
    };
    const setColorToDiv = (ref, id, key) => {
      const colorElement = document.getElementById(id);
      if (colorElement && colorElement.value) {
        const colorInformation = JSON.parse(colorElement.value);
        const foundColor = colorList.find(
          (color) => color.id === colorInformation[key]
        );
        ref.current.style.backgroundColor = foundColor.code;
      }
    };
    if (metricUpdated && printingTypeDetail) {
      retrieveAndSetConstructedFileMap();
      if (colorRef && colorRef.current) {
        setColorToDiv(colorRef, `material_color_${parsedFileKey}`, "id");
      }
    }
  }, [metricUpdated]);

  const retrievePrintingTypeCuttings = () => {
    if (printingTypeDetail && printingTypeDetail.associatedCuttingIdList) {
      const associatedCuttingList = [];
      printingTypeDetail.associatedCuttingIdList.forEach(
        (associatedCuttingId) => {
          const foundCutting = cuttingList.find(
            (cutting) => cutting.id === associatedCuttingId
          );
          if (foundCutting) {
            associatedCuttingList.push(foundCutting);
          }
        }
      );

      return associatedCuttingList.map((associatedCutting) => ({
        value: JSON.stringify(associatedCutting),
        text: associatedCutting.name,
      }));
    }
    return [];
  };

  const retrievePrintingTypeMaterialThicknessList = () => {
    if (printingTypeDetail) {
      const cuttingTypeElement = document.getElementById(
        `cutting_type_${parsedFileKey}`
      );
      const materialColorElement = document.getElementById(
        `material_color_${parsedFileKey}`
      );
      if (
        cuttingTypeElement &&
        cuttingTypeElement.value &&
        materialColorElement &&
        materialColorElement.value
      ) {
        const cuttingType = JSON.parse(cuttingTypeElement.value);
        const materialColor = JSON.parse(materialColorElement.value);
        if (printingTypeDetail.materialThicknessList) {
          const filteredMaterialThicknessList =
            printingTypeDetail.materialThicknessList.filter(
              (materialThickness) =>
                materialThickness.cuttingId === cuttingType.id &&
                materialThickness.colorId === materialColor.id
            );
          filteredMaterialThicknessList.sort(
            (a, b) => parseFloat(a.thickness) - parseFloat(b.thickness)
          );
          return filteredMaterialThicknessList.map((materialThickness) => ({
            value: JSON.stringify(materialThickness),
            text: `${materialThickness.thickness} (${materialThickness.thicknessUnit})`,
          }));
        }
      }
    }

    return [];
  };

  const retrievePrintingTypeMaterialColorList = () => {
    if (printingTypeDetail) {
      const cuttingTypeElement = document.getElementById(
        `cutting_type_${parsedFileKey}`
      );
      if (cuttingTypeElement && cuttingTypeElement.value) {
        const cuttingType = JSON.parse(cuttingTypeElement.value);
        if (printingTypeDetail.materialThicknessList) {
          const filteredMaterialThicknessList =
            printingTypeDetail.materialThicknessList.filter(
              (materialThickness) =>
                materialThickness.cuttingId === cuttingType.id
            );
          const materialColorList = [];
          filteredMaterialThicknessList.forEach((filteredMaterialThickness) => {
            const foundIndex = materialColorList.findIndex(
              (materialColor) =>
                materialColor.id === filteredMaterialThickness.colorId
            );
            if (foundIndex === -1) {
              const foundColor = colorList.find(
                (color) => color.id === filteredMaterialThickness.colorId
              );
              if (foundColor) {
                materialColorList.push(foundColor);
              }
            }
          });
          return materialColorList.map((materialColor) => ({
            value: JSON.stringify(materialColor),
            text: materialColor.name,
          }));
        }
      }
    }
    return [];
  };

  const retrievePrintingTypeCountList = () => {
    return [
      { text: 1, value: 1 },
      { text: 2, value: 2 },
      { text: 3, value: 3 },
      { text: 4, value: 4 },
      { text: 5, value: 5 },
    ];
  };

  const retrievePrintingTypeSidebandDepthList = () => {
    if (printingTypeDetail && printingTypeDetail.sidebandDepthList) {
      const materialColorElement = document.getElementById(
        `material_color_${parsedFileKey}`
      );
      if (materialColorElement && materialColorElement.value) {
        const materialColor = JSON.parse(materialColorElement.value);
        const filteredSidebandDepthList =
          printingTypeDetail.sidebandDepthList.filter(
            (sidebandDepth) => sidebandDepth.colorId === materialColor.id
          );
        return filteredSidebandDepthList.map((sidebandDepth) => ({
          value: JSON.stringify(sidebandDepth),
          text: `${sidebandDepth.depthName}`,
        }));
      }
    }
    return [];
  };

  const RenderSpecificWoodSideband = () => {
    const cuttingTypeElement = document.getElementById(
      `cutting_type_${parsedFileKey}`
    );
    if (cuttingTypeElement && cuttingTypeElement.value) {
      const cuttingType = JSON.parse(cuttingTypeElement.value);
      if (cuttingType.name.toUpperCase() === "SUNTA") {
        return (
          <div className="col-md-6 mt-3">
            <InputGroup
              id={`sideband_depth_${parsedFileKey}`}
              label="Yan Bant"
              type="select"
              optionList={retrievePrintingTypeSidebandDepthList()}
              onChange={() => {
                props.setMetricUpdated(true);
              }}
            />
          </div>
        );
      }
    }

    return null;
  };

  const RenderWoodPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`cutting_type_${parsedFileKey}`}
            label="Malzeme"
            type="select"
            optionList={retrievePrintingTypeCuttings()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`material_color_${parsedFileKey}`}
                label="Renk"
                type="select"
                optionList={retrievePrintingTypeMaterialColorList()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={colorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`count_${parsedFileKey}`}
            label="Adet"
            type="select"
            optionList={retrievePrintingTypeCountList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        {RenderSpecificWoodSideband()}
      </React.Fragment>
    );
  };

  const RenderPlasticPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`cutting_type_${parsedFileKey}`}
            label="Malzeme"
            type="select"
            optionList={retrievePrintingTypeCuttings()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`material_color_${parsedFileKey}`}
                label="Renk"
                type="select"
                optionList={retrievePrintingTypeMaterialColorList()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={colorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`count_${parsedFileKey}`}
            label="Adet"
            type="select"
            optionList={retrievePrintingTypeCountList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  const RenderMetalPrintingTypeDetails = () => {
    return (
      <React.Fragment>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`cutting_type_${parsedFileKey}`}
            label="Malzeme"
            type="select"
            optionList={retrievePrintingTypeCuttings()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`material_thickness_${parsedFileKey}`}
            label="Malzeme Kalınlığı"
            type="select"
            optionList={retrievePrintingTypeMaterialThicknessList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
        <div className="col-md-6 mt-3">
          <div className="row">
            <div style={{ position: "relative" }}>
              <InputGroup
                id={`material_color_${parsedFileKey}`}
                label="Renk"
                type="select"
                optionList={retrievePrintingTypeMaterialColorList()}
                onChange={() => {
                  props.setMetricUpdated(true);
                }}
              />
              <div
                ref={colorRef}
                className="single-file-detail-color-ball-div"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-3">
          <InputGroup
            id={`count_${parsedFileKey}`}
            label="Adet"
            type="select"
            optionList={retrievePrintingTypeCountList()}
            onChange={() => {
              props.setMetricUpdated(true);
            }}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-start align-items-center flex-wrap">
        <div className="col-md-12">
          <div>
            <span style={{ fontWeight: "bold" }}>Dosya İsmi: </span>
            {parsedFileKey}
          </div>
        </div>
        <div className="col-md-9 mt-2">
          <InputGroup
            id={`base_material_${parsedFileKey}`}
            type="select"
            optionList={printingTypeList.map((printingType) => ({
              text: printingType.name,
              value: printingType.id,
            }))}
            onChange={(e) => {
              const value = e.target.value;
              const selectedPrintingType = printingTypeList.find(
                (printingType) => printingType.id === parseInt(value)
              );
              setPrintingTypeIndex(selectedPrintingType.id);
            }}
            label="Malzeme Grubu"
          />
        </div>
      </div>

      <div className="row">
        {printingTypeIndex === 3
          ? RenderWoodPrintingTypeDetails()
          : printingTypeIndex === 4
          ? RenderPlasticPrintingTypeDetails()
          : printingTypeIndex === 5
          ? RenderMetalPrintingTypeDetails()
          : null}
      </div>
    </React.Fragment>
  );
};

export default CncCuttingFileDetail;
