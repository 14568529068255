import InputGroup from "../../components/InputGroup";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RiRefreshLine } from "react-icons/ri";
import React, { useState } from "react";

const ActivateAccountScreen = () => {
  const navigate = useNavigate();
  const [activationCodeSent, setActivationCodeSent] = useState(false);

  const handleVerifyAccount = () => {
    const email = document.getElementById("email").value.trim();
    if (email) {
      const verificationCodeElement =
        document.getElementById("verification_code");
      if (verificationCodeElement) {
        const verificationCode = verificationCodeElement.value.trim();
        if (verificationCode) {
          Auth.confirmSignUp(email, verificationCode)
            .then((data) => {
              if (data === "SUCCESS") {
                toast(
                  "Hesabınız başarı ile aktive oldu. Giriş yaparak devam edebilirsiniz."
                );
                navigate("/login");
              }
              console.log(data);
            })
            .catch((err) => {
              if (
                (err.message =
                  "Invalid verification code provided, please try again.")
              ) {
                toast(
                  "Geçersiz aktivasyon kodu, lütfen e-mailinize en son gönderilen kodu girin ve devam edin."
                );
              }
            });
        } else {
          toast("Lütfen bütün alanları doldurun");
        }
      } else {
        toast("Lütfen önce onay kodunu gönderin");
      }
    } else {
      toast("Lütfen bütün alanları doldurun");
    }
  };

  const handleResendCode = () => {
    const email = document.getElementById("email").value.trim();
    if (email) {
      Auth.resendSignUp(email)
        .then(() => {
          setActivationCodeSent(true);
          toast(
            "Aktivasyon kodunuz başarıyla gönderildi. Lütfen e-mailinizi kontrol edin."
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast("Lütfen geçerli bir e-mail adresi girin");
    }
  };

  return (
    <div className="login-register-screen-template">
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/login_image.png" style={{ width: "100%" }}></img>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                Hesabınızı Aktive Edin
              </div>
              <div className="col-md-9">
                <div
                  style={{ fontSize: 14, textAlign: "center", marginTop: 20 }}
                >
                  Lütfen önce email adresinizi girip yenileme butonuna basın.
                  Ardından emailinize gönderilen kodu aşağıdaki alana girip
                  hesabınızı aktive edebilirsiniz
                </div>
              </div>
              <div className="col-md-9" style={{ marginTop: 40 }}>
                <div style={{ position: "relative", paddingRight: 50 }}>
                  <InputGroup
                    id="email"
                    label="E-mail"
                    type="text"
                    placeholder="Emailiniz"
                  />
                  <RiRefreshLine
                    onClick={handleResendCode}
                    size={30}
                    style={{
                      // color: "#d75d34",
                      color: "#ff6700",
                      // border: "1px solid #d75d34",
                      border: "1px solid #ff6700",
                      borderRadius: 5,
                      position: "absolute",
                      right: 10,
                      padding: 5,
                      top: 32,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {activationCodeSent ? (
                  <div className="mt-2">
                    <InputGroup
                      id="verification_code"
                      label="Aktivasyon Kodu"
                      type="number"
                      placeholder="Aktivasyon kodunu girin"
                    />
                  </div>
                ) : null}

                <div className="col-md-6 mt-5 text-center w-100">
                  <button
                    className={`${
                      activationCodeSent ? "primary-button" : "secondary-button"
                    }`}
                    onClick={handleVerifyAccount}
                    style={{ width: "100%" }}
                  >
                    Hesabımı aktive et
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountScreen;
