import config from "../config/config.json";
import axios from "axios";

export const sendOrderReceivedEmail = async (order) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.email.context}${config.email.sendOrderReceived.context}`,
      order
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const sendOrderCreatedEmail = async (order) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.email.context}${config.email.sendOrderCreated.context}`,
      order
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
