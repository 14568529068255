import axios from "axios";
import config from "../config/config.json";

export const responsePayment = async (payment) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.payment.context}${config.payment.prod.responsePayment.context}`,
      payment
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const initializeThreeDsPayment = async (payment) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.payment.context}${config.payment.prod.initializeThreeDs.context}`,
      payment
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const initializeCheckoutFormPayment = async (payment) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.payment.context}${config.payment.prod.initializeCheckoutForm.context}`,
      payment
    );
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const queryPaymentListByConversationId = async (conversationId) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.payment.context}${config.payment.query.byConversationId.context}`,
      { conversationId }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
