const PrivacyAndSecurityScreen = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Gizlilik ve Güvenlik
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel Verileri Koruma Kanunu hakkında genel bilgilendirme
        </div>
        <div style={{ marginTop: 30 }}>
          6698 Sayılı Kişisel Verilerin Korunması Kanunu (bundan sonra KVKK
          olarak anılacaktır) 24 Mart 2016 tarihinde kabul edilmiş, 7 Nisan 2016
          tarihli 29677 sayılı Resmi Gazete’de yayınlanmıştır. KVKK’nun bir
          kısmı yayın tarihinde, bir kısmı ise 7 Ekim 2016’da yürürlüğe
          girmiştir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Veri sorumlusu sıfatıyla bilgilendirme
        </div>
        <div style={{ marginTop: 30 }}>
          6698 sayılı KVKK uyarınca ve Veri Sorumlusu sıfatıyla, kişisel
          verileriniz bu sayfada açıklandığı çerçevede; kaydedilecek,
          saklanacak, güncellenecek, mevzuatın izin verdiği durumlarda 3.
          kişilere açıklanabilecek / devredilebilecek, sınıflandırılabilecek ve
          KVKK’da sayılan şekillerde işlenebilecektir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerinizin ne şekilde işlenebileceği
        </div>
        <div style={{ marginTop: 30 }}>
          6698 sayılı KVKK uyarınca, Firmamız ile paylaştığınız kişisel
          verileriniz, tamamen veya kısmen, otomatik olarak, veyahut herhangi
          bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
          yollarla elde edilerek, kaydedilerek, depolanarak, değiştirilerek,
          yeniden düzenlenerek, kısacası veriler üzerinde gerçekleştirilen her
          türlü işleme konu olarak tarafımızdan işlenebilecektir. KVKK
          kapsamında veriler üzerinde gerçekleştirilen her türlü işlem "kişisel
          verilerin işlenmesi” olarak kabul edilmektedir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerinizin işlenme amaçları ve hukuki sebepleri
        </div>
        <div style={{ marginTop: 30 }}>
          Paylaştığınız kişisel veriler,
          <div style={{ marginLeft: 20 }}>
            Müşterilerimize verdiğimiz hizmetlerin gereklerini, sözleşmenin ve
            teknolojinin gereklerine uygun şekilde yapabilmek, sunulan ürün ve
            hizmetlerimizi geliştirebilmek için;
          </div>
          <div style={{ marginLeft: 20 }}>
            6563 sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6502
            sayılı Tüketicinin Korunması Hakkında Kanun ile bu düzenlemelere
            dayanak yapılarak hazırlanan 26.08.2015 tarihli 29457 sayılı RG’de
            yayınlanan Elektronik Ticarette Hizmet Sağlayıcı ve Aracı Hizmet
            Sağlayıcılar Hakkında Yönetmelik, 27.11.2014 tarihli ve 29188 sayılı
            RG’de yayınlanan Mesafeli Sözleşmeler Yönetmeliği ve diğer ilgili
            mevzuat kapsamında işlem sahibinin bilgilerini tespit için kimlik,
            adres ve diğer gerekli bilgileri kaydetmek için;
          </div>
          <div style={{ marginLeft: 20 }}>
            Bankacılık ve Elektronik Ödeme alanında zorunlu olan ödeme
            sistemleri, elektronik sözleşme veya kağıt ortamında işleme dayanak
            olacak tüm kayıt ve belgeleri düzenlemek; mevzuat gereği ve diğer
            otoritelerce öngörülen bilgi saklama, raporlama, bilgilendirme
            yükümlülüklerine uymak için;
          </div>
          <div style={{ marginLeft: 20 }}>
            Kamu güvenliğine ilişkin hususlarda ve hukuki uyuşmazlıklarda, talep
            halinde ve mevzuat gereği savcılıklara, mahkemelere ve ilgili kamu
            görevlilerine bilgi verebilmek için;
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          6698 sayılı KVKK ve ilgili ikincil düzenlemelere uygun olarak
          işlenecektir. Kişisel verilerinizin aktarılabileceği üçüncü kişi veya
          kuruluşlar hakkında bilgilendirme Yukarıda belirtilen amaçlarla,
          Firmamız ile paylaştığınız kişisel verilerinizin aktarılabileceği kişi
          / kuruluşlar; başta Firmamızın e-ticaret altyapısını sağlayan Ticimax
          olmak üzere, tedarikçiler, kargo şirketleri gibi sunulan hizmetler ile
          ilgili kişi ve kuruluşlar, faaliyetlerimizi yürütmek üzere ve/veya
          Veri İşleyen sıfatı ile hizmet alınan, iş birliği yaptığımız program
          ortağı kuruluşları, yurtiçi / yurtdışı kuruluşlar ve diğer 3.
          kişilerdir.
        </div>

        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerinizin toplanma şekli
        </div>
        <div style={{ marginTop: 30 }}>
          Kişisel verileriniz,
          <div style={{ marginLeft: 20 }}>
            Firmamız internet sitesi ve mobil uygulamalarındaki formlar
            aracılığıyla ad, soyad, TC kimlik numarası, adres, telefon, iş veya
            özel e-posta adresi gibi bilgiler ile; kullanıcı adı ve şifresi
            kullanılarak giriş yapılan sayfalardaki tercihleri, gerçekleştirilen
            işlemlerin IP kayıtları, tarayıcı tarafından toplanan çerez verileri
            ile gezinme süre ve detaylarını içeren veriler, konum verileri
            şeklinde;
          </div>
          <div style={{ marginLeft: 20 }}>
            Satış ve pazarlama departmanı çalışanlarımız, şubelerimiz,
            tedarikçilerimiz, diğer satış kanalları, kağıt üzerindeki formlar,
            kartvizitler, dijital pazarlama ve çağrı merkezi gibi kanallarımız
            aracılığıyla sözlü, yazılı veya elektronik ortamdan;
          </div>
          <div style={{ marginLeft: 20 }}>
            Firmamız ile ticari ilişki kurmak, iş başvurusu yapmak, teklif
            vermek gibi amaçlarla, kartvizit, özgeçmiş (cv), teklif vermek ve
            sair yollarla kişisel verilerini paylaşan kişilerden alınan, fiziki
            veya sanal bir ortamda, yüz yüze ya da mesafeli, sözlü veya yazılı
            ya da elektronik ortamdan;
          </div>
          <div style={{ marginLeft: 20 }}>
            Ayrıca farklı kanallardan dolaylı yoldan elde edilen, web sitesi,
            blog, yarışma, anket, oyun, kampanya ve benzeri amaçlı kullanılan
            (mikro) web sitelerinden ve sosyal medyadan elde edilen veriler,
            e-bülten okuma veya tıklama hareketleri, kamuya açık veri
            tabanlarının sunduğu veriler, sosyal medya platformlarından
            paylaşıma açık profil ve verilerden; işlenebilmekte ve
            toplanabilmektedir.
          </div>
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          KVKK yürürlüğe girmeden önce elde edilen kişisel verileriniz
        </div>
        <div style={{ marginTop: 30 }}>
          KVKK’nun yürürlük tarihi olan 7 Nisan 2016 tarihinden önce, üyelik,
          elektronik ileti izni, ürün / hizmet satın alma ve diğer şekillerde
          hukuka uygun olarak elde edilmiş olan kişisel verileriniz de bu
          belgede düzenlenen şart ve koşullara uygun olarak işlenmekte ve
          muhafaza edilmektedir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerinizin yurtdışına aktarılması
        </div>
        <div style={{ marginTop: 30 }}>
          Türkiye’de işlenerek veya Türkiye dışında işlenip muhafaza edilmek
          üzere, yukarıda sayılan yöntemlerden herhangi birisi ile toplanmış
          kişisel verileriniz KVKK kapsamında kalmak kaydıyla ve sözleşme
          amaçlarına uygun olarak yurtdışında bulunan (Kişisel Veriler Kurulu
          tarafından akredite edilen ve kişisel verilerin korunması hususunda
          yeterli korumanın bulunduğu ülkelere) hizmet aracılarına da
          aktarılabilecektir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerin saklanması ve korunması
        </div>
        <div style={{ marginTop: 30 }}>
          Kişisel verileriniz, Firmamız nezdinde yer alan veri tabanında ve
          sistemlerde KVKK’nun 12. maddesi gereğince gizli olarak saklanacak;
          yasal zorunluluklar ve bu belgede belirtilen düzenlemeler haricinde
          hiçbir şekilde üçüncü kişilerle paylaşılmayacaktır. Firmamız, kişisel
          verilerinizin barındığı sistemleri ve veri tabanlarını, KVKK’nun 12.
          Maddesi gereği kişisel verilerin hukuka aykırı olarak işlenmesini
          önlemekle, yetkisiz kişilerin erişimlerini engellemekle, erişim
          yönetimi gibi yazılımsal tedbirleri ve fiziksel güvenlik önlemleri
          almakla mükelleftir. Kişisel verilerin yasal olmayan yollarla
          başkaları tarafından elde edilmesinin öğrenilmesi halinde durum
          derhal, yasal düzenlemeye uygun ve yazılı olarak Kişisel Verileri
          Koruma Kurulu’na bildirilecektir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          Kişisel verilerin güncel ve doğru tutulması
        </div>
        <div style={{ marginTop: 30 }}>
          KVKK’nun 4. maddesi uyarınca Firmamızın kişisel verilerinizi doğru ve
          güncel olarak tutma yükümlülüğü bulunmaktadır. Bu kapsamda Firmamızın
          yürürlükteki mevzuattan doğan yükümlülüklerini yerine getirebilmesi
          için Müşterilerimizin doğru ve güncel verilerini paylaşması veya web
          sitesi / mobil uygulama üzerinden güncellemesi gerekmektedir.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          6698 sayılı KVKK uyarınca kişisel veri sahibinin hakları
        </div>
        <div style={{ marginTop: 30 }}>
          6698 sayılı KVKK 11.maddesi 07 Ekim 2016 tarihinde yürürlüğe girmiş
          olup ilgili madde gereğince, Kişisel Veri Sahibi’nin bu tarihten
          sonraki hakları aşağıdaki gibidir: Kişisel Veri Sahibi, Firmamıza
          (veri sorumlusu) başvurarak kendisiyle ilgili;
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ marginLeft: 20 }}>
            <ol>
              <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
              <li>
                Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde
                bunların düzeltilmesini isteme,
              </li>
              <li>
                KVKK’nun 7. maddesinde öngörülen şartlar çerçevesinde kişisel
                verilerin silinmesini veya yok edilmesini isteme,
              </li>
              <li>
                Kişisel verilerin düzeltilmesi, silinmesi, yok edilmesi halinde
                bu işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere de
                bildirilmesini isteme,
              </li>
              <li>
                İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun
                ortaya çıkmasına itiraz etme,
              </li>
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğraması hâlinde zararın giderilmesini talep etme,
              </li>
            </ol>
            haklarına sahiptir.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyAndSecurityScreen;
