import axios from "axios";
import config from "../config/config.json";

export const queryOrderList = async (username) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.orders.context}${config.orders.query.byUsername.context}`,
      {
        username,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const createOrder = async (order) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.orders.context}`,
      order
    );
    return response;
  } catch (err) {
    console.log(err);
    return null;
  }
};
