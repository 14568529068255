const AboutUsScreen = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Hakkımızda
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          NetÜretim Serüveni
        </div>
        <div style={{ marginTop: 30 }}>
          Netüretim, teknoloji ve yenilikleri yakından takip ederek, online
          alışveriş sisteminin de yaygınlaşmasıyla, fiyatlama, üretim ve tedarik
          sürecinin en aza indirilmesi amacıyla kurulmuş bir e-ticaret ve üretim
          merkezini birleştiren projedir.
        </div>
        <div style={{ marginTop: 30 }}>
          Netüretim, imalat sektöründe yıllarca süren tecrübelerimizin sonucunda
          ulaşılmış bir endüstriyel e-ticaret sistemidir. Netüretim.com Onur
          reklam tarafından kurulmuştur.
        </div>
        <img
          src="https://static.wixstatic.com/media/a3c153_20122b9a32cc4e9a9faca835b9f82d14~mv2.jpg/v1/fill/w_1162,h_383,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a3c153_20122b9a32cc4e9a9faca835b9f82d14~mv2.jpg"
          style={{ marginTop: 30, width: "100%" }}
        />
        <div style={{ marginTop: 30, fontWeight: "bold" }}>Hikayemiz</div>
        <div style={{ marginTop: 30 }}>
          1998 yılında başladığımız tabela imalat sektöründe teknolojinin
          sektörümüze katmış olduğu yeniliklerle beraber, fason üretimin
          yaygınlaşmasıyla faytlandırma ve üretim sürecini hızlandırmak için
          Netüretim.com'u kurduk.
        </div>
        <div style={{ marginTop: 30 }}>
          Netüretim.com, talaşlı imalat ve sanayi makinelerini online platforma
          taşıyarak hızlı bir şekilde fiyatlandırma sağlayabilmektedir. en büyük
          artımız ise kullanıcıların kendi dosyalarını sistemimiz üzerine
          yükleme yöntemiyle fiyatlandırma sağlamaktayız.{" "}
        </div>
      </div>
    </div>
  );
};

export default AboutUsScreen;
