import InputGroup from "../../components/InputGroup";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LoginScreen = (props) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    const email = document.getElementById("email").value.trim();
    const password = document.getElementById("password").value.trim();
    if (email && password) {
      Auth.signIn({
        username: email,
        password,
      })
        .then((user) => {
          props.setAxiosInterceptor(
            user.signInUserSession.accessToken.jwtToken
          );
          toast("Başarıyla giriş yaptınız!");
          navigate("/");
        })
        .catch((err) => {
          if (err.message === "Incorrect username or password.") {
            toast("Geçersiz kullanıcı adı veya şifre");
          }
          console.log(err);
        });
    }
  };

  return (
    <div className="login-register-screen-template">
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/login_image.png" style={{ width: "100%" }}></img>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                Giriş Yapın
              </div>
              <div className="col-md-9" style={{ marginTop: 40 }}>
                <div>
                  <InputGroup
                    id="email"
                    label="E-Mail"
                    type="text"
                    placeholder="E-mail adresinizi girin"
                  />
                </div>
                <div className="mt-2">
                  <InputGroup
                    id="password"
                    label="Şifre"
                    type="password"
                    placeholder="Şifrenizi girin"
                  />
                </div>

                <div className="col-md-6 mt-4 text-center w-100">
                  <button
                    className="primary-button"
                    onClick={handleOnClick}
                    style={{ width: "100%" }}
                  >
                    Giriş Yap
                  </button>
                </div>
                <div
                  style={{ textAlign: "right", marginTop: 20, fontSize: 14 }}
                >
                  Hesabınız yok mu? Dilerseniz{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    buradan
                  </span>{" "}
                  kayıt olabilirsiniz
                </div>
                <div
                  style={{ textAlign: "right", marginTop: 10, fontSize: 14 }}
                >
                  Aktivasyon kodunuzu{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/activate-account");
                    }}
                  >
                    buradan
                  </span>{" "}
                  yeniden gönderebilirsiniz
                </div>
                <div
                  style={{ textAlign: "right", marginTop: 10, fontSize: 14 }}
                >
                  Şifrenizi mi unuttunuz? Dilerseniz{" "}
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/forgot-password");
                    }}
                  >
                    buradan
                  </span>{" "}
                  yenileyebilirsiniz
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
