import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const BoxLetterProductionScreen = () => {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const RenderCarouselItemList = () => {
    return (
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_153dcf77a3d14877b83b16b63d0068b4~mv2.jpg/v1/fill/w_1000,h_367,al_c,q_85,enc_auto/d1a664_153dcf77a3d14877b83b16b63d0068b4~mv2.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_05840660304f4d86b54c436647d8b1bd~mv2.png/v1/fill/w_1000,h_367,al_c,enc_auto/d1a664_05840660304f4d86b54c436647d8b1bd~mv2.png"
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_b9d94076b17948db8769e008ba4971bd~mv2.jpg/v1/fill/w_1000,h_367,al_c,q_85,enc_auto/d1a664_b9d94076b17948db8769e008ba4971bd~mv2.jpg"
            alt="Third slide"
          />

          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_f6e9449b0af1450c8db25e50e9048dac~mv2.jpg/v1/fill/w_1000,h_367,al_c,q_85,enc_auto/d1a664_f6e9449b0af1450c8db25e50e9048dac~mv2.jpg"
            alt="Forth slide"
          />

          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_a2fa9bda95e046d9a04781774277f688~mv2.jpg/v1/fill/w_1000,h_367,al_c,q_85,enc_auto/d1a664_a2fa9bda95e046d9a04781774277f688~mv2.jpg"
            alt="Fifth slide"
          />

          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            onClick={() => {
              navigate("/offer");
            }}
            className="d-block w-100"
            src="https://static.wixstatic.com/media/d1a664_2635f8371bce46d4996f753b6ec7fb04~mv2.jpg/v1/fill/w_1000,h_367,al_c,q_85,enc_auto/d1a664_2635f8371bce46d4996f753b6ec7fb04~mv2.jpg"
            alt="Sixth slide"
          />

          <Carousel.Caption>
            <h3>Gold Krom Kutu Harf</h3>
            <p>Tıkla ve Hemen Teklif Al</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  };

  return (
    <div className="landing-screen-template">
      <div className="container">{RenderCarouselItemList()}</div>
    </div>
  );
};

export default BoxLetterProductionScreen;
