import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { context } from "../context/ContextProvider";
import { FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { BsTextIndentLeft } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { AiOutlineMenu } from "react-icons/ai";
import { BiUpload } from "react-icons/bi";
import { Auth } from "aws-amplify";

const Navbar = (props) => {
  const navigate = useNavigate();
  const { boardingCompleted, userInformation } = useContext(context);
  const [currentUser, setCurrentUser] = useState(null);
  const [showUserOptionList, setShowUserOptionList] = useState(false);

  useEffect(() => {
    const retrieveAndSetCurrentUser = async () => {
      const foundCurrentUser = await Auth.currentAuthenticatedUser();
      setCurrentUser(foundCurrentUser);
    };
    if (boardingCompleted) {
      retrieveAndSetCurrentUser();
    }
  }, [boardingCompleted]);

  return (
    <div className="container">
      <nav
        className="navbar navbar-expand-lg navbar-base-template"
        style={{ fontSize: 14 }}
      >
        <a
          className="navbar-brand"
          style={{
            cursor: "pointer",
            textAlign: "center",
            marginRight: 10,
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <img
            src="https://net-uretim-files.s3.eu-west-1.amazonaws.com/logo/net_uretim_logo.jpeg"
            alt="logo"
            width={160}
            height={34}
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <AiOutlineMenu size={30} />
          {/* <span className="navbar-toggler-icon"></span> */}
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto w-100">
            <li className="nav-item mt-auto">
              <a
                className="nav-link navbar-item"
                onClick={() => {
                  navigate("/offer", { state: "BOX_LETTER" });
                }}
              >
                Kutu Harf İmalatı
              </a>
            </li>
            <li className="nav-item mt-auto">
              <a
                className="nav-link navbar-item"
                onClick={() => {
                  navigate("/offer", { state: "3D_PRINTING" });
                }}
              >
                3D Baskı
              </a>
            </li>
            <li className="nav-item mt-auto">
              <a
                className="nav-link navbar-item"
                onClick={() => {
                  navigate("/offer", { state: "LASER_CUTTING" });
                }}
              >
                Lazer Kesim
              </a>
            </li>
            <li className="nav-item mt-auto">
              <a
                className="nav-link navbar-item"
                onClick={() => {
                  navigate("/offer", { state: "CNC_CUTTING" });
                }}
              >
                CNC Kesim
              </a>
            </li>

            <li className="nav-item mt-auto">
              <a
                className="nav-link navbar-offer-item"
                onClick={() => {
                  navigate("/offer");
                }}
              >
                Hemen Teklif Al
                <BiUpload size={20} style={{ marginLeft: 10 }} />
              </a>
            </li>

            {currentUser ? (
              <li
                className="nav-item dropdown"
                style={{ marginTop: "auto", marginLeft: "auto" }}
              >
                <a
                  className="nav-link dropdown-toggle navbar-item"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Hoş Geldiniz,
                  <br />
                  {userInformation ? userInformation.name : null}{" "}
                  {userInformation ? userInformation.lastName : null}
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                    style={{ marginTop: 10 }}
                  >
                    <a
                      className="dropdown-item d-flex flex-row"
                      style={{ fontSize: 14 }}
                      onMouseDown={(e) => {
                        navigate("/settings");
                      }}
                    >
                      <IoSettingsOutline
                        size={20}
                        style={{ marginRight: 10 }}
                      />
                      Ayarlar
                    </a>
                    <a
                      className="dropdown-item d-flex flex-row"
                      style={{ fontSize: 14 }}
                      onMouseDown={() => {
                        navigate("/my-orders");
                      }}
                    >
                      <BsTextIndentLeft size={20} style={{ marginRight: 10 }} />
                      Siparişlerim
                    </a>
                    <div className="dropdown-divider"></div>
                    <a
                      className="dropdown-item d-flex flex-row"
                      onMouseDown={props.handleSignOut}
                      style={{ fontSize: 14 }}
                    >
                      <FaSignOutAlt size={20} style={{ marginRight: 10 }} />
                      Çıkış
                    </a>
                  </div>
                </a>
              </li>
            ) : (
              <li className="nav-item mt-auto" style={{ marginLeft: "auto" }}>
                <a
                  className="nav-link navbar-item"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Giriş Yapın
                  <FaUserCircle size={20} style={{ marginLeft: 10 }} />
                </a>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
