import { Auth } from "aws-amplify";
import { useContext, useEffect, useState } from "react";
import { context } from "../../context/ContextProvider";
import InputGroup from "../InputGroup";
import {
  patchUserInformation,
  queryUserListByEmail,
} from "../../requests/userRequests";
import { toast } from "react-toastify";

const UserInformation = () => {
  const appContext = useContext(context);
  const { userInformation, setUserInformation } = appContext;
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    const retrieveAndSetUserInformation = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      const foundUserList = await queryUserListByEmail(currentUser.username);
      if (foundUserList && foundUserList.length > 0) {
        setUserInformation(foundUserList[0]);
      }
    };
    if (updated) {
      retrieveAndSetUserInformation();
      setUpdated(false);
    }
  }, [updated]);

  const handleUpdateUserInformation = async () => {
    const email = document.getElementById("email").value.trim();
    const name = document.getElementById("name").value.trim();
    const lastName = document.getElementById("lastName").value.trim();
    let patchUser = {};
    if (userInformation.type === "INDIVIDUAL") {
      if (!(name && lastName)) {
        toast("Lütfen bütün alanları doldurun");
        return;
      }
      patchUser = {
        email,
        name,
        lastName,
      };
    } else if (userInformation.type === "CORPORATE") {
      const phoneNumber = document.getElementById("phoneNumber").value.trim();
      const corporateName = document
        .getElementById("corporateName")
        .value.trim();
      const taxOffice = document.getElementById("taxOffice").value.trim();
      const taxNumber = document.getElementById("taxNumber").value.trim();
      if (!(corporateName && taxOffice && taxNumber && phoneNumber)) {
        toast("Lütfen bütün alanları doldurun");
        return;
      }
      patchUser = {
        email,
        name,
        lastName,
        phoneNumber,
        corporateName,
        taxOffice,
        taxNumber,
      };
    }

    const response = await patchUserInformation(userInformation.id, patchUser);
    if (response) {
      console.log(response);
      toast("Kullanıcı bilgisi başarıyla güncellendi");
      setUpdated(true);
    }
  };

  const RenderCorporateUserContent = () => {
    return (
      <div>
        <div className="m-2">
          <InputGroup
            id="email"
            label="E-Mail"
            type="text"
            placeholder="E-mail adresinizi girin"
            defaultValue={userInformation.email}
            disabled={true}
          />
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="col-md-4">
            <div className="m-2">
              <InputGroup
                id="name"
                label="İsim"
                type="text"
                defaultValue={userInformation.name}
                placeholder="İsminizi girin"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="m-2">
              <InputGroup
                id="lastName"
                label="Soyisim"
                type="text"
                defaultValue={userInformation.lastName}
                placeholder="Soyisminizi girin"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div style={{ position: "relative" }}>
              <div
                style={{
                  bottom: 7,
                  left: 15,
                  position: "absolute",
                }}
              >
                +90
              </div>
              <div className="m-2">
                <InputGroup
                  id="phoneNumber"
                  label="İrtibat Numarası"
                  type="number"
                  placeholder="İrtibat Numarası"
                  defaultValue={userInformation.phoneNumber}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="col-md-4">
            <div className="m-2">
              <InputGroup
                id="corporateName"
                label="Şirket İsmi"
                type="text"
                defaultValue={userInformation.corporateName}
                placeholder="Şirket isminizi girin"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="m-2">
              <InputGroup
                id="taxOffice"
                label="Vergi Dairesi"
                type="text"
                defaultValue={userInformation.taxOffice}
                placeholder="Vergi dairenizi girin"
                disabled={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="m-2">
              <InputGroup
                id="taxNumber"
                label="Vergi Numarası"
                type="number"
                defaultValue={userInformation.taxNumber}
                placeholder="Vergi numaranızı girin"
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="col-md-4">
            <button
              className="primary-button"
              style={{ width: "100%" }}
              onClick={handleUpdateUserInformation}
            >
              Güncelle
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderIndividualUserContent = () => {
    return (
      <div>
        <div className="m-2">
          <InputGroup
            id="email"
            label="E-Mail"
            type="text"
            placeholder="E-mail adresinizi girin"
            defaultValue={userInformation.email}
            disabled={true}
          />
        </div>
        <div className="d-flex flex-row mt-2">
          <div className="col-md-6">
            <div className="m-2">
              <InputGroup
                id="name"
                label="İsim"
                type="text"
                defaultValue={userInformation.name}
                placeholder="İsminizi girin"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="m-2">
              <InputGroup
                id="lastName"
                label="Soyisim"
                type="text"
                defaultValue={userInformation.lastName}
                placeholder="Soyisminizi girin"
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-4">
          <div className="col-md-4">
            <button
              className="primary-button"
              style={{ width: "100%" }}
              onClick={handleUpdateUserInformation}
            >
              Güncelle
            </button>
          </div>
        </div>
      </div>
    );
  };

  const RenderMainContent = () => {
    if (userInformation) {
      if (userInformation.type === "INDIVIDUAL") {
        return RenderIndividualUserContent();
      } else if (userInformation.type === "CORPORATE") {
        return RenderCorporateUserContent();
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return RenderMainContent();
};

export default UserInformation;
