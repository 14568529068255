import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsPlusCircleDotted } from "react-icons/bs";
import { FaCheck, FaPlus } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import { createAddress, deleteAddress } from "../../requests/addressRequests";
import InputGroup from "../InputGroup";

const ShippingBillingInformation = (props) => {
  const {
    selectedBillingId,
    setSelectedBillingId,
    selectedBilling,
    selectedShippingId,
    setSelectedShippingId,
    selectedShipping,
    shippingList,
    billingList,
    setAddressUpdated,
  } = props;
  const [showCreateBillingModal, setShowCreateBillingModal] = useState(false);
  const [showCreateShippingModal, setShowCreateShippingModal] = useState(false);
  const [shippingPageState, setShippingPageState] = useState(
    selectedShippingId !== null ? 0 : 1
  );
  const [billingPageState, setBillingPageState] = useState(
    selectedBillingId !== null ? 0 : 1
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [showDeleteBillingModal, setShowDeleteBillingModal] = useState(false);
  const [showDeleteShippingModal, setShowDeleteShippingModal] = useState(false);

  const handleCreateShipping = async () => {
    const shippingHeading = document
      .getElementById("shipping_heading")
      .value.trim();
    const shippingFullName = document
      .getElementById("shipping_full_name")
      .value.trim();
    const shippingCountry = document
      .getElementById("shipping_country")
      .value.trim();
    const shippingCity = document.getElementById("shipping_city").value.trim();
    const shippingState = document
      .getElementById("shipping_state")
      .value.trim();

    const shippingAddress = document
      .getElementById("shipping_address")
      .value.trim();
    const shippingPhoneNumber = document
      .getElementById("shipping_phone_number")
      .value.trim();
    const shippingPostalCode = document
      .getElementById("shipping_postal_code")
      .value.trim();
    const shippingAddressType = document
      .getElementById("shipping_address_type")
      .value.trim();
    const shippingCorporateName = document.getElementById(
      "shipping_corporate_name"
    )
      ? document.getElementById("shipping_corporate_name").value.trim()
      : null;
    const shippingTaxOffice = document.getElementById("shipping_tax_office")
      ? document.getElementById("shipping_tax_office").value.trim()
      : null;
    const shippingTaxNumber = document.getElementById("shipping_tax_number")
      ? document.getElementById("shipping_tax_number").value.trim()
      : null;
    if (!shippingHeading) {
      toast("Lütfen adres başlığı ekleyin");
      return;
    } else if (!shippingFullName) {
      toast("Lütfen İsim Soyisim ekleyin");
      return;
    } else if (!shippingCountry) {
      toast("Lütfen ülke ekleyin");
      return;
    } else if (!shippingAddress) {
      toast("Lütfen adres ekleyin");
      return;
    } else if (!shippingPhoneNumber) {
      toast("Lütfen irtibat numarası ekleyin");
      return;
    }
    const currentUser = await Auth.currentUserInfo();
    const address = {
      id: new Date().getTime(),
      username: currentUser.username,
      heading: shippingHeading,
      fullName: shippingFullName,
      country: shippingCountry,
      city: shippingCity,
      state: shippingState,
      address: shippingAddress,
      phoneNumber: shippingPhoneNumber,
      postalCode: shippingPostalCode,
      addressType: shippingAddressType,
      corporateName: shippingCorporateName,
      taxOffice: shippingTaxOffice,
      taxNumber: shippingTaxNumber,
      type: "SHIPPING",
    };
    const response = await createAddress(address);
    if (response.status === 201) {
      setAddressUpdated(true);
      setShowCreateShippingModal(false);
      toast("Teslimat adresi başarı ile yaratıldı");
    } else {
      toast("Teslimat adresi yaratırken bir problem ile karşılaşıldı");
    }
  };

  const RenderCreateShippingModal = () => {
    const [addressType, setAddressType] = useState("INDIVIDUAL");
    return (
      <Modal
        show={showCreateShippingModal}
        onHide={() => {
          setShowCreateShippingModal(false);
        }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Kargo Adresi Yarat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <InputGroup
                  id="shipping_heading"
                  label="Adres Başlığı"
                  type="text"
                  placeholder="Adres başlığı belirtin"
                />
              </div>
              <div className="col-md-8">
                <InputGroup
                  id="shipping_full_name"
                  label="Yetkili Kişi"
                  type="text"
                  placeholder="Yetkili kişi belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <InputGroup
                  id="shipping_country"
                  label="Ülke"
                  type="text"
                  placeholder="Ülke belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="shipping_city"
                  label="Şehir"
                  type="text"
                  placeholder="Şehir belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="shipping_state"
                  label="Eyalet"
                  type="text"
                  placeholder="Eyalet belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <InputGroup
                  id="shipping_address"
                  label="Sokak Adresi"
                  type="text"
                  placeholder="Tam adresinizi belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 7,
                      left: 15,
                    }}
                  >
                    +90
                  </div>
                  <InputGroup
                    id="shipping_phone_number"
                    label="İrtibat Numarası"
                    type="number"
                    placeholder="İrtibat Numarası"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="shipping_postal_code"
                  label="Posta Kodu"
                  type="text"
                  placeholder="Posta kodu belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="shipping_address_type"
                  label="Adres Tipi"
                  type="select"
                  optionList={[
                    { text: "Bireysel", value: "INDIVIDUAL" },
                    { text: "Kurumsal", value: "CORPORATE" },
                  ]}
                  defaultValue={addressType}
                  onChange={(e) => {
                    setAddressType(e.target.value);
                  }}
                />
              </div>
            </div>
            {addressType === "CORPORATE" ? (
              <div className="row mt-2">
                <div className="col-md-4">
                  <InputGroup
                    id="shipping_corporate_name"
                    label="Kurum Adı"
                    type="text"
                    placeholder="Kurum Adı belirtin"
                  />
                </div>
                <div className="col-md-4">
                  <InputGroup
                    id="shipping_tax_office"
                    label="Vergi Dairesi"
                    type="text"
                    placeholder="Vergi Dairesi belirtin"
                  />
                </div>
                <div className="col-md-4">
                  <InputGroup
                    id="shipping_tax_number"
                    label="Vergi Numarası"
                    type="number"
                    placeholder="Vergi Numarası belirtin"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <button className="primary-button" onClick={handleCreateShipping}>
              Ekle
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDeleteShipping = async () => {
    const currentUser = await Auth.currentUserInfo();
    const response = await deleteAddress(selectedItem.id, currentUser.username);
    if (response) {
      if (selectedItem.id === selectedShippingId) {
        setSelectedShippingId(null);
      }
      toast(`${selectedItem.heading} isimli teslimat adresi başarıyla silindi`);
      setShowDeleteBillingModal(false);
      setAddressUpdated(true);
    }
  };

  const RenderDeleteShippingModal = () => {
    return (
      <Modal
        show={showDeleteShippingModal}
        onHide={() => {
          setShowDeleteShippingModal(false);
        }}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Teslimat Adresi Kaldır</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span style={{ fontWeight: "bold" }}>
              {selectedItem ? selectedItem.heading : null}
            </span>{" "}
            isimli teslimat adresini kaldırmak istediğinize emin misiniz
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <button
              className="secondary-button"
              onClick={() => {
                setShowDeleteShippingModal(false);
              }}
            >
              Geri Dön
            </button>
            <button className="primary-button" onClick={handleDeleteShipping}>
              Sil
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleCreateBilling = async () => {
    const billingHeading = document
      .getElementById("billing_heading")
      .value.trim();
    const billingFullName = document
      .getElementById("billing_full_name")
      .value.trim();
    const billingCountry = document
      .getElementById("billing_country")
      .value.trim();
    const billingCity = document.getElementById("billing_city").value.trim();
    const billingState = document.getElementById("billing_state").value.trim();

    const billingAddress = document
      .getElementById("billing_address")
      .value.trim();
    const billingPhoneNumber = document
      .getElementById("billing_phone_number")
      .value.trim();
    const billingPostalCode = document
      .getElementById("billing_postal_code")
      .value.trim();
    const billingAddressType = document
      .getElementById("billing_address_type")
      .value.trim();
    const billingCorporateName = document.getElementById(
      "billing_corporate_name"
    )
      ? document.getElementById("billing_corporate_name").value.trim()
      : null;
    const billingTaxOffice = document.getElementById("billing_tax_office")
      ? document.getElementById("billing_tax_office").value.trim()
      : null;
    const billingTaxNumber = document.getElementById("billing_tax_number")
      ? document.getElementById("billing_tax_number").value.trim()
      : null;
    if (!billingHeading) {
      toast("Lütfen adres başlığı ekleyin");
      return;
    } else if (!billingFullName) {
      toast("Lütfen İsim Soyisim ekleyin");
      return;
    } else if (!billingCountry) {
      toast("Lütfen ülke ekleyin");
      return;
    } else if (!billingAddress) {
      toast("Lütfen adres ekleyin");
      return;
    } else if (!billingPhoneNumber) {
      toast("Lütfen irtibat numarası ekleyin");
      return;
    }

    const currentUser = await Auth.currentUserInfo();
    const address = {
      id: new Date().getTime(),
      username: currentUser.username,
      heading: billingHeading,
      fullName: billingFullName,
      country: billingCountry,
      city: billingCity,
      state: billingState,
      address: billingAddress,
      phoneNumber: billingPhoneNumber,
      postalCode: billingPostalCode,
      addressType: billingAddressType,
      corporateName: billingCorporateName,
      taxOffice: billingTaxOffice,
      taxNumber: billingTaxNumber,
      type: "BILLING",
    };
    const response = await createAddress(address);
    if (response.status === 201) {
      setAddressUpdated(true);
      setShowCreateBillingModal(false);
      toast("Fatura adresi başarı ile yaratıldı");
    } else {
      toast("Fatura adresi yaratırken bir problem ile karşılaşıldı");
    }
  };

  const RenderCreateBillingModal = () => {
    const [addressType, setAddressType] = useState("INDIVIDUAL");
    return (
      <Modal
        show={showCreateBillingModal}
        onHide={() => {
          setShowCreateBillingModal(false);
        }}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fatura Adresi Yarat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <InputGroup
                  id="billing_heading"
                  label="Adres Başlığı"
                  type="text"
                  placeholder="Adres başlığı belirtin"
                />
              </div>
              <div className="col-md-8">
                <InputGroup
                  id="billing_full_name"
                  label="Yetkili Kişi"
                  type="text"
                  placeholder="Yetkili kişi belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <InputGroup
                  id="billing_country"
                  label="Ülke"
                  type="text"
                  placeholder="Ülke belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="billing_city"
                  label="Şehir"
                  type="text"
                  placeholder="Şehir belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="billing_state"
                  label="Eyalet"
                  type="text"
                  placeholder="Eyalet belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-12">
                <InputGroup
                  id="billing_address"
                  label="Sokak Adresi"
                  type="text"
                  placeholder="Tam adresinizi belirtin"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4">
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      bottom: 7,
                      left: 15,
                    }}
                  >
                    +90
                  </div>
                  <InputGroup
                    id="billing_phone_number"
                    label="İrtibat Numarası"
                    type="number"
                    placeholder="İrtibat Numarası"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="billing_postal_code"
                  label="Posta Kodu"
                  type="text"
                  placeholder="Posta kodu belirtin"
                />
              </div>
              <div className="col-md-4">
                <InputGroup
                  id="billing_address_type"
                  label="Adres Tipi"
                  type="select"
                  optionList={[
                    { text: "Bireysel", value: "INDIVIDUAL" },
                    { text: "Kurumsal", value: "CORPORATE" },
                  ]}
                  defaultValue={addressType}
                  onChange={(e) => {
                    setAddressType(e.target.value);
                  }}
                />
              </div>
            </div>
            {addressType === "CORPORATE" ? (
              <div className="row mt-2">
                <div className="col-md-4">
                  <InputGroup
                    id="billing_corporate_name"
                    label="Kurum Adı"
                    type="text"
                    placeholder="Kurum Adı belirtin"
                  />
                </div>
                <div className="col-md-4">
                  <InputGroup
                    id="billing_tax_office"
                    label="Vergi Dairesi"
                    type="text"
                    placeholder="Vergi Dairesi belirtin"
                  />
                </div>
                <div className="col-md-4">
                  <InputGroup
                    id="billing_tax_number"
                    label="Vergi Numarası"
                    type="text"
                    placeholder="Vergi Numarası belirtin"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-column justify-content-end align-items-center">
            <button className="primary-button" onClick={handleCreateBilling}>
              Ekle
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDeleteBilling = async () => {
    const currentUser = await Auth.currentUserInfo();
    const response = await deleteAddress(selectedItem.id, currentUser.username);
    if (response) {
      if (selectedItem.id === selectedBillingId) {
        setSelectedBillingId(null);
      }
      toast(`${selectedItem.heading} isimli fatura adresi başarıyla silindi`);
      setShowDeleteBillingModal(false);
      setAddressUpdated(true);
    }
  };

  const RenderDeleteBillingModal = () => {
    return (
      <Modal
        show={showDeleteBillingModal}
        onHide={() => {
          setShowDeleteBillingModal(false);
        }}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fatura Adresi Kaldır</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <span style={{ fontWeight: "bold" }}>
              {selectedItem ? selectedItem.heading : null}
            </span>{" "}
            isimli fatura adresini kaldırmak istediğinize emin misiniz
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <button
              className="secondary-button"
              onClick={() => {
                setShowDeleteBillingModal(false);
              }}
            >
              Geri Dön
            </button>
            <button className="primary-button" onClick={handleDeleteBilling}>
              Sil
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const RenderShippingContent = () => {
    if (shippingPageState === 0) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-start"
          style={{
            border: "1px solid lightgray",
            padding: 10,
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-row align-items-center">
              <FaCheck size={40} color="green" style={{ marginRight: 20 }} />
              {selectedShippingId === 0 ? (
                <div className="d-flex flex-column justify-content-center">
                  <div style={{ fontWeight: "bold" }}>Elden Teslimat</div>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <div style={{ fontWeight: "bold" }}>
                    {selectedShipping ? selectedShipping.heading : null}
                  </div>
                  <div style={{ fontSize: 14 }}>
                    {selectedShipping ? selectedShipping.address : null}
                  </div>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <div
                style={{
                  color: "#ff6700",
                  marginLeft: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShippingPageState(1);
                }}
              >
                Değiştir
              </div>
            </div>
          </div>
        </div>
      );
    } else if (shippingPageState === 1) {
      return (
        <React.Fragment>
          <div
            className="d-flex flex-column justify-content-center align-items-start"
            style={{
              border: "1px solid lightgray",
              padding: 10,
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateShippingModal(true);
            }}
          >
            <div className="d-flex flex-row align-items-center">
              <BsPlusCircleDotted
                size={40}
                color="#ff6700"
                style={{ marginRight: 20 }}
              />
              <div style={{ color: "#ff6700" }}>Teslimat Adresi Ekle</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div
                className={`d-flex flex-column justify-content-start align-items-start offer-base-information-selection-billing-box ${
                  selectedShippingId === 0
                    ? "offer-base-information-selection-billing-box-selected"
                    : null
                }`}
                onClick={() => {
                  setSelectedShippingId(0);
                  setShippingPageState(0);
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 10,
                  }}
                >
                  İş Yeri Teslimat
                </div>
                <div style={{ fontSize: 12 }}>
                  Siparişinizi iş yerimizden teslim almak için bu seçeneği
                  seçebilirsiniz
                </div>
              </div>
            </div>
            {shippingList.map((shipping, shippingIndex) => (
              <div key={shippingIndex} className="col-md-3">
                <div
                  className={`offer-base-information-selection-billing-box ${
                    selectedShippingId === shipping.id
                      ? "offer-base-information-selection-billing-box-selected"
                      : null
                  }`}
                  onClick={() => {
                    setSelectedShippingId(shipping.id);
                    setShippingPageState(0);
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: 10,
                    }}
                  >
                    {shipping.heading}
                  </div>
                  <div style={{ fontSize: 12 }}>{shipping.address}</div>
                  <div
                    style={{ position: "absolute", top: 5, right: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteShippingModal(true);
                      setSelectedItem(shipping);
                    }}
                  >
                    <ImCross size={14} color="#fe4a55" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    }
  };

  const RenderBillingContent = () => {
    if (billingPageState === 0) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-start"
          style={{
            border: "1px solid lightgray",
            padding: 10,
            borderRadius: 5,
            cursor: "pointer",
          }}
        >
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex flex-row align-items-center">
              <FaCheck size={40} color="green" style={{ marginRight: 20 }} />
              <div className="d-flex flex-column justify-content-center">
                <div style={{ fontWeight: "bold" }}>
                  {selectedBilling ? selectedBilling.heading : null}
                </div>
                <div style={{ fontSize: 14 }}>
                  {selectedBilling ? selectedBilling.address : null}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div
                style={{
                  color: "#ff6700",
                  marginLeft: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setBillingPageState(1);
                }}
              >
                Değiştir
              </div>
            </div>
          </div>
        </div>
      );
    } else if (billingPageState === 1) {
      return (
        <React.Fragment>
          <div
            className="d-flex flex-column justify-content-center align-items-start"
            style={{
              border: "1px solid lightgray",
              padding: 10,
              borderRadius: 5,
              cursor: "pointer",
            }}
            onClick={() => {
              setShowCreateBillingModal(true);
            }}
          >
            <div className="d-flex flex-row align-items-center">
              <BsPlusCircleDotted
                size={40}
                color="#ff6700"
                style={{ marginRight: 20 }}
              />
              <div style={{ color: "#ff6700" }}>Fatura Bilgisi Ekle</div>
            </div>
          </div>
          <div className="row">
            {billingList.map((billing, billingIndex) => (
              <div key={billingIndex} className="col-md-3">
                <div
                  className={`offer-base-information-selection-billing-box ${
                    selectedBillingId === billing.id
                      ? "offer-base-information-selection-billing-box-selected"
                      : null
                  }`}
                  onClick={() => {
                    setSelectedBillingId(billing.id);
                    setBillingPageState(0);
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: 10,
                    }}
                  >
                    {billing.heading}
                  </div>
                  <div style={{ fontSize: 12 }}>{billing.address}</div>
                  <div
                    style={{ position: "absolute", top: 5, right: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteBillingModal(true);
                      setSelectedItem(billing);
                    }}
                  >
                    <ImCross size={14} color="#fe4a55" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      );
    }
  };

  return (
    <React.Fragment>
      <RenderCreateShippingModal />
      <RenderDeleteShippingModal />
      <RenderCreateBillingModal />
      <RenderDeleteBillingModal />
      <div className="offer-base-information-selection-base">
        <div className="col-md-12 mt-2 mb-2">
          <div className="offer-base-information-selection-base-header mb-2">
            Teslimat Adresi
          </div>
          {RenderShippingContent()}
        </div>
        <div className="col-md-12" style={{ marginTop: 40 }}>
          <div className="offer-base-information-selection-base-header mb-2">
            Fatura Bilgileri
          </div>
          {RenderBillingContent()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShippingBillingInformation;
