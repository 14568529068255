import axios from "axios";
import config from "../config/config.json";

export const queryAddressList = async (username) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.addresses.context}${config.addresses.query.context}`,
      {
        username,
      }
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const createAddress = async (address) => {
  try {
    const response = await axios.post(
      `${config.baseUrl}${config.addresses.context}`,
      address
    );
    return response;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const deleteAddress = async (id, username) => {
  try {
    const response = await axios.delete(
      `${config.baseUrl}${config.addresses.context}/${id}/${username}`
    );
    return response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};
