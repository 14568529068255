const CncCuttingCartDetail = (props) => {
  const { parsedFileKey, parsedFile } = props;

  const RenderSpecificWoodSideBandDepth = () => {
    if (parsedFile.sideBandDepth) {
      return (
        <div>
          <div style={{ fontWeight: "bold" }}>Yan Bant</div>
          <div>{JSON.parse(parsedFile.sideBandDepth).depthName}</div>
        </div>
      );
    }
    return null;
  };

  const RenderWoodCartInformation = () => {
    return (
      <div className="col">
        <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Malzeme Grubu</div>
            <div>Ahşap</div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center col">
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme</div>
            <div>{JSON.parse(parsedFile.cuttingType).name}</div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme Kalınlığı</div>
            <div>
              {JSON.parse(parsedFile.materialThickness).thickness}{" "}
              {JSON.parse(parsedFile.materialThickness).thicknessUnit}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Renk</div>
            <div>{JSON.parse(parsedFile.materialColor).name}</div>
          </div>
          {RenderSpecificWoodSideBandDepth()}
        </div>
      </div>
    );
  };

  const RenderPlasticCartInformation = () => {
    return (
      <div className="col">
        <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Malzeme Grubu</div>
            <div>Plastik</div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center col">
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme</div>
            <div>{JSON.parse(parsedFile.cuttingType).name}</div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme Kalınlığı</div>
            <div>
              {JSON.parse(parsedFile.materialThickness).thickness}{" "}
              {JSON.parse(parsedFile.materialThickness).thicknessUnit}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Renk</div>
            <div>{JSON.parse(parsedFile.materialColor).name}</div>
          </div>
        </div>
      </div>
    );
  };

  const RenderMetalCartInformation = () => {
    return (
      <div className="col">
        <div className="d-flex flex-row" style={{ marginBottom: 20 }}>
          <div className="col-md-6">
            <div style={{ fontWeight: "bold" }}>Malzeme Grubu</div>
            <div>Metal</div>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-around align-items-center col">
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme</div>
            <div>{JSON.parse(parsedFile.cuttingType).name}</div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Malzeme Kalınlığı</div>
            <div>
              {JSON.parse(parsedFile.materialThickness).thickness}{" "}
              {JSON.parse(parsedFile.materialThickness).thicknessUnit}
            </div>
          </div>
          <div>
            <div style={{ fontWeight: "bold" }}>Renk</div>
            <div>{JSON.parse(parsedFile.materialColor).name}</div>
          </div>
        </div>
      </div>
    );
  };

  const RenderCartInformation = () => {
    const baseMaterialValue = parsedFile.baseMaterial;
    if (baseMaterialValue) {
      const baseMaterialId = parseInt(baseMaterialValue);
      if (baseMaterialId === 3) {
        return RenderWoodCartInformation();
      } else if (baseMaterialId === 4) {
        return RenderPlasticCartInformation();
      } else if (baseMaterialId === 5) {
        return RenderMetalCartInformation();
      } else {
        return null;
      }
    }
  };

  return (
    <div className="d-flex flex-row justify-content-start align-items-center text-center mt-4">
      <div id={`canvas_${parsedFileKey}`} className="col-md-2" />
      {RenderCartInformation()}
    </div>
  );
};

export default CncCuttingCartDetail;
