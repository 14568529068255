const DeliveryContitionsScreen = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Teslimat Koşulları
        </div>
        <div style={{ marginTop: 30 }}>
          Siparişleriniz, banka onayı alındıktan sonra iş hacmine göre imalat
          koşulları göz önüne alınarak en geç 7 iş günü (Pazartesi-Cuma)
          içerisinde (Saat 16.00'a kadar) kargoya teslim edilir. Haftasonu
          kargolama ile ilgili son kargo çıkışı saati Cumartesi için 11.00' dır.
          İşletmemiz Pazar günü çalışmadığı için pazar günleri işlem
          yapılmamaktadır.
        </div>
        <div style={{ marginTop: 30 }}>
          Özel üretim ürünlerin teslim süreleri imalat zamanına göre farklılık
          göstermektedir. Teslim süresi ile ilgili detaylı bilgi için Firmamız
          ile iletişime geçmeniz gerekmektedir.
        </div>
        <div style={{ marginTop: 30 }}>
          Tarafımızdan kaynaklanan bir aksilik olması halinde ise size üyelik
          bilgilerinizden yola çıkılarak haber verilecektir. Bu yüzden üyelik
          bilgilerinizin eksiksiz ve doğru olması önemlidir. Bayram ve tatil
          günlerinde teslimat yapılmamaktadır. Seçtiğiniz ürünlerin tamamı
          anlaşmalı olduğumuz kargo şirketleri tarafından kargo garantisi ile
          size teslim edilecektir.
        </div>
        <div style={{ marginTop: 30 }}>
          Satın aldığınız ürünler bir teyit e-posta'sı ile tarafınıza
          bildirilecektir. Seçtiğiniz ürünlerden herhangi birinin stokta mevcut
          olmaması durumunda konu ile ilgili bir e-posta size yollanacak ve
          ürünün ilk stoklara gireceği tarih tarafınıza bildirilecektir.
          Neturetim online alışveriş sitesidir. Aynı anda birden çok kullanıcıya
          alışveriş yapma imkanı tanır. Enderde olsa tüketicinin aynı ürünü
          alması söz konusudur ve ürün stoklarda tükenmektedir.
        </div>
        <div style={{ marginTop: 30 }}>
          Bu durumda;
          <div style={{ marginLeft: 20 }}>
            Ödemesini internet üzerinden yaptınız ürün eğer stoklarmızda
            kalmamış ise en az 1 (Bir) en fazla 3 (Üç) gün bekleme süresi
            vardır. Ürün bu tarihleri arasında tüketiciye verilemez ise yaptığı
            ödeme kendisine iade edilir.
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryContitionsScreen;
