const DistantSalesAgreement = () => {
  return (
    <div className="static-screen-template">
      <div style={{ width: "60%", margin: "auto" }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: 50, fontWeight: "bold", fontSize: 30 }}
        >
          Mesafeli Satış Sözleşmesi
        </div>
        <div style={{ marginTop: 30 }}>
          İşbu sözleşme 13.06.2003 tarih ve 25137 sayılı Resmi Gazetede
          yayınlanan Mesafeli Sözleşmeler Uygulama Usul ve Esasları Hakkında
          Yönetmelik gereği internet üzerinden gerçekleştiren satışlar için
          sözleşme yapılması zorunluluğuna istinaden düzenlenmiş olup, maddeler
          halinde aşağıdaki gibidir.
        </div>

        <div style={{ marginTop: 30, fontWeight: "bold" }}>MADDE 1 – KONU</div>
        <div style={{ marginTop: 30 }}>
          İşbu sözleşmenin konusu, SATICI nın, ALICI ya satışını yaptığı,
          aşağıda nitelikleri ve satış fiyatı belirtilen ürünün satışı ve
          teslimi ile ilgili olarak 4077 sayılı Tüketicilerin Korunması
          Hakkındaki Kanun-Mesafeli Sözleşmeleri Uygulama Esas ve Usulleri
          Hakkında Yönetmelik hükümleri gereğince tarafların hak ve
          yükümlülüklerinin kapsamaktadır.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          MADDE 2.1 – SATICI BİLGİLERİ
        </div>
        <div style={{ marginTop: 30 }}>Net Üretim</div>
        <div>
          İvedik OSB mah. 1122.cadde Prestij İş merkezi No:40/16 Yenimahalle /
          ANKARA
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          MADDE 2.2 – ALICI BİLGİLERİ
        </div>
        <div style={{ marginTop: 30 }}>
          Müşteri olarak www.neturetim.com alışveriş sitesine üye olan kişi.
        </div>
        <div>
          Üye olurken kullanılan adres ve iletişim bilgileri esas alınır.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          MADDE 3 – SÖZLEŞME KONUSU ÜRÜN BİLGİLERİ
        </div>
        <div style={{ marginTop: 30 }}>
          Malın / Ürünün / Hizmetin türü, miktarı, marka/modeli, rengi, adedi,
          satış bedeli, ödeme şekli, siparişin sonlandığı andaki bilgilerden
          oluşmaktadır.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          MADDE 4 – GENEL HÜKÜMLER
        </div>
        <div style={{ marginTop: 30 }}>
          4.1 – ALICI, Madde 3 te belirtilen sözleşme konusu ürün veya ürünlerin
          temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin
          tüm ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda
          gerekli teyidi verdiğini beyan eder.
        </div>
        <div style={{ marginTop: 30 }}>
          4.2 – Sözleşme konusu ürün veya ürünler, yasal 30 günlük süreyi
          aşmamak koşulu ile her bir ürün için ALICI nın yerleşim yerinin
          uzaklığına bağlı olarak ön bilgiler içinde açıklanan süre içinde ALICI
          veya gösterdiği adresteki kişi/kuruluşa teslim edilir. Bu süre ALICI
          ya daha önce bildirilmek kaydıyla en fazla 10 gün daha uzatılabilir.
        </div>
        <div style={{ marginTop: 30 }}>
          4.3 – Sözleşme konusu ürün, ALICI dan başka bir kişi/kuruluşa teslim
          edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul
          etmemesinden SATICI sorumlu tutulamaz.
        </div>
        <div style={{ marginTop: 30 }}>
          4.4 – SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte
          belirtilen niteliklere uygun teslim edilmesinden sorumludur.
        </div>
        <div style={{ marginTop: 30 }}>
          4.5 – Sözleşme konusu ürünün teslimatı için işbu sözleşmenin imzalı
          nüshasının SATICI ya ulaştırılmış olması ve bedelinin ALICI nın tercih
          ettigi ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle
          ürün bedeli ödenmez veya banka kayıtlarında iptal edilir ise, SATICI
          ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.
        </div>
        <div style={{ marginTop: 30 }}>
          4.6- Ürünün tesliminden sonra ALICI ya ait kredi kartının ALICI nın
          kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya
          hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans
          kuruluşun ürün bedelini SATICI ya ödememesi halinde, ALICI nın
          kendisine teslim edilmiş olması kaydıyla ürünün 3 gün içinde SATICI ya
          gönderilmesi zorunludur. Bu takdirde nakliye giderleri ALICI ya
          aittir.
        </div>
        <div style={{ marginTop: 30 }}>
          4.7- SATICI mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti,
          ulaşımın kesilmesi gibi olağanüstü durumlar nedeni ile sözleşme konusu
          ürünü süresi içinde teslim edemez ise, durumu ALICI ya bildirmekle
          yükümlüdür. Bu takdirde ALICI siparişin iptal edilmesini, sözleşme
          konusu ürünün varsa emsali ile değiştirilmesini, ve/veya teslimat
          süresinin engelleyici durumun ortadan kalkmasına kadar ertelenmesi
          haklarından birini kullanabilir. ALICI nın siparişi iptal etmesi
          halinde ödediği tutar 10 gün içinde kendisine nakden ve defaten
          ödenir.
        </div>
        <div style={{ marginTop: 30 }}>
          4.8- İşbu sözleşme, ALICI tarafından okudum kabul ediyorum
          tıklamasıyla geçerli olacaktır.
        </div>
        <div style={{ marginTop: 30, fontWeight: "bold" }}>
          MADDE 5 – CAYMA HAKKI
        </div>
        <div style={{ marginTop: 30 }}>
          ALICI, sözleşme konusu ürünün kendisine veya gösterdiği adresteki
          kişi/kuruluşa tesliminden itibaren (7) gün içinde cayma hakkına
          sahiptir. Cayma hakkının kullanılması için bu süre içinde SATICIYA ya
          faks, email veya telefon ile bildirimde bulunulması ve ürünün ilgili
          madde hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın
          kullanılması halinde, 3. kişiye veya ALICI ya teslim edilen ürünün
          SATICI ya gönderildiğine ilişkin kargo teslim tutanağı örneği ile
          fatura aslının iadesi zorunludur. Bu belgelerin ulaşmasını takip eden
          10 gün içinde ürün bedeli ALICI ya iade edilir. Fatura aslı
          gönderilmez ise KDV ve varsa sair yasal yükümlülükler iade edilemez.
          Cayma hakkı nedeni ile iade edilen ürünün kargo bedeli SATICI
          tarafından karşılanır.
        </div>
        <div style={{ marginTop: 30 }}>
          Ödemenin kredi kartı veya benzeri bir ödeme kartı ile yapılması
          halinde tüketici, kartın kendi rızası dışında ve hukuka aykırı biçimde
          kullanıldığı gerekçesiyle ödeme işleminin iptal edilmesini talep
          edebilir. Bu halde, kartı çıkaran kuruluş itirazın kendisine
          bildirilmesinden itibaren 10 gün içinde ödeme tutarını tüketiciye iade
          eder.
        </div>
        <div style={{ marginTop: 30 }}>
          İşbu sözleşmenin uygulanmasında, Sanayi ve Ticaret Bakanlığınca ilan
          edilen değere kadar Tüketici Hakem Heyetleri ile SATICI nın yerleşim
          yerindeki Tüketici Mahkemeleri yetkilidir.
        </div>
        <div style={{ marginTop: 30 }}>
          Siparişin sonuçlanması durumunda ALICI işbu sözleşmenin tüm
          koşullarını kabul etmiş sayılacaktır.
        </div>
      </div>
    </div>
  );
};

export default DistantSalesAgreement;
