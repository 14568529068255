import { Auth } from "aws-amplify";
import React, { useState } from "react";
import { RiRefreshLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputGroup from "../../components/InputGroup";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [activationCodeSent, setActivationCodeSent] = useState(false);

  const handleResetPassword = () => {
    const email = document.getElementById("email").value.trim();
    if (email) {
      const verificationCodeElement =
        document.getElementById("verification_code");
      const newPasswordElement = document.getElementById("password");
      if (verificationCodeElement && newPasswordElement) {
        const verificationCode = verificationCodeElement.value.trim();
        const newPassword = newPasswordElement.value.trim();
        if (verificationCode && newPassword) {
          console.log(verificationCode, newPassword);
          Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
            .then((data) => {
              if (data === "SUCCESS") {
                toast(
                  "Hesap şifreniz başarı ile yenilendi. Giriş yaparak devam edebilirsiniz."
                );
                navigate("/login");
              }
              console.log(data);
            })
            .catch((err) => {
              if (
                err.message ===
                "Invalid verification code provided, please try again."
              ) {
                toast(
                  "Geçersiz aktivasyon kodu, lütfen e-mailinize en son gönderilen kodu girin ve devam edin."
                );
              } else if (
                err.message ===
                "Password does not conform to policy: Password not long enough"
              ) {
                toast("Şifreniz yeterli uzunluğa sahip değil");
              } else if (
                err.message ===
                "Password does not conform to policy: Password must have lowercase characters"
              ) {
                toast("Şifrenizde küçük harf bulundurmalısınız");
              } else if (
                err.message ===
                "Password does not conform to policy: Password must have uppercase characters"
              ) {
                toast("Şifrenizde büyük harf bulundurmalısınız");
              } else if (
                err.message ===
                "Password does not conform to policy: Password must have symbol characters"
              ) {
                toast("Şifrenizde sembol bulundurmalısınız");
              } else if (
                err.message ===
                "Attempt limit exceeded, please try after some time."
              ) {
                toast(
                  "Deneme sınırına ulaştınız, lütfen birkaç dakika bekleyip daha sonra tekrar deneyiniz"
                );
              }
            });
        } else {
          toast("Lütfen bütün alanları doldurun");
        }
      } else {
        toast("Lütfen önce onay kodunu gönderin");
      }
    } else {
      toast("Lütfen bütün alanları doldurun");
    }
  };

  const handleResendCode = () => {
    const email = document.getElementById("email").value.trim();
    if (email) {
      Auth.forgotPassword(email)
        .then(() => {
          setActivationCodeSent(true);
          toast(
            "Şifre yenileme kodunuz başarıyla gönderildi. Lütfen e-mailinizi kontrol edin."
          );
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      toast("Lütfen geçerli bir e-mail adresi girin");
    }
  };

  return (
    <div className="login-register-screen-template">
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="col-md-6">
            <img src="/images/login_image.png" style={{ width: "100%" }}></img>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                Şifrenizi Yenileyin
              </div>
              <div className="col-md-9">
                <div
                  style={{ fontSize: 14, textAlign: "center", marginTop: 20 }}
                >
                  Lütfen önce email adresinizi girip yenileme butonuna basın.
                  Ardından emailinize gönderilen kodu ve yeni şifrenizi
                  aşağıdaki alanlara girip şifrenizi yenileyebilirsiniz
                </div>
              </div>
              <div className="col-md-9" style={{ marginTop: 40 }}>
                <div style={{ position: "relative", paddingRight: 50 }}>
                  <InputGroup
                    id="email"
                    label="E-mail"
                    type="text"
                    placeholder="Emailiniz"
                  />
                  <RiRefreshLine
                    onClick={handleResendCode}
                    size={30}
                    style={{
                      // color: "#d75d34",
                      color: "#ff6700",
                      // border: "1px solid #d75d34",
                      border: "1px solid #ff6700",
                      borderRadius: 5,
                      position: "absolute",
                      right: 10,
                      padding: 5,
                      top: 32,
                      cursor: "pointer",
                    }}
                  />
                </div>
                {activationCodeSent ? (
                  <React.Fragment>
                    <div className="mt-2">
                      <InputGroup
                        id="verification_code"
                        label="Aktivasyon Kodu"
                        type="number"
                        placeholder="Aktivasyon kodunu girin"
                      />
                    </div>
                    <div className="mt-2">
                      <InputGroup
                        id="password"
                        label="Şifre"
                        type="password"
                        placeholder="Şifrenizi girin"
                      />
                    </div>
                  </React.Fragment>
                ) : null}

                <div className="col-md-6 mt-5 text-center w-100">
                  <button
                    className={`${
                      activationCodeSent ? "primary-button" : "secondary-button"
                    }`}
                    onClick={handleResetPassword}
                    style={{ width: "100%" }}
                  >
                    Şifremi Yenile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
