import "./App.css";
import "@repay/react-credit-card/dist/react-credit-card.css";
import AWS from "aws-sdk";
import Amplify, { Auth } from "aws-amplify";
import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import LandingScreen from "./screens/landing/LandingScreen";
import { context } from "./context/ContextProvider";
import Navbar from "./navigation/Navbar";
import LoginScreen from "./screens/login/LoginScreen";
import RegisterScreen from "./screens/login/RegisterScreen";
import axios from "axios";
import OfferScreen from "./screens/offer/OfferScreen";
import MyOrderScreen from "./screens/orders/MyOrderScreen";
import ActivateAccountScreen from "./screens/login/ActivateAccountScreen";
import ForgotPasswordScreen from "./screens/login/ForgotPasswordScreen";
import DeliveryContitionsScreen from "./screens/footer/DeliveryContitionsScreen";
import PrivacyAndSecurityScreen from "./screens/footer/PrivacyAndSecurityScreen";
import BoxLetterProductionScreen from "./screens/navbar/BoxLetterProductionScreen";
import MembershipAgreement from "./screens/footer/MembershipAgreement";
import WarrantAndReturnConditionsScreen from "./screens/footer/WarrantAndReturnConditionsScreen";
import DistantSalesAgreement from "./screens/footer/DistantSalesAgreement";
import AboutUsScreen from "./screens/footer/AboutUsScreen";
import ContactUsScreen from "./screens/footer/ContantUsScreen";
import SettingsScreen from "./screens/settings/SettingsScreen";
import Footer from "./navigation/Footer";
import { retrieveColorList } from "./requests/colorRequests";
import { retrieveCuttingList } from "./requests/cuttingRequests";
import { retrieveDekotaList } from "./requests/dekotaRequests";
import { retrieveLedList } from "./requests/ledRequests";
import { retrievePatternList } from "./requests/patternRequests";
import { retrieveTransformerList } from "./requests/transformerRequets";
import { retrieveProductionTypeList } from "./requests/productionTypeRequests";
import { retrieveSettings } from "./requests/settingsRequests";
import { queryUserListByEmail } from "./requests/userRequests";
import TawkTo from "tawkto-react";
import LoadingAnimation from "./components/loading/LoadingAnimation";

function App() {
  const appContext = useContext(context);
  const {
    boardingCompleted,
    setBoardingCompleted,
    setUserInformation,
    setColorList,
    setCuttingList,
    setDekotaList,
    setLedList,
    setPatternList,
    setTransformerList,
    setProductionTypeList,
    setSettings,
  } = appContext;
  const navigate = useNavigate();
  const [axiosReady, setAxiosReady] = useState(false);
  let basicRequestInterceptor = null;
  let basicResponseInterceptor = null;

  useEffect(() => {
    const configureTawkTo = () => {
      new TawkTo(
        process.env.REACT_APP_TAWKTO_PROPERTY_ID,
        process.env.REACT_APP_TAWKTO_TAWK_ID
      );
    };
    configureTawkTo();
  }, []);

  useEffect(() => {
    const configureAuthentication = async () => {
      Amplify.configure({
        Auth: {
          region: process.env.REACT_APP_REGION,
          userPoolId: process.env.REACT_APP_USER_POOL_ID,
          userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        },
      });
      try {
        const currentSession = await Auth.currentSession();
        setAxiosInterceptor(currentSession.accessToken.jwtToken);
      } catch (err) {
        console.log(err);
        setBoardingCompleted(true);
      }
    };
    const configureS3Authentication = async () => {
      AWS.config.update({
        region: process.env.REACT_APP_REGION,
        // credentials: new AWS.CognitoIdentityCredentials({
        //   IdentityPoolId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        // }),
        credentials: {
          accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        },
      });
    };

    configureAuthentication();
    configureS3Authentication();
  }, []);

  useEffect(() => {
    const retrieveAndSetUserInformation = async () => {
      const currentUser = await Auth.currentAuthenticatedUser();
      const foundUserList = await queryUserListByEmail(currentUser.username);
      if (foundUserList && foundUserList.length > 0) {
        setUserInformation(foundUserList[0]);
      }
    };
    const configureUserInformation = async () => {
      retrieveAndSetUserInformation();
      setBoardingCompleted(true);
    };
    if (axiosReady) {
      configureUserInformation();
    }
  }, [axiosReady]);

  useEffect(() => {
    const retrieveAndSetColorList = async () => {
      const foundColorList = await retrieveColorList();
      setColorList(foundColorList);
    };
    const retrieveAndSetCuttingList = async () => {
      const foundCuttingList = await retrieveCuttingList();
      setCuttingList(foundCuttingList);
    };
    const retrieveAndSetDekotaList = async () => {
      const foundDekotaList = await retrieveDekotaList();
      setDekotaList(foundDekotaList);
    };
    const retrieveAndSetLedList = async () => {
      const foundLedList = await retrieveLedList();
      setLedList(foundLedList);
    };
    const retrieveAndSetPatternList = async () => {
      const foundPatternList = await retrievePatternList();
      setPatternList(foundPatternList);
    };
    const retrieveAndSetTransformerList = async () => {
      const foundTransformerList = await retrieveTransformerList();
      setTransformerList(foundTransformerList);
    };
    const retrieveAndSetProductionTypeList = async () => {
      const foundProductionTypeList = await retrieveProductionTypeList();
      setProductionTypeList(foundProductionTypeList);
    };
    const retrieveAndSetSettings = async () => {
      const foundSettings = await retrieveSettings();
      setSettings(foundSettings);
    };
    if (boardingCompleted) {
      retrieveAndSetColorList();
      retrieveAndSetCuttingList();
      retrieveAndSetDekotaList();
      retrieveAndSetLedList();
      retrieveAndSetPatternList();
      retrieveAndSetTransformerList();
      retrieveAndSetProductionTypeList();
      retrieveAndSetSettings();
    }
  }, [boardingCompleted]);

  const handleSignOut = async () => {
    await Auth.signOut();
    setUserInformation(null);
    setAxiosReady(false);
    if (basicRequestInterceptor) {
      axios.interceptors.request.eject(basicRequestInterceptor);
      basicRequestInterceptor = null;
    }
    if (basicResponseInterceptor) {
      axios.interceptors.response.eject(basicResponseInterceptor);
      basicResponseInterceptor = null;
    }
    navigate("/");
  };

  const setAxiosInterceptor = (accessToken) => {
    basicRequestInterceptor = axios.interceptors.request.use(
      async (config) => {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    basicResponseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response &&
          error.response.status === 401 &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true;
          const user = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          user.refreshSession(currentSession.refreshToken, (err, data) => {
            axios.interceptors.request.eject(basicRequestInterceptor);
            basicRequestInterceptor = axios.interceptors.request.use(
              async (config) => {
                config.headers = {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${data.accessToken}`,
                };
                return config;
              },
              (error) => {
                Promise.reject(error);
              }
            );
          });
          setTimeout(() => {
            return axios.request(originalRequest);
          }, 500);
        } else {
          return Promise.reject(error);
        }
      }
    );

    setAxiosReady(true);
  };

  const RenderMainContent = () => {
    return (
      <div className="screen-base-template">
        <Navbar handleSignOut={handleSignOut} />
        <Routes>
          <Route
            path="/login"
            element={<LoginScreen setAxiosInterceptor={setAxiosInterceptor} />}
          />
          <Route path="/activate-account" element={<ActivateAccountScreen />} />
          <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/offer" element={<OfferScreen />} />
          <Route path="/my-orders" element={<MyOrderScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route
            path="/box-letter-production"
            element={<BoxLetterProductionScreen />}
          />
          <Route
            path="/membership-agreement"
            element={<MembershipAgreement />}
          />
          <Route
            path="/distant-sales-agreement"
            element={<DistantSalesAgreement />}
          />
          <Route
            path="/delivery-conditions"
            element={<DeliveryContitionsScreen />}
          />
          <Route
            path="/warrant-and-return-conditions"
            element={<WarrantAndReturnConditionsScreen />}
          />
          <Route
            path="/privacy-and-security"
            element={<PrivacyAndSecurityScreen />}
          />
          <Route path="/about-us" element={<AboutUsScreen />} />
          <Route path="/contact-us" element={<ContactUsScreen />} />
          <Route path="/" element={<LandingScreen />} />
        </Routes>
        <Footer />
      </div>
    );
  };

  return boardingCompleted ? (
    <RenderMainContent />
  ) : (
    <LoadingAnimation height={"100vh"} />
  );
}

export default App;
