import { Component, createContext } from "react";

export const context = createContext();

class ContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardingCompleted: false,
      userInformation: null,
      colorList: [],
      cuttingList: [],
      dekotaList: [],
      ledList: [],
      patternList: [],
      transformerList: [],
      productionTypeList: [],
      settings: null,
    };

    this.setBoardingCompleted = this.setBoardingCompleted.bind(this);
    this.setUserInformation = this.setUserInformation.bind(this);
    this.setColorList = this.setColorList.bind(this);
    this.setCuttingList = this.setCuttingList.bind(this);
    this.setDekotaList = this.setDekotaList.bind(this);
    this.setLedList = this.setLedList.bind(this);
    this.setPatternList = this.setPatternList.bind(this);
    this.setTransformerList = this.setTransformerList.bind(this);
    this.setProductionTypeList = this.setProductionTypeList.bind(this);
    this.setSettings = this.setSettings.bind(this);
  }

  setBoardingCompleted = (boardingCompleted) => {
    this.setState({ boardingCompleted });
  };

  setUserInformation = (userInformation) => {
    this.setState({ userInformation });
  };

  setCategoryList = (categoryList) => {
    this.setState({ categoryList });
  };

  setColorList = (colorList) => {
    this.setState({ colorList });
  };

  setCuttingList = (cuttingList) => {
    this.setState({ cuttingList });
  };

  setDekotaList = (dekotaList) => {
    this.setState({ dekotaList });
  };

  setLedList = (ledList) => {
    this.setState({ ledList });
  };

  setPatternList = (patternList) => {
    this.setState({ patternList });
  };

  setTransformerList = (transformerList) => {
    this.setState({ transformerList });
  };

  setProductionTypeList = (productionTypeList) => {
    this.setState({ productionTypeList });
  };

  setSettings = (settings) => {
    this.setState({ settings });
  };

  render() {
    return (
      <context.Provider
        value={{
          ...this.state,
          setBoardingCompleted: this.setBoardingCompleted,
          setUserInformation: this.setUserInformation,
          setColorList: this.setColorList,
          setCuttingList: this.setCuttingList,
          setDekotaList: this.setDekotaList,
          setLedList: this.setLedList,
          setPatternList: this.setPatternList,
          setTransformerList: this.setTransformerList,
          setProductionTypeList: this.setProductionTypeList,
          setSettings: this.setSettings,
        }}
      >
        {this.props.children}
      </context.Provider>
    );
  }
}

export default ContextProvider;
